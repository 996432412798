export const isValidEAN13 = code => {
  // O código deve ter exatamente 13 dígitos
  if (!/^\d{13}$/.test(code)) {
    return false;
  }

  // Calcula o dígito de verificação
  const digits = code.split("").map(Number);
  const checkDigit = digits.pop();
  const sum = digits.reduce((acc, digit, index) => {
    return index % 2 === 0 ? acc + digit : acc + digit * 3;
  }, 0);
  const calculatedCheckDigit = (10 - (sum % 10)) % 10;

  return checkDigit === calculatedCheckDigit;
};
