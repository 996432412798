import styled from "styled-components";

export const TableStyled = styled.table`
  width: 100%;
`;
export const TableCard = styled.div`
  overflow: scroll;
  max-height: 300px;
`;

export const TrStyled = styled.tr`
  border-bottom: 0.1px solid #ece4e4 !important;
  border-top: 0.1px solid #ece4e4 !important;
`;

export const TdStyled = styled.td`
  padding: 10px 4px 10px 4px !important;
`;
