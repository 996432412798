import { transformListSelect } from "utils/utils";

export const FIELDS_TYPES = {
  EAN: "Código de Barras",
  PROD_DESCRICAO: "Descrição do Produto",
  CNPJ: "CNPJ",
};
export const SELECT_ALL = {
  ALL: "Todos",
};

export const FIELDS_TYPES_LIST = transformListSelect(FIELDS_TYPES);

const TYPES_OPPORTUNITY = {
  MONEY: "Dinheiro na Mesa",
  TEMPTATION: "Cuidado com a Tentação",
};
export const TYPES_OPPORTUNITY_LIST = transformListSelect({
  ...SELECT_ALL,
  ...TYPES_OPPORTUNITY,
});

export const USER_TYPE = {
  ROOT: "root",
  ADMIN: "admin",
  DEV: "dev",
  FINANCIAL: "financial",
  MANAGER: "manager",
  ATTENDANT: "attendant",
  SALE: "sale",
};

export const USER_TYPE_LIST = transformListSelect(USER_TYPE);

export const PRIVILEGES = {
  ADMIN: USER_TYPE.ADMIN,
  DEV: USER_TYPE.DEV,
  FINANCIAL: USER_TYPE.FINANCIAL,
  MANAGER: USER_TYPE.MANAGER,
  ATTENDANT: USER_TYPE.ATTENDANT,
  ROOT: USER_TYPE.ROOT,
  SALE: USER_TYPE.SALE,
};

export const PRIVILEGES_LIST = transformListSelect(PRIVILEGES);

export const USER_ROLES = [
  {
    value: "Administrador",
    label: "Administrador",
  },
  {
    value: "Gerente",
    label: "Gerente",
  },
  {
    value: "Supervisor",
    label: "Supervisor",
  },
  {
    value: "Atendente",
    label: "Atendente",
  },
  {
    value: "Root",
    label: "Root",
  },
];

export const ACOUNT_ACTIVE_USER = [
  {
    value: true,
    label: "Sim",
  },
  {
    type: false,
    label: "Não",
  },
];
