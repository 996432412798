export const convertBase64ToFile = imageSrc => {
  const base64WithoutPrefix = imageSrc.replace(
    /^data:image\/[a-z]+;base64,/,
    "",
  );

  const blob = new Blob([Buffer.from(base64WithoutPrefix, "base64")], {
    type: "image/jpeg",
  });

  const fileName = `image-${new Date()}.jpg`;

  const imageFile = new File([blob], fileName, { type: "image/jpeg" });

  return imageFile;
};
