import { createSlice } from "@reduxjs/toolkit";

const InitialStatePolitic = {
  typeSelect: "",
  optionDateSelect: "",
  selectedDate: "",
  selectSwitch: "",
  isOpen: true,
};

const initialState = () => {
  return InitialStatePolitic;
};

export const politicPriceSlice = createSlice({
  name: "politicPrice",
  initialState,
  reducers: {
    handleOpenLowOption: (state, { payload }) => {
      state.typeSelect = payload.value;
    },
    handleOpenDateOption: (state, { payload }) => {
      state.optionDateSelect = payload.value;
    },
    handleSelectDate: (state, { payload }) => {
      state.selectedDate = payload.value;
    },
    OpenCollapse: (state, { payload }) => {
      state.isOpen = payload;
    },
    handleSelectSwitch: (state, { payload }) => {
      state.selectSwitch = payload.value;
    },
  },
});

export const {
  handleOpenLowOption,
  handleOpenDateOption,
  handleSelectDate,
  handleSelectSwitch,
  OpenCollapse,
} = politicPriceSlice.actions;

export default politicPriceSlice.reducer;
