import { useCallback, useState } from "react";

import { useAPINFE2, useFetchNfePost, useToast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useGetQueimaAndRisco = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      apiNfe
        .post("/tax-note/produtos-queima-risco", {
          ...params,
        })
        .then(values => {
          setTotalPages(values?.data?.length);
          setData(values?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setTotalPages(undefined);
          setLoading(false);
        });
    },
    [apiNfe],
  );

  return { isLoading: loading, data, totalPages, fetch };
};

export const useQueimaAndRiscoActions = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const onSend = useCallback(
    (params = {}) => {
      setLoading(true);
      apiNfe
        .get(
          getURLWithParams("/tax-note/produtos-queima-risco", {
            taxType: params.taxType,
            issue_date_start: params.issue_date_start,
            issue_date_end: params.issue_date_end,
            type_export: params.type_export,
            user_name: params?.user_name,
            user_email: params?.user_email,
          }),
        )
        .then(() => {
          addToast({
            type: "success",
            title: "Solicitação em andamento...",
            description: `Iremos lhe enviar um link para download do arquivo...`,
          });
        })
        .catch(() => {
          setData(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apiNfe, addToast],
  );

  return { isLoading: loading, data, onSend };
};

export const useQueimaAndRisco = (params = {}) =>
  useFetchNfePost("/tax-note/produtos-queima-risco", params);

export const useQueimaAndRiscoNotas = (params = {}) =>
  useFetchNfePost("/tax-note/produtos-queima-risco/notas", params);
