import { useState, useCallback } from "react";
import jsonp from "jsonp";
import {
  useFetchAuthenticate,
  useAPIAuthenticate,
  useToast,
  useAPIAuthenticateToast,
  useFetchMixAPi1,
  useMixApi1Toast,
  useAPICmfToast,
} from "hooks";
import { getURLWithParams } from "utils/context";
import {
  ischeckIfAndNumber,
  formatActivities,
  formatSocialName,
  formatCnpj,
} from "utils/utils";
import { dateFormatInvoice } from "utils";
import { CERTIFICADO_STATUS } from "constants/Labels/storesLabels";

export const useStores = (params = {}) =>
  useFetchAuthenticate(params ? getURLWithParams("/stores", params) : null);

export const useWalletStore = id =>
  useFetchAuthenticate(id ? getURLWithParams(`/store/wallets/${id}`) : null);

export const useLinkedUsers = id => useFetchAuthenticate(`/users/store/${id}`);

export const useFilial = id => useFetchAuthenticate(`/stores/filiais/${id}`);

export const useListTagsIgnoradas = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/classificacao/ignored-list", params));

export const useGenerateNewTags = () => {
  const { isLoading, onSend } = useAPICmfToast();

  const onCommandSend = async data => {
    await onSend({
      endpoint: `/mercadologico-tagueamento-ia`,
      messageSuccess: `Requisição completada`,
      messageFail: `Falha ao completar requisição!`,
      data,
      type: "POST",
    });
  };

  return {
    onCommandSend,
    isLoading,
  };
};

export const useTagsIgnoradas = id => {
  const { isLoading, data, onSend } = useMixApi1Toast();

  const ignoreCreate = async data => {
    const endpoint = id
      ? `/classificacao/ignored-update`
      : `/classificacao/ignored-create`;
    const type = id ? "PUT" : "POST";

    const res = await onSend({
      endpoint,
      messageSuccess: "Sucesso!",
      messageFail: "Requisição falhou!",
      type,
      data,
    });
    return res;
  };

  const onRemove = id =>
    onSend({
      endpoint: `/classificacao/ignored/${id}`,
      messageSuccess: `Tag deletada!`,
      messageFail: `Erro ao deletar!`,
      type: "DELETE",
    });

  return {
    onRemove,
    ignoreCreate,
    isLoading,
    data,
  };
};

export const useWalletStoreRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id =>
    onSend({
      endpoint: `/store/wallet/${id}`,
      messageSuccess: "Assessor foi removido com sucesso!",
      messageFail: "Assessor não foi removido",
      type: "DELETE",
    });

  return {
    onRemove,
    isLoading,
  };
};

const check = search => {
  if (search) {
    if (ischeckIfAndNumber(search)) {
      return `&cnpj=${search}`;
    }
    return `&search=${search}`;
  }
  return "";
};

export const useStoresPagination = (params = {}) =>
  useFetchAuthenticate(
    params
      ? `/stores?page=${params.page}&limit=${params.limit}${check(
          params.search,
        )}`
      : null,
  );

export const useStore = id =>
  useFetchAuthenticate(id ? getURLWithParams(`/store/${id}`) : null);

export const useStoreCreateWallet = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(``);

  const { addToast } = useToast();

  const fetch = useCallback(
    (value = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const res = await api.post(`/store/wallet`, value);

          if (res.message) {
            addToast({
              title: "Usuário",
              type: "warning",
              description: res.message,
            });
          }
          setData(res.wallet);
          addToast({
            title: "Usuário",
            type: "success",
            description: "Assessor cadastrado com sucesso!",
          });
        } catch (e) {
          setIsError(e);
          setLoading(false);
          console.log(e.messagge);
          setIsError(``);
        }
      };
      fetchData();
    },
    [api, addToast],
  );
  return {
    data,
    fetch,
    isError,
    isLoading: loading,
  };
};

export const useStoreRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id =>
    onSend({
      endpoint: `/store/${id}`,
      messageSuccess: `LOJA DELETADA`,
      messageFail: "Loja foi deletada com sucesso!",
      type: "DELETE",
    });

  return {
    onRemove,
    isLoading,
  };
};

const getUrl = ({ search, type = "" }) => {
  const isCheckValueNumber = ischeckIfAndNumber(search);

  const isType = `&type=${type}`;

  if (!isCheckValueNumber) {
    return `?page=1&limit=50&search=${search}${type ? isType : ``}`;
  }

  return `?page=1&limit=50&cnpj=${search}${type ? isType : ``}`;
};

// FIXME: Remover o uso de todos os lugares
export const UseStoresSearch = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);

      const url = getUrl(params);

      const fetchData = async () => {
        try {
          const {
            data: { stores },
          } = await api.get(`/stores${url}`);
          setData(stores.docs);
        } catch (e) {
          console.log(e.messagge);
        } finally {
          setLoading(false);
        }
      };
      fetchData(url);
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};

export const UseStoresSearchAsync = () => {
  const api = useAPIAuthenticate();
  const fetch = useCallback(
    async (params = {}) => {
      try {
        const url = getUrl(params);
        const {
          data: { stores },
        } = await api.get(`/stores${url}`);

        return stores.docs;
      } catch (error) {
        return [];
      }
    },
    [api],
  );

  return { fetch };
};

export const useStoresSearch = params =>
  useFetchAuthenticate(params && getURLWithParams(`/stores`, params));

export const UseStoresAll = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(``);
  const fetch = useCallback(() => {
    setLoading(true);
    setIsError(`loading`);
    const url = `?page=all&limit=50`;
    const fetchData = async () => {
      try {
        const {
          data: { stores },
        } = await api.get(`/stores${url}`);
        setData(stores.docs);
        setLoading(false);
        setIsError(``);
      } catch (e) {
        console.log(e.messagge);
        setIsError(`not-exist`);
      }
    };
    fetchData();
  }, [api]);

  return { isLoading: loading, data, fetch, isError };
};

// FIXME: NAO PODE INICIAR O use com U maiusculo (padrao do react)!!!
export const UseGetCnpjData = () => {
  const [isExistCadastro, setIsExistCadastro] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast({});

  const { fetch: searchingAsync } = UseStoresSearchAsync();

  const HasCnpj = useCallback(
    async params => {
      const res = await searchingAsync({
        search: params?.cnpj,
      });

      if (res?.length > 0) {
        setIsExistCadastro(true);
      } else {
        setIsExistCadastro(false);
      }
    },
    [setIsExistCadastro, searchingAsync],
  );

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      if (!params.cnpj) {
        return addToast({
          title: "CNPJ em branco",
          type: "error",
          description: "O campo CNPJ não pode ficar em branco.",
        });
      }
      HasCnpj(params);
      jsonp(
        `https://www.receitaws.com.br/v1/cnpj/${params.cnpj}`,
        null,
        (err, cnpjData) => {
          if (err) {
            setLoading(false);
            return addToast({
              title: "CNPJ",
              type: "error",
              description: `Ocorreu o seguinte erro: ${err.message}`,
            });
          }
          if (cnpjData.status === "ERROR") {
            setLoading(false);
            return addToast({
              title: "CNPJ",
              type: "error",
              description: cnpjData.message,
            });
          }

          if (!cnpjData && err && cnpjData.status === `ERROR`) {
            setLoading(false);
            return addToast({
              title: "CNPJ Não existente.",
              type: "error",
              description: `O CNPJ: ${formatCnpj(params.cnpj)}`,
            });
          }
          setData(prevState => {
            return {
              ...prevState,
              zipcode: cnpjData?.cep,
              email: cnpjData?.email,
              companyName: cnpjData.nome,
              tradeName: cnpjData.fantasia,
              address: cnpjData.logradouro,
              number: cnpjData.numero,
              complement: cnpjData.complemento || "-",
              zipcode: cnpjData.cep,
              district: cnpjData.bairro,
              neighborhood: cnpjData.bairro,
              city: cnpjData.municipio,
              state: cnpjData.uf,
              legalNature: cnpjData.natureza_juridica,
              openDate: cnpjData?.abertura,
              lastUpdate: cnpjData?.ultima_atualizacao,
              phone: cnpjData.telefone,
              namePartnerOne: formatSocialName(cnpjData.qsa, 0),
              namePartnerTwo: formatSocialName(cnpjData.qsa, 1),
              mainActivity: formatActivities(cnpjData.atividade_principal),
              secondaryActivity: formatActivities(
                cnpjData.atividades_secundarias,
              ),
              logo: cnpjData.logo,
              certificate: cnpjData.certificate,
              companyType: cnpjData.tipo,
            };
          });
          setLoading(false);
        },
      );
    },
    [addToast, HasCnpj],
  );
  return { isLoading: loading, data, fetch, isExistCadastro };
};

export const useStoreWhatsAppForm = item => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onSave = data =>
    onSend({
      endpoint: item.id ? `/store/${item.id}` : "store",
      messageSuccess: `WhatsApp da Loja  ${
        item.whatsapp ? "atualizado" : "criada"
      } com sucesso!`,
      messageFail: "Falha ao tentar salvar whatsApp!",
      data,
      type: item.id ? "PUT" : "POST",
    });

  return {
    onSave,
    isLoading,
  };
};

export const useStoreForm = item => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onSave = data =>
    onSend({
      endpoint: item?.id ? `/store/${item?.id}` : "store",
      messageSuccess: `Loja ${item?.id ? "atualizado" : "criada"} com sucesso!`,
      messageFail: "Falha ao tentar salvar salvar loja!",
      data,
      type: item?.id ? "PUT" : "POST",
    });

  const onRemoveCertificado = data =>
    onSend({
      endpoint: "/store/certificate-remove",
      messageSuccess: `Certificado deletado com sucesso`,
      messageFail: "Falha ao tentar deletar certificado!",
      data,
      type: "DELETE",
    });

  return {
    onSave,
    isLoading,
    onRemoveCertificado,
  };
};

export const useStoresStatus = () => useFetchAuthenticate("stores/status");

export const useStoreAssessor = () => useFetchAuthenticate("stores/assessor");

export const useStoreDadosCertificateNew = (id = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPIAuthenticate();
  const { addToast } = useToast();
  const onSave = async (values = {}) => {
    try {
      const nfeFormData = new FormData();
      nfeFormData.append("file", values.certificate);
      nfeFormData.append("password", values.certificatePassword);
      nfeFormData.append("cnpj", values.cnpj);
      await api.post(`/store/certificate`, nfeFormData);
      setIsLoading(false);
      return addToast({
        type: "success",
        title: "Sucesso",
        description: `Certificado ${
          id ? "Atualizado" : "Cadastrado"
        } com sucesso`,
      });
    } catch (e) {
      const { data } = e.response;
      if (data?.data?.tipo === CERTIFICADO_STATUS?.CERTIFICADO_EXPIRADO) {
        return addToast({
          type: "error",
          title: "FALHA",
          align: "top",
          description: `O certificado está expirado. ${dateFormatInvoice(
            data?.data?.data_expiracao,
          )}`,
        });
      }
      addToast({
        type: "error",
        title: "FALHA",
        align: "top",
        description: data?.data?.message,
      });
      setIsLoading(false);
    }
  };

  return {
    onSave,
    isLoading,
  };
};

export const UseStoresSearchingAsync = () => {
  const api = useAPIAuthenticate();

  const fetch = useCallback(
    async (params = {}) => {
      const url = getUrl(params);
      try {
        const response = await api.get(`/stores${url}`);
        const stores = response.data.stores || [];
        return stores.docs;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  return { fetch };
};

export const useUserStore = id =>
  useFetchAuthenticate(getURLWithParams(`/user/store/${id}`));

export const useUserStoreEdit = () => {
  const { onSend } = useAPIAuthenticateToast();

  const onSave = (id, data) =>
    onSend({
      endpoint: `/user/store/${id}`,
      messageSuccess: `Edit com sucesso!`,
      messageFail: "Falha ao tentar salvar loja!",
      data,
      type: "PUT",
    });

  return { onSave };
};

export const useTaxModifiers = () =>
  useFetchMixAPi1(
    getURLWithParams(`/modificadores/modificadores-tributarios/cliente`),
  );

export const useUserModifiers = () => {
  const { onSend } = useMixApi1Toast();

  const onCreateEditModifier = data => {
    if (data?.edit) {
      return onSend({
        endpoint: `/modificadores/modificadores-tributarios/edit`,
        messageSuccess: `Modificador editado com sucesso!`,
        messageFail: "Não foi possivel editar o modificador.",
        type: "PUT",
        data,
      });
    }

    return onSend({
      endpoint: `/modificadores/modificadores-tributarios`,
      messageSuccess: `Modificador criado com sucesso!`,
      messageFail: "Não foi possivel criar o modificador.",
      type: "POST",
      data,
    });
  };

  const onRemove = (cnpj, id) =>
    onSend({
      endpoint: getURLWithParams(
        `/modificadores/modificadores-tributarios/delete/${cnpj}`,
        { id },
      ),
      messageSuccess: `Modificador deletado com sucesso!`,
      messageFail: "Falha ao tentar excluír modificador!",
      type: "DELETE",
    });

  return {
    onRemove,
    onCreateEditModifier,
  };
};

// /modificadores-tributarios/edit
