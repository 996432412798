import { useCallback } from "react";
import { useFetchMixAPi1, useMixApi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useEspecificacaoList = (params = {}) =>
  useFetchMixAPi1(getURLWithParams(`/legislacao/especificacoes/`, params));

export const useEspecificacaoDetail = id =>
  useFetchMixAPi1(id ? `/legislacao/especificacoes/detail/${id}` : null);

export const useEspecificacoesNovo = (id = null) => {
  const { onSend } = useMixApi1Toast();

  const onCreate = async (data, modal) => {
    const endpoint = id
      ? `legislacao/especificacoes/update/${id}`
      : `legislacao/especificacoes/novo`;
    const type = id ? "PUT" : "POST";

    onSend({
      endpoint,
      messageSuccess: id
        ? `Especificação Editada com Sucesso`
        : `Especificação Cadastrada com Sucesso`,
      messageFail: id
        ? `Não foi possível editar a Especificação`
        : `Não foi possível cadastrar a Especificação`,
      data,
      type,
      redirect: id || modal ? false : true,
      fieldGetId: "mxf_esp_id",
      urlRedirect: "/legislacao/especificacao/editar",
    });
  };

  const onCreateByGroup = async data => {
    onSend({
      endpoint: `legislacao/especificacoes/create-by-group`,
      messageSuccess: `Especificação Cadastrada com Sucesso`,
      messageFail: `Não foi possível cadastrar a Especificação`,
      data,
      type: "POST",
    });
  };

  const onRemove = async id =>
    onSend({
      endpoint: `legislacao/especificacoes/destroy/${id}`,
      messageSuccess: `Portaria desassociada com sucesso`,
      messageFail: `Não foi possível desassociar Portaria`,
      type: "DELETE",
    });

  const onCreateRelacaoComPortaria = async data =>
    onSend({
      endpoint: "legislacao/especificacaoPortaria/novo",
      messageSuccess: "Relação Cadastrada com Sucesso",
      messageFail: "Não foi possível cadastrar a Relação",
      data,
      type: "POST",
    });

  const onRemovePortaria = async data =>
    onSend({
      endpoint: `legislacao/especificacaoPortaria/destroy`,
      messageSuccess: `Portaria desassociada com sucesso`,
      messageFail: `Não foi possível desassociar Portaria`,
      data,
      type: "DELETE",
    });

  return {
    onCreate,
    onCreateRelacaoComPortaria,
    onRemovePortaria,
    onRemove,
    onCreateByGroup,
  };
};

export const useEspecificacoesAsync = () => {
  const api = useMixApi1();

  const fetchPortaria = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/portaria/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  const fetchGrupoEspecificacoes = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/grupo-especificacoes/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  return { fetchPortaria, fetchGrupoEspecificacoes };
};
