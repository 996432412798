import { useState, useCallback } from "react";
import {
  useFetchAuthenticate,
  useAPIAuthenticateToast,
  useAPIAuthenticate,
} from "hooks";
import { getURLWithParams } from "utils/context";

export const useModulesShow = id =>
  useFetchAuthenticate(id ? `/module/${id}` : null);

export const useModules = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/modules", params));

export const useNewModule = id => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onSave = data =>
    onSend({
      endpoint: id ? `/module/all/${id}` : `/module`,
      messageSuccess: `MÓDULO ${id ? "atualizado" : "criado"} com sucesso!`,
      messageFail: "Falha ao tentar salvar módulo",
      data,
      type: id ? "PUT" : "POST",
    });

  return {
    onSave,
    isLoading,
  };
};

export const useRemoveModule = () => {
  const { onSend } = useAPIAuthenticateToast();

  const onRemove = id => {
    onSend({
      endpoint: `/configs/module/${id}`,
      messageSuccess: `MÓDULO DELETADO`,
      messageFail: "Falha ao tentar deletar modulo!",
      type: "DELETE",
    });
  };

  return {
    onRemove,
  };
};

export const useGetModules = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    id => {
      setLoading(true);
      const url = id || ``;
      const fetchData = async () => {
        try {
          const { data } = await api.get(`/modules`);

          if (data.modules === 0) {
            setData({ modules: [] });
          }
          setData(data);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(e.messagge);
        }
      };
      fetchData(url);
    },
    [api],
  );

  return {
    data,
    fetch,
    isLoading: loading,
  };
};
