import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";

export const accessGroupTreateSend = modules => {
  const childrens = [];
  const father = [];

  (modules || []).forEach(item => {
    if (item?.childrens?.length > 0) {
      const treatChildrens = (item?.childrens || []).map(ch => ({
        moduleId: ch?.moduleId,
        _id: ch?.moduleId,
        rules: {
          get: ch?.get,
          put: ch?.put,
          post: ch?.post,
          delete: ch?.delete,
        },
      }));
      childrens.push(...treatChildrens);
      delete item.childrens;
    }

    father.push({
      moduleId: item?.moduleId,
      _id: item?.moduleId,
      rules: item?.rules,
    });
  });

  return { childrens, father };
};

export const getLastSixMonths = date => {
  const currentDate = date ? new Date(date) : new Date();

  // Array to store the results
  const resultMonths = [];

  // Get the last 6 months
  for (let i = 0; i < 6; i++) {
    const previousMonth = subMonths(currentDate, i);
    const startOfMonthDate = startOfMonth(previousMonth);
    const endOfMonthDate = endOfMonth(previousMonth);

    const start = format(startOfMonthDate, "yyyy-MM-dd");
    const end = format(endOfMonthDate, "yyyy-MM-dd");

    resultMonths.push({ start, end });
  }

  return {
    start: resultMonths[5]?.start,

    end: resultMonths[0]?.end,
  };
};
