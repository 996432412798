import { useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useGrupoEspecificacaoList = (params = {}) =>
  useFetchMixAPi1(
    getURLWithParams(`/legislacao/grupo-especificacoes/list`, params),
  );

export const useGrupoEspecificacaoDetail = id =>
  useFetchMixAPi1(id ? `legislacao/grupo-especificacoes/detail/${id}` : null);

export const useGrupoEspecificacoesToast = (id = null) => {
  const { onSend } = useMixApi1Toast();

  const onCreate = async data => {
    const endpoint = id
      ? `legislacao/grupo-especificacoes/update/${id}`
      : `legislacao/grupo-especificacoes/create`;
    const type = id ? "PUT" : "POST";

    onSend({
      endpoint,
      messageSuccess: id
        ? `Grupo Editado com Sucesso`
        : `Grupo Cadastrado com Sucesso`,
      messageFail: id
        ? `Não foi possível editar o Grupo`
        : `Não foi possível cadastrar o Grupo`,
      data,
      type,
      redirect: id ? false : true,
      fieldGetId: "mxf_gru_id",
      urlRedirect: "/legislacao/grupo-especificacoes/editar",
    });
  };

  const onRemove = () =>
    onSend({
      endpoint: `legislacao/grupo-especificacoes/destroy/${id}`,
      messageSuccess: `Grupo apagado com sucesso`,
      messageFail: `Não foi possível apagar o Grupo`,
      type: "DELETE",
    });

  return {
    onCreate,
    onRemove,
  };
};
