import { createSlice } from "@reduxjs/toolkit";

export const inicialProdutos = {
  params: {
    limit: 20,
    page: 1,
  },
};

const initialState = () => {
  return inicialProdutos;
};

export const ProdutosSlice = createSlice({
  name: "produtos",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      if (payload?.isDelete) {
        delete state.params[payload?.field];
      } else {
        state.params[payload?.field] = payload?.value;
      }
    },
  },
});

export const { handleParams } = ProdutosSlice.actions;

export default ProdutosSlice.reducer;
