import { createSlice } from "@reduxjs/toolkit";

const InitialResend = {
  showModal: {
    type: "",
    show: false,
  },
  client: "",
  idValue: [],
  form: {},
};

const initialState = () => {
  return InitialResend;
};

export const ResendCreateSlice = createSlice({
  name: "resend",
  initialState,
  reducers: {
    handleSetResend: (state, { payload }) => {
      state.type = payload;
    },
    handleShowModal: (state, { payload }) => {
      state.showModal.show = payload?.showModal;
      state.showModal.type = payload?.type;
    },
    handleSetId: (state, { payload }) => {
      state.idValue = payload?.idValue;
    },
    handleSetClient: (state, { payload }) => {
      state.client = payload?.client;
    },
    handleResend: (state, { payload }) => {
      state.form = payload;
    },
  },
});

export const {
  handleSetResend,
  handleShowModal,
  handleSetId,
  handleResend,
  handleSetClient,
} = ResendCreateSlice.actions;

export default ResendCreateSlice.reducer;
