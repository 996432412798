import { createSlice } from "@reduxjs/toolkit";

export const initialFinancas = {
  list: [],
  params: {
    page: 1,
    limit: 10,
    search: "",
  },
};

const initialState = () => {
  return initialFinancas;
};

export const usersSlice = createSlice({
  name: "financas",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      state.params.page = payload.page;
    },

    handleSetLimit: (state, { payload }) => {
      state.params.page = payload.page;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload?.page) {
        state.params.page = payload.page;
      }
      if (payload?.limit) {
        state.params.limit = payload.limit;
      }
    },
    handleSetSearch: (state, { payload }) => {
      state.params.search = payload?.value;
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
    handleParams: (state, { payload }) => {
      state.params[payload?.field] = payload?.value;
      return state;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetLimit,
  handleSetSearch,
  handleParamsLimit,
  handleParams,

  handleSetPageAndLimit,
} = usersSlice.actions;

export default usersSlice.reducer;
