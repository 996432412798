import React from "react";

import { ChevronDown } from "react-feather";
import { Card, CardBody } from "reactstrap";
import { DataTable, ProgressPage } from "components/structure";

import { useQueimaAndRiscoNotas } from "useCases/reports/QueimaCaixa";
import { columns } from "./columns";

import { Header } from "./Header";

const customStyles = {
  rows: {
    style: {
      padding: "10px 0 10px 0",
    },
  },
};

export const TableListNotes = ({
  data,
  paramsSearch,
  handlePageChange,
  handleChangeFilter,
}) => {
  const { data: dataNota, isLoading: isLoadingNota } = useQueimaAndRiscoNotas({
    cprod: data?.data?.cprod,
    ...paramsSearch,
  });

  return (
    <>
      <Card>
        <CardBody>
          <Header
            handleChangeFilter={handleChangeFilter}
            value={paramsSearch?.id}
            handlePageChange={handlePageChange}
          />
          <DataTable
            noHeader
            sortServer
            pagination
            expandableRowsHideExpander
            subHeader={false}
            isLoading={isLoadingNota}
            columns={columns}
            responsive={true}
            page={paramsSearch?.page}
            totalPages={dataNota?.max_pages}
            customStyles={customStyles}
            data={dataNota?.data || []}
            sortIcon={<ChevronDown />}
            className="react-dataTable"
            defaultSortField="fornecedor"
            handlePageChange={handlePageChange}
            paginationPosition="justify-content-start"
            progressComponent={<ProgressPage />}
          />
        </CardBody>
      </Card>
    </>
  );
};
