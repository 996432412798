import { useEffect, useState } from "react";
import useSWR from "swr";
import { useAuth } from "context/AuthContext";
import {
  useAPI,
  useAPIAuthenticate,
  useAPINFE2,
  useAPIMixPricing,
  useAPIRobot,
} from "./useAPI";

import { useMixApi1 } from "./useMixApi";

import { useToast } from "./useToast";

import { useAPILinkMix } from "./useLinkMixAPI";
import { useApiIntegrationBlueSoft } from "./useIntegrationBlue";

export const useFetch = (url, axiosConfigs, config) => {
  const api = useAPI();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      if (config?.method === "POST") {
        const response = await api.post(urlReturn, axiosConfigs);

        return response.data;
      }
      const response = await api.get(urlReturn, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchAuthenticate = (url, axiosConfigs, config) => {
  const api = useAPIAuthenticate();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Revalida todas as requisicoes para carregar os dados corretamente!
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
      setRevalidateAll();
    }
  }, [tokenCompanies, revalidate, revalidateAll, setRevalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchIntegrationBlue = (url, axiosConfigs, config) => {
  const api = useApiIntegrationBlueSoft();
  const { user } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Revalida todas as requisicoes para carregar os dados corretamente!
  useEffect(() => {
    if (user?.length) {
      revalidate();
      setRevalidateAll();
    }
  }, [user, revalidate]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const usePostFetchAuthenticate = (
  url,
  payload,
  axiosConfigs,
  config,
) => {
  const api = useAPIAuthenticate();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    [url, JSON.stringify(payload)],
    async urlReturn => {
      const response = await api.post(urlReturn, payload, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

// TODO: Temporario!!!
export const useFetchNfe = (url, axiosConfigs, config) => {
  const api = useAPINFE2();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

// TODO: Temporario!!!
export const useFetchNfePost = (url, payload, axiosConfigs, config) => {
  const api = useAPINFE2();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    [url, JSON.stringify(payload)],
    async urlReturn => {
      const response = await api.post(urlReturn, payload, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchIntregationPost = (url, payload, axiosConfigs, config) => {
  const api = useAPILinkMix();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    [url, JSON.stringify(payload)],
    async urlReturn => {
      const response = await api.post(urlReturn, payload, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchIntegration = (url, axiosConfigs, config) => {
  const api = useAPILinkMix();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchMixPricing = (url, axiosConfigs, config) => {
  const api = useAPIMixPricing();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchRobot = (url, axiosConfigs, config) => {
  const api = useAPIRobot();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Revalida todas as requisicoes para carregar os dados corretamente!
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
      setRevalidateAll();
    }
  }, [tokenCompanies, revalidate, revalidateAll, setRevalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useAPIRobotToast = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const api = useAPIRobot();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      addToast({
        type: "success",
        title: "SUCESSO",
        description: messageSuccess,
      });
      setData(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      setData(err);
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    onSend,
  };
};

// FIXME: Retirar pois vai ficar bem duplicado!!!!!!!
export const useAPIRobotProxyToast = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const api = useMixApi1();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      addToast({
        type: "success",
        title: "SUCESSO",
        description: messageSuccess,
      });
      setData(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      setData(err);
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    onSend,
  };
};
