import { useState, useCallback } from "react";
import { useAPIAuthenticate, useToast, useAPIAuthenticateToast } from "hooks";
import { getURLWithParams } from "utils/context";
import { validatePassword } from "utils/utils";

export const useSetNewPassword = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();
  const { addToast } = useToast();
  const onSave = (token, field) => {
    const validation = validatePassword(field?.password);
    const validationConfirm = validatePassword(field?.confirmPassword);

    if (!validation || !validationConfirm) {
      return addToast({
        title: "Usuário error senha",
        type: "error",
        description: `Precisa conter 8 caracteres, letras minúsculas,
                letras maiúsculas, números e caracteres especiais.`,
      });
    }

    if (field?.password !== field?.confirmPassword) {
      return addToast({
        title: "Usuário",
        type: "error",
        description: `Os campos de senha não coincidem`,
      });
    }
    onSend({
      endpoint: `/changePassword/${token}`,
      messageSuccess: `Senha alterada com sucesso!`,
      messageFail: "Falha ao tentar remover salvar nova senha!",
      data: field,
      type: "POST",
    });
  };

  return {
    onSave,
    isLoading,
  };
};

export const useCheckTokenNewPassword = () => {
  const api = useAPIAuthenticate();
  const [isShow, setShow] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(
    token => {
      setLoading(true);
      const fetchData = async () => {
        try {
          await api.post(getURLWithParams(`/checkToken/${token}`));
          setShow(true);
          setLoading(false);
        } catch (e) {
          setShow(false);
          setLoading(false);
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, isShow, fetch };
};
