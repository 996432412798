import { createSlice } from "@reduxjs/toolkit";
import {
  setSessionModalInfo,
  setSessionModalInfoData,
  getSessionModalData,
} from "utils/session";

export const initialDialog = {
  isShowModalAlert: false,
  dialogMsg: {},
};

const initialState = () => {
  const session = getSessionModalData();
  const sessionItems = session ? JSON.parse(session) : null;
  return initialDialog || sessionItems;
};

export const dialogSlice = createSlice({
  name: "dialogAlert",
  initialState,
  reducers: {
    handleToogleDialog: (state, { payload }) => {
      state.isShowModalAlert = payload;
      if (payload === false) {
        setSessionModalInfo(true);
      }
    },
    handleSetDataDialog: (state, { payload }) => {
      state.dialogMsg = payload;
      setSessionModalInfoData(
        JSON.stringify({ isShowModalAlert: true, dialogMsg: { ...payload } }),
      );
    },
  },
});

export const { handleToogleDialog, handleSetDataDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
