import { createSlice } from "@reduxjs/toolkit";

export const initialCest = {
  list: [],
  params: {
    page: 1,
    limit: 100,
  },
  type: "",
  totalPages: 0,
};

const initialState = () => {
  return initialCest;
};

export const cestSlice = createSlice({
  name: "cest",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      state.page = payload.page;
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      state.params.page = payload.page;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetPageAndLimit,
  handleParamsLimit,
} = cestSlice.actions;

export default cestSlice.reducer;
