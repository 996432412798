import styled, { css } from "styled-components";

import InputMask from "react-input-mask";

export const InputMaskCustom = styled(InputMask)`
  ${({ skin }) => css`
    background-color: ${skin === "dark" ? "#283046" : "#fff"} !important;
    border: 1px solid ${skin === "dark" ? "#404656" : "#d8d6de"} !important;
    color: ${skin === "dark" ? "#b4b7bd" : "#404656"} !important;

    &:hover,
    &:active,
    &:focus {
      border-color: #7367f0 !important;
      box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
    }
  `};
`;

export const CurrencyField = styled.div`
  & > input {
    width: 100%;
    border: 1px solid #d8d6de;
    padding: 4px;
    border-radius: 4px;
    min-height: 36px;

    &:hover,
    &:focus,
    &:active,
    &:focus-visible,
    &:visible {
      border-color: #7367f0;
      box-shadow: 0 0 0 1px #7367f0;
    }
  }
`;

const inputFieldModifiers = {
  disabled: () => css`
    background-color: rgb(179 179 179 / 24%);
    border-color: rgb(179 179 179 / 24%);
  `,
};

export const InputField = styled.input`
  ${({ error, disabled }) => css`
    transition: all 0.2s ease 0s;
    padding: 6px 8px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    width: 100%;

    color: ${error ? "#f48fb1" : "rgb(89, 103, 120)"};
    border: ${error ? "1px solid #f48fb1" : "1px solid rgb(89, 103, 120)"};

    ${disabled && inputFieldModifiers.disabled()}

    &:hover, &:active, &:focus {
      border-color: #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    }
  `}
`;

export const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 30px 12px 0 29px;
`;

export const InputFieldContainer = styled.div`
  ${({ width }) => css`
    width: ${width || "100%"};
    position: relative;
  `}
`;

export const InputFieldLabel = styled.label`
  ${({ error }) => css`
    color: ${error ? "#f48fb1" : ""};
    font-size: 14px;
    margin-left: 8px;
    display: block;
    width: 100%;
  `}
`;

export const InputFieldHelperText = styled.label`
  ${({ error }) => css`
    display: ${error ? "block" : "none"};
    color: ${error ? "#f48fb1" : ""};
    font-size: 12px;
    margin-left: 8px;
  `}
`;
