import { transformListSelect } from "utils/utils";
export const MSQ_TYPES_PEDIDO_ASSOCIATION = {
  SUCCESS: "Número do pedido enviado",
  ERROR: "Não enviado, por loja não é obrigatório o número",
};

export const MSQ_TYPES_NOTE_TYPE_ASSOCIATION = {
  SUCCESS: "Tipo da nota foi enviado",
  ERROR: "Não foi possivel enviar o tipo da nota",
};

export const OPTIONS_LIST_ASSOCIATION_NOTE_TYPE = {
  "00": "Mercadoria para Revenda",
  "001": "Bonificação",
  "01": "Matéria-prima",
  "02": "Embalagem",
  "07": "Material de Uso e Consumo",
  "08": "Ativo Imobilizado",
};

export const OPTIONS_LIST_ASSOCIATION_NOTE_TYPE_COLOR = {
  "00": "secondary",
  "001": "danger",
  "01": "warning",
  "02": "info",
  "07": "success",
  "08": "warning",
};

export const OPTIONS_LIST_ASSOCIATION_NOTE_TYPE_LIST = transformListSelect(
  OPTIONS_LIST_ASSOCIATION_NOTE_TYPE,
);

export const PEDIDO_REQUEST_TYPE = {
  NOT: "Nenhum",
  "NOT-WORK": "Não trabalha",
  MANDATORY: "Obrigatorio",
  "NOT-MANDATORY": "Não e obrigatorio",
  "NOT-REQUEST": "Não trabalhamos com pedido",
};

export const STATUS_CONTRATO = {
  DEGUSTACAO: "Degustação",
  CANCELADO: "Cancelado",
  CONTRATO_FECHADO: "Contrato fechado",
};

export const CONTRACT_TYPES = {
  DEGUSTACAO: "DEGUSTACAO",
  CANCELADO: "CANCELADO",
  CONTRATO_FECHADO: "CONTRATO_FECHADO",
};

export const PEDIDO_REQUEST_TYPE_LIST =
  transformListSelect(PEDIDO_REQUEST_TYPE);

export const STATUS_CONTRATO_LIST = transformListSelect(STATUS_CONTRATO);

export const STATUS_TYPE = {
  true: "Pendente",
  false: "Associado",
};
export const STATUS_TYPE_COLOR = {
  true: "danger",
  false: "success",
  "em-verificacao": "primary",
  recebida: "success",
  "nota-marcada": "warning",
};

export const STATUS_TYPE_NOTA = {
  "em-verificacao": "Processo de Recebimento",
  recebida: "Nota Recebida",
  "nota-marcada": "Para verificação fiscal",
  validation: "Nota Válidada por o fiscal",
  cancelada: "Nota Cancelada",
};

export const STATUS_TYPE_COLOR_ALL = {
  true: "danger",
  false: "success",
  "em-verificacao": "primary",
  aguardando_associacao: "danger",
  recebida: "success",
  "nota-escriturada": "success",
  "nota-marcada": "warning",
  classificacao: "warning",
  classificado: "success",
  escriturando: "danger",
  validation: "info",
  cancelada: "danger",
};

export const ALL_SELECTE_SEARCH = {
  all: "Todos",
};

export const STATUS_TYPE_NOTA_ALL = {
  "em-verificacao": "Em verificação",
  recebida: "Nota Recebida",
  aguardando_associacao: "Aguardando Associação",
  "nota-marcada": "Nota marcada para validação fiscal",
  validation: "Nota Válidada",
  classificacao: "EM-CLASSIFICAÇÃO",
  classificado: "CLASSIFICADA",
  escriturando: "ESCRITURANDO",
  "nota-escriturada": "Nota Escriturada",
  cancelada: "Nota Cancelada",
};

export const STATUS_LIST_UPDATE = [
  {
    label: "Nota Recebida",
    value: "recebida",
  },

  {
    label: "Nota Válidada",
    value: "validation",
  },
  {
    label: "Em-classificação",
    value: "classificacao",
  },
  {
    label: "Classificada",
    value: "classificado",
  },
  {
    label: "ESCRITURANDO",
    value: "escriturando",
  },
  {
    label: "Nota Escriturada",
    value: "nota-escriturada",
  },
  {
    label: "Cancelada",
    value: "cancelada",
  },
];

export const SELECT_STATUS_FILTER = {
  ...ALL_SELECTE_SEARCH,
  ...STATUS_TYPE_NOTA_ALL,
};

export const SELECT_STATUS_LIST = transformListSelect(SELECT_STATUS_FILTER);

export const PARAMETER_TYPES_OBJECT = {
  icms: "ICMS",
  "pis-cofins": "Pis/Cofins",
};

export const PARAMETER_TYPES = [
  {
    label: "ICMS",
    value: "icms",
  },
  {
    label: "Pis/Cofins",
    value: "pis-cofins",
  },
];

export const CFOPS_ENTRIES_TYPES = [
  {
    cfop: 5101,
    description: "Venda de produção do estabelecimento",
    enabled: true,
  },
  {
    cfop: 5102,
    description: "Venda de mercadoria adquirida ou recebida de terceiros",
    enabled: true,
  },
  {
    cfop: 5402,
    description:
      "Venda de produção do estabelecimento de produto sujeito ao regime de substituição tributária, em operação entre contribuintes substitutos do mesmo produto.",
    enabled: true,
  },
  {
    cfop: 5103,
    description:
      "Venda de produção do estabelecimento, efetuada fora do estabelecimento",
    enabled: true,
  },
  {
    cfop: 5104,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento",
    enabled: true,
  },
  {
    cfop: 5105,
    description:
      "Venda de produção do estabelecimento que não deva por ele transitar",
    enabled: true,
  },
  {
    cfop: 5106,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar",
    enabled: true,
  },
  {
    cfop: 5111,
    description:
      "Venda de produção do estabelecimento remetida anteriormente em consignação industrial",
    enabled: true,
  },
  {
    cfop: 5112,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação industrial",
    enabled: true,
  },
  {
    cfop: 5113,
    description:
      "Venda de produção do estabelecimento remetida anteriormente em consignação mercantil",
    enabled: true,
  },
  {
    cfop: 5114,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação mercantil",
    enabled: true,
  },
  {
    cfop: 5115,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil",
    enabled: true,
  },
  {
    cfop: 5116,
    description:
      "Venda de produção do estabelecimento originada de encomenda para entrega futura",
    enabled: true,
  },
  {
    cfop: 5117,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, originada de encomenda para entrega futura",
    enabled: true,
  },
  {
    cfop: 5118,
    description:
      "Venda de produção do estabelecimento entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    enabled: true,
  },
  {
    cfop: 5119,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    enabled: true,
  },
  {
    cfop: 5120,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário pelo vendedor remetente, em venda à ordem",
    enabled: true,
  },
  {
    cfop: 5122,
    description:
      "Venda de produção do estabelecimento remetida para industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    enabled: true,
  },
  {
    cfop: 5123,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros remetida para industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    enabled: true,
  },
  {
    cfop: 5124,
    description: "Industrialização efetuada para outra empresa",
    enabled: true,
  },
  {
    cfop: 5125,
    description:
      "Industrialização efetuada para outra empresa quando a mercadoria recebida para utilização no processo de industrialização não transitar pelo estabelecimento adquirente da mercadoria",
    enabled: true,
  },
  {
    cfop: 5401,
    description:
      "Venda de produção do estabelecimento quando o produto esteja sujeito ao regime de substituição tributária",
    enabled: true,
  },
  {
    cfop: 5403,
    description:
      "Venda de mercadoria, adquirida ou recebida de terceiros, sujeita ao regime de substituição tributária, na condição de contribuinte-substituto",
    enabled: true,
  },
  {
    cfop: 5405,
    description:
      "Venda de mercadoria, adquirida ou recebida de terceiros, sujeita ao regime de substituição tributária, na condição de contribuinte-substituído",
    enabled: true,
  },
  {
    cfop: 5901,
    description: "Remessa para industrialização por encomenda",
    enabled: true,
  },
  {
    cfop: 5902,
    description:
      "Retorno de mercadoria utilizada na industrialização por encomenda",
    enabled: true,
  },
  {
    cfop: 5904,
    description: "Remessa para venda fora do estabelecimento",
    enabled: true,
  },
  {
    cfop: 5907,
    description:
      "Retorno simbólico de mercadoria depositada em depósito fechado ou armazém geral",
    enabled: true,
  },
  {
    cfop: 5910,
    description: "Remessa em bonificação, doação ou brinde",
    enabled: true,
  },
  {
    cfop: 5914,
    description: "Remessa de mercadoria ou bem para exposição ou feira",
    enabled: true,
  },
  {
    cfop: 5924,
    description:
      "Remessa para industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente",
    enabled: true,
  },
  {
    cfop: 5925,
    description:
      "Retorno de mercadoria recebida para industrialização por conta e ordem do adquirente da mercadoria, quando aquela não transitar pelo estabelecimento do adquirente",
    enabled: true,
  },
  {
    cfop: 5949,
    description:
      "Outra saída de mercadoria ou prestação de serviço não especificado",
  },
  {
    cfop: 6101,
    description: "Venda de produção do estabelecimento",
    enabled: true,
  },
  {
    cfop: 6102,
    description: "Venda de mercadoria adquirida ou recebida de terceiros",
    enabled: true,
  },
  {
    cfop: 6103,
    description:
      "Venda de produção do estabelecimento, efetuada fora do estabelecimento",
    enabled: true,
  },
  {
    cfop: 6104,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento",
    enabled: true,
  },
  {
    cfop: 6105,
    description:
      "Venda de produção do estabelecimento que não deva por ele transitar",
    enabled: true,
  },
  {
    cfop: 6106,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar",
    enabled: true,
  },
  {
    cfop: 6107,
    description:
      "Venda de produção do estabelecimento, destinada a não contribuinte",
    enabled: true,
  },
  {
    cfop: 6108,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte",
    enabled: true,
  },
  {
    cfop: 6111,
    description:
      "Venda de produção do estabelecimento remetida anteriormente em consignação industrial",
    enabled: true,
  },
  {
    cfop: 6112,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação industrial",
    enabled: true,
  },
  {
    cfop: 6113,
    description:
      "Venda de produção do estabelecimento remetida anteriormente em consignação mercantil",
    enabled: true,
  },
  {
    cfop: 6114,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação mercantil",
    enabled: true,
  },
  {
    cfop: 6115,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil",
    enabled: true,
  },
  {
    cfop: 6116,
    description:
      "Venda de produção do estabelecimento originada de encomenda para entrega futura",
    enabled: true,
  },
  {
    cfop: 6117,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros, originada de encomenda para entrega futurVenda de mercadoria adquirida ou recebida de terceiros, originada de encomenda para entrega futurVenda de mercadoria adquirida ou recebida de terceiros, originada de encomenda para entrega futurVenda de mercadoria adquirida ou recebida de terceiros, originada de encomenda para entrega futura",
    enabled: true,
  },
  {
    cfop: 6118,
    description:
      "Venda de produção do estabelecimento entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    enabled: true,
  },
  {
    cfop: 6119,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem",
    enabled: true,
  },
  {
    cfop: 6120,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário pelo vendedor remetente, em venda à ordem",
    enabled: true,
  },
  {
    cfop: 6122,
    description:
      "Venda de produção do estabelecimento remetida para industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    enabled: true,
  },
  {
    cfop: 6123,
    description:
      "Venda de mercadoria adquirida ou recebida de terceiros remetida para industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente",
    enabled: true,
  },
  {
    cfop: 6124,
    description: "Industrialização efetuada para outra empresa",
    enabled: true,
  },
  {
    cfop: 6125,
    description:
      "Industrialização efetuada para outra empresa quando a mercadoria recebida para utilização no processo de industrialização não transitar pelo estabelecimento adquirente da mercadoriIndustrialização efetuada para outra empresa quando a mercadoria recebida para utilização no processo de industrialização não transitar pelo estabelecimento adquirente da mercadoria",
    enabled: true,
  },
  {
    cfop: 6401,
    description:
      "Venda de produção do estabelecimento em operação com produto sujeito ao regime de substituição tributária, na condição de contribuinte substituto",
    enabled: true,
  },
  {
    cfop: 6403,
    description:
      "Venda de mercadoria, adquirida ou recebida de terceiros, sujeita ao regime de substituição tributária, na condição de contribuinte-substituto",
    enabled: true,
  },
  {
    cfop: 6404,
    description:
      "Venda de mercadoria sujeita ao regime de substituição tributária, cujo imposto já tenha sido retido anteriormente",
    enabled: true,
  },
  {
    cfop: 6910,
    description: "Remessa em bonificação, doação ou brinde.",
    enabled: true,
  },
  {
    cfop: 5936,
    description: "Remessa de bonificação",
    enabled: true,
  },
  {
    cfop: 6936,
    description: "Remessa de bonificação",
    enabled: true,
  },
  {
    cfop: 6949,
    description:
      "Outra saída de mercadoria ou prestação de serviço não especificado",
    enabled: true,
  },
];

export const TYPES_STATUS_VINCULO = {
  VINCULO_CODIGO_INTERNO_FORNECEDOR_CPROD: "Vinculo codigo interno Fornecedor",
  VINCULO_CODIGO_INTERNO_CORRETO: "Vinculo codigo interno",
  VINCULO_EAN_PRODUTO_CORRETO: "Vinculo ean Produto correto",
  VINCULO_DESCRICAO_PRODUTO_CORRETO: "Vinculo descriçao",
  VINCULO_ITEM_CONHECIDO: "Vinculo item Conhecido",
  SEM_ASSOCIACAO: "Não associado",
  ASSOCIADO: "ASSOCIADO",
};

const success = "success";
const danger = "danger";

export const TYPES_STATUS_VINCULO_COLOR = {
  VINCULO_CODIGO_INTERNO_FORNECEDOR_CPROD: success,
  VINCULO_CODIGO_INTERNO_CORRETO: success,
  VINCULO_EAN_PRODUTO_CORRETO: success,
  VINCULO_DESCRICAO_PRODUTO_CORRETO: success,
  VINCULO_ITEM_CONHECIDO: success,
  SEM_ASSOCIACAO: danger,
  ASSOCIADO: success,
};
export const TYPES_STATUS_VINCULO_BOOLEAN = {
  VINCULO_CODIGO_INTERNO_FORNECEDOR_CPROD: true,
  VINCULO_CODIGO_INTERNO_CORRETO: true,
  VINCULO_EAN_PRODUTO_CORRETO: true,
  VINCULO_DESCRICAO_PRODUTO_CORRETO: true,
  VINCULO_ITEM_CONHECIDO: true,
  SEM_ASSOCIACAO: false,
  ASSOCIADO: true,
};
