import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  figureDetail: {
    id: null,
    open: false,
  },
  paramsfigura: {
    page: 1,
    limit: 10,
  },
  paramsproduto: {
    page: 1,
    limit: 10,
  },
};

const reducers = {
  handleParamsFigura: (state, { payload }) => {
    const field = payload?.field;
    state.paramsfigura[field] = payload?.value;
    return state;
  },
  handleParamsProduto: (state, { payload }) => {
    const field = payload?.field;
    state.paramsproduto[field] = payload?.value;
    return state;
  },

  handleFigureDetailOpenModal: (state, { payload }) => {
    state.figureDetail.id = payload.id;
    state.figureDetail.open = true;

    return state;
  },

  handleFigureDetailCloseModal: state => {
    state.figureDetail.id = null;
    state.figureDetail.open = false;

    return state;
  },
};

const figurasFiscaisSlice = createSlice({
  name: "bluesoftFigurasFiscais",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = figurasFiscaisSlice.reducer;
export const actions = figurasFiscaisSlice.actions;

export default reducer;
