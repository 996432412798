import { createSlice } from "@reduxjs/toolkit";

export const initial = {
  list: [],
};

const initialState = () => {
  return initial;
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = payload;
    },
  },
});

export const { handleSetList } = notificationSlice.actions;

export default notificationSlice.reducer;
