export const SOLICITATION_ENDPOINTS = [
  {
    nome: "Pis",
    value: "PIS",
  },
  {
    nome: "Cofins",
    value: "cofins",
  },
  {
    nome: "Icms",
    value: "icms",
  },
  {
    nome: "Destinação Fiscal",
    value: "DESTINACAO",
  },
  {
    nome: "Operação Fiscal",
    value: "operacao",
  },
  {
    nome: "Mercadológico",
    value: "mercadologico",
  },
];
