import { createSlice } from "@reduxjs/toolkit";

const LOCAL_NAME = "app-interno-lista-clientes";

const initialState = () => {
  const headerData = window.localStorage.getItem(LOCAL_NAME)
    ? JSON.parse(window.localStorage.getItem(LOCAL_NAME))
    : {};

  return {
    ...headerData,
    page: 1,
    filter: {
      onlyMyWallet: true,
      isActive: true,
    },
    sort: null,
  };
};

export const setSaveState = (values = {}) =>
  window.localStorage.setItem(LOCAL_NAME, JSON.stringify(values));

// TODO: Mudar nome e escolher um melhor!
export const appSlice = createSlice({
  name: "internoListaClientes",
  initialState: initialState(),
  reducers: {
    handleChangePage: (state, action) => {
      state.page = action.payload;
    },

    handleFilterSubmit: (state, action) => {
      state.filter = action.payload;
    },
    handleSort: (state, action) => {
      state.sort = action.payload;
    },
  },
});

export const { handleChangePage, handleFilterSubmit, handleSort } =
  appSlice.actions;

export default appSlice.reducer;
