import { createSlice } from "@reduxjs/toolkit";

export const inicialCategoria = {
  params: {
    page: 1,
    limit: 15,
  },
  isShowModal: {
    show: false,
    showCaso: false,
    showEspecificacao: false,
  },
};

const initialState = () => {
  return inicialCategoria;
};

export const CategoriaSlice = createSlice({
  name: "categoria",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      if (payload?.isDelete) {
        delete state.params[field];
      } else {
        state.params[field] = payload?.value;
      }
      return state;
    },
    handleClearParams: (state, { payload }) => {
      const field = payload?.field;
      delete state.params[field];
      return state;
    },
    handleOpenModal: (state, { payload }) => {
      state.isShowModal.show = payload;
    },
    handleOpenCasoModal: (state, { payload }) => {
      state.isShowModal.showCaso = payload;
    },
    handleEspecificacaoModal: (state, { payload }) => {
      state.isShowModal.showEspecificacao = payload;
    },
  },
});

export const {
  handleParams,
  handleClearParams,
  handleOpenModal,
  handleOpenCasoModal,
  handleEspecificacaoModal,
} = CategoriaSlice.actions;
export default CategoriaSlice.reducer;
