/* eslint-disable import/no-extraneous-dependencies */
// ** React Imports
import React from "react";

import BarcodeScannerComponent from "react-webcam-barcode-scanner";

import * as S from "./barcodeScreen.styles";
import { X } from "react-feather";

export const BarCodeScanner = ({ setSearch, close }) => {
  const onUpdateScreen = (err, result) => {
    if (result) {
      setSearch(result.text);
      close(false);
    } else {
    }
  };

  return (
    <S.BarcodeScannerCustom>
      <div
        className="d-none d-lg-block text-center"
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <S.ButtonCloseCustom onClick={() => close(false)} color="primary">
          <X size={25} />
        </S.ButtonCloseCustom>
        <BarcodeScannerComponent
          width={"100%"}
          height={400}
          onUpdate={(err, result) => onUpdateScreen(err, result)}
        />
      </div>
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
        className="d-lg-none d-xl-none  text-center"
      >
        <S.ButtonCloseCustom onClick={() => close(false)} color="primary">
          <X size={25} />
        </S.ButtonCloseCustom>
        <BarcodeScannerComponent
          width={"100%"}
          height={"100%"}
          onUpdate={(err, result) => onUpdateScreen(err, result)}
        />
      </div>
    </S.BarcodeScannerCustom>
  );
};
