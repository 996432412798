export const MOVEMENT_TYPES = [
  {
    label: "ENTRADA",
    value: "ENTRADA",
  },
  {
    label: "ENTRADA COMPRA",
    value: "ENTRADA_C",
  },
  {
    label: "TRANSFERÊNCIA",
    value: "TRANSF",
  },

  {
    label: "DEVOLUÇÃO COMPRA",
    value: "DEVOLUC_C",
  },
  {
    label: "SAIDA",
    value: "SAIDA",
  },
  {
    label: "SAIDA COMPRA",
    value: "SAIDA_C",
  },
  {
    label: "TODOS",
    value: "TODOS",
  },
];

export const CartTotaisCfopsaLabels = {
  DEVOLUC_C: "Devolução compra",
  ENTRADA: "Entrada",
  ENTRADA_C: "Entrada compra",
  SAIDA: "Saída",
  SAIDA_C: "Saída compra",
  TRANSF: "Transferência",
};
