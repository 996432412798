import { useState, useCallback } from "react";

import { useAPIAuthenticate, useAPIAuthenticateToast } from "hooks";

export const useNotificationDeliveri = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onDeliveri = data =>
    onSend({
      endpoint: "/notification/deliveri",
      messageSuccess: "",
      messageFail: "",
      data,
      disabledMsg: true,
    });

  return {
    onDeliveri,
    isLoading,
  };
};

export const UseGetNotifications = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const fetch = useCallback(
    ({ pooling = false }) => {
      setLoading(!pooling);
      const url = `?page=1&limit=50&type=new`;
      const fetchData = async () => {
        try {
          const {
            data: { notifications },
          } = await api.get(`/notifications${url}`);
          setData(notifications.docs);

          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(e.messagge);
          setIsError(true);
        }
      };
      fetchData(url);
    },
    [api],
  );

  return { isLoading: loading, data, fetch, isError };
};

export const UseNotificationUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateEnd, setIsUpdateEnd] = useState(false);
  const [isError, setIsError] = useState(false);
  const api = useAPIAuthenticate();

  const fetch = async (params = {}) => {
    try {
      setIsLoading(true);
      const { data } = await (!params?.fields?.data?._id
        ? api.post(`/notification`, params)
        : api.put(
            `/notification/${params?.fields?.data?._id}`,
            params?.fields?.data,
          ));

      if (data.response) {
        setIsUpdateEnd(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsUpdateEnd(false);
      setIsLoading(false);
    }
  };

  return {
    fetch,
    isError,
    isUpdateEnd,
    isLoading,
  };
};
