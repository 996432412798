import { createSlice } from "@reduxjs/toolkit";

export const initialGeneralAnalytics = {
  params: {
    limit: 10,
    page: 1,
    operation: "VENDA",
    tipoDivergencia: ["RISCO", "QUEIMA"],
  },
};

const initialState = () => initialGeneralAnalytics;

export const GeneralAnalyticSlice = createSlice({
  name: "generalAnalytics",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
  },
});

export const { handleParams } = GeneralAnalyticSlice.actions;

export default GeneralAnalyticSlice.reducer;
