export const companiesByStores = list => {
  console.log({ list });
  const values = list.reduce((previous, current) => {
    if (!previous[current?.company?._id]) {
      return {
        [current?.company?._id]: {
          ...current.company,
          stores: [current],
        },
        ...previous,
      };
    }
    return {
      ...previous,
      [current?.company?._id]: {
        ...previous[current?.company?._id],
        stores: [...previous[current?.company?._id].stores, ...[current]],
      },
    };
  }, {});

  return Object.values(values);
};

export const storesUniqs = list => {
  const values = list.reduce((previous, current) => {
    if (!previous[current._id]) {
      return {
        ...previous,
        [current._id]: current,
      };
    }

    return { ...previous };
  }, []);

  return Object.values(values);
};
