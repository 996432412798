import React, { useState } from "react";

import { Card, CardBody, Row, Col, Spinner, Collapse } from "reactstrap";

import { AlertList } from "components/structure/AlertList";

import { DataTableMobile } from "./Table/index";
import { Badges } from "./Table/Badges";
import { Headers } from "./Headers";
import { Actions } from "./Table/Actions";
import { CustomPagination } from "../Pagination";
import * as S from "./mobileTable.styles";
import { ChevronDown, ChevronUp } from "react-feather";

const NoData = () => (
  <AlertList
    title="Informativo"
    description="Ainda não há dados cadastrados nessa seção."
  />
);

export const ProgressPage = () => (
  <div className="text-center pt-4 pb-4">
    <Spinner />
    <h4>Carregando dados....</h4>
  </div>
);

const MobileTable = props => {
  const { handlePageChange, childrenExpendade, handleClick } = props;

  const [isExpendade, setExpendade] = useState(null);

  const toggle = index => setExpendade(isExpendade === index ? null : index);

  return (
    <Row className="d-lg-none d-xl-none ">
      {props?.isLoading ? (
        <Col sm="12">
          <Card>
            <ProgressPage />
          </Card>
        </Col>
      ) : props?.data?.length > 0 ? (
        <>
          {(props?.data || []).map((item, index) => {
            return (
              <>
                <Col md="6" sm="12" className="mt-0 ">
                  <Card className="mb-1">
                    <Headers columns={props?.columns} dataItem={item} />

                    <CardBody>
                      <Col md="12">
                        <Badges columns={props?.columns} dataItem={item} />
                      </Col>
                      <Col md="12" className="mt-1">
                        <DataTableMobile
                          columns={props?.columns}
                          dataItem={item}
                        />
                      </Col>
                      {props?.expandableRows ? (
                        <>
                          <Row>
                            <Col md="12" onClick={() => toggle(index)}>
                              <S.CardHeaderAddingTitle>
                                <Row>
                                  <Col md="10" xs="10">
                                    <h4>
                                      {props?.titleAdding || "Dados adicionais"}
                                    </h4>
                                  </Col>
                                  <Col md="2" xs="2" className="text-end">
                                    {isExpendade === index ? (
                                      <ChevronUp size={16} />
                                    ) : (
                                      <ChevronDown size={16} />
                                    )}
                                  </Col>
                                </Row>
                              </S.CardHeaderAddingTitle>
                            </Col>
                          </Row>
                          {isExpendade === index ? (
                            <Collapse isOpen={Boolean(isExpendade === index)}>
                              {childrenExpendade({ data: item })}
                            </Collapse>
                          ) : null}
                        </>
                      ) : null}
                    </CardBody>
                    <Actions
                      columns={props?.columns}
                      dataItem={item}
                      handleClick={handleClick}
                    />
                  </Card>
                </Col>
              </>
            );
          })}
          <Col md="12" sm="12">
            <CustomPagination
              totalPages={props?.totalPages}
              handlePageChange={handlePageChange}
              page={props?.page}
            />
          </Col>
        </>
      ) : (
        <NoData />
      )}
    </Row>
  );
};

MobileTable.AppPros = {
  columns: [] || JSX.Element,
  data: [] || JSX.Element,
  page: Number,
  isLoading: Boolean,
  totalDocs: Number,
  totalPages: Number,
  handleClick: () => {},
  handlePageChange: () => {},
  handlePerRowsChange: () => {},
  titleAdding: String,
  expandableRows: Boolean,
  childrenExpendade: [] || JSX.Element,
};

export default MobileTable;
