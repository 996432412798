import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";

// ** Reactstrap Imports
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import * as S from "./Bradcrumb.styles";

export const Breadcrumbs = props => {
  // ** Props
  const { data, title } = props;

  const renderBreadCrumbs = () => {
    return data.map((item, index) => {
      const Wrapper = item.link ? Link : Fragment;
      const isLastItem = data.length - 1 === index;
      return (
        <S.BreadcrumbItem
          tag="li"
          key={index}
          active={!isLastItem}
          className={classnames({
            "text-primary": !isLastItem,
            "d-none": 1,
            "d-xl-block": 1,
          })}
        >
          <Wrapper {...(item.link ? { to: item.link } : {})}>
            {item.title}
          </Wrapper>
        </S.BreadcrumbItem>
      );
    });
  };

  return (
    <div
      className="content-header row"
      style={{ width: "100%", margin: "0px" }}
    >
      <div
        className="content-header-left col-md-12 col-12"
        style={{ padding: "0px" }}
      >
        <div className="row breadcrumbs-top">
          <div className="col-12">
            {title ? (
              <h3 className="content-header-title float-start mb-0">{title}</h3>
            ) : (
              ""
            )}
            <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12">
              <Breadcrumb>
                <BreadcrumbItem tag="li">
                  <Link to="/dashboards/home">Home</Link>
                </BreadcrumbItem>
                {renderBreadCrumbs()}
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
