import { createSlice } from "@reduxjs/toolkit";

export const initial = {
  params: {
    page: 1,
    limit: 8,
  },
};

const initialState = () => {
  return initial;
};

export const notificationAllSlice = createSlice({
  name: "notificationAll",
  initialState,
  reducers: {
    handleChangeParams: (state, { payload }) => {
      state.params[payload.field] = payload.value;
    },
  },
});

export const { handleChangeParams } = notificationAllSlice.actions;

export default notificationAllSlice.reducer;
