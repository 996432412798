import { createSlice } from "@reduxjs/toolkit";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);
const INITIAL_STATE = {
  isShowModalInvoiceCorrection: {
    show: false,
    prod_id: "",
    chave_note: "",
    data: {},
    showListProduct: true,
  },
  suppliersTop: {
    limit: 5,
    order: "desc",
    tipo: "ENTRADA",
  },
  suppliersBottom: {
    limit: 5,
    order: "asc",
    tipo: "ENTRADA",
  },
  isRevalidadeListClassEsc: false,
  params: {
    page: 1,
    limit: 20,
    status: "classificado",
  },
  isRevalidateListProduct: false,
  isShowModalRoles: {
    show: false,
    id: "",
    data: [],
  },

  downloadFilter: {
    start: firstDay,
    end: currentDay,
  },
};

const reducers = {
  handleAssociationListRevalidade: (state, { payload }) => {
    state.isRevalidadeListClassEsc = payload?.show;
  },
  handleRevalidateProductList: (state, { payload }) => {
    state.isRevalidateListProduct = payload?.show;
  },
  handleDateSolicitation: (state, { payload }) => {
    state.downloadFilter.start = payload.start;
    state.downloadFilter.end = payload.end;
  },
  handleAssociationModalInvoice: (state, { payload }) => {
    Object.keys(payload).map(field => {
      const value = payload[field];
      return (state.isShowModalInvoiceCorrection[field] = value);
    });
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
  handleEscriturationModalRolesNull: (state, { payload }) => {
    Object.keys(payload).map(field => {
      const value = payload[field];
      return (state.isShowModalRoles[field] = value);
    });
  },
  handleClearParams: (state, { payload }) => {
    const params = payload?.params;
    state.params = {};
    Object.keys(params)
      .filter(field => params[field] !== undefined && !params[field] !== "")
      .map(field => {
        state.params[field] = params[field];
      });
    return state;
  },
  handleParamsMultiples: (state, { payload }) => {
    const params = payload?.params;
    Object.keys(params)
      .filter(field => params[field] !== undefined && !params[field] !== "")
      .map(field => {
        return (state.params[field] = params[field]);
      });
    return state;
  },
};

const appEscrituracaoSlice = createSlice({
  name: "appEscrituracao",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = appEscrituracaoSlice.reducer;
export const actions = appEscrituracaoSlice.actions;
export default reducer;
