import { useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useIcmsNf = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("legislacao/nota-fiscal", params));

export const useIcmsNfDetail = id =>
  useFetchMixAPi1(id ? `legislacao/nota-fiscal/detalhe/${id}` : null);

export const useIcmsNfActionsCreate = id => {
  const { isLoading, data, onSend } = useMixApi1Toast();

  const onNfCreate = async data => {
    const res = await onSend({
      endpoint: id
        ? `legislacao/nota-fiscal/detalhe/${id}`
        : `legislacao/nota-fiscal/novo`,
      messageSuccess: id ? `Editado com sucesso` : `Criado com sucesso`,
      messageFail: `Requisão falhou!`,
      type: id ? "PUT" : "POST",
      data,
      redirect: id ? false : true,
      fieldGetId: "mxf_nf_id",
      urlRedirect: "/icms/notasfiscais/detail",
    });
    return res;
  };

  const onRemove = async () => {
    const res = await onSend({
      endpoint: `legislacao/nota-fiscal/detalhe/${id}`,
      messageSuccess: `Deletado com sucesso`,
      messageFail: `Requisão falhou!`,
      type: "DELETE",
    });
    return res;
  };

  return {
    onNfCreate,
    onRemove,
    isLoading,
    data,
  };
};
