import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  planSelect: "",
  planActual: "",
  slug: "services",
  form: {},
};

const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState: INITIAL_STATE,
  reducers: {
    handlePlanSelect: (state, { payload }) => {
      state.planSelect = payload?.value;
    },
    handleSlugSelect: (state, { payload }) => {
      state.slug = payload?.value;
    },
    handlePlanActual: (state, { payload }) => {
      state.planActual = payload?.value;
    },
    handleSubmitForm: (state, { payload }) => {
      state.form = payload;
    },
  },
});

export const actions = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
