import React from "react";

import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

export const DropDown = props => {
  const { title, data, onClick, selected, color = "primary" } = props;
  return (
    <React.Fragment>
      <UncontrolledButtonDropdown>
        <DropdownToggle {...props} color={color} caret>
          {selected || title || "Abrir"}
        </DropdownToggle>
        <DropdownMenu>
          {(data || []).map(item => {
            return (
              <DropdownItem onClick={() => onClick(item?.value)}>
                {item?.label}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </React.Fragment>
  );
};
