import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  search: "",
  isShowDialog: {
    show: false,
    id: "",
    data: {},
    modelo: "",
    user_id: "",
    tipo: "",
  },
  isShowDialogRevalidate: true,
  association: {
    show: false,
    prod_id: "",
    chave_note: "",
    data: {},
  },
  params: {},
  prodSelected: {},
  historicShow: false,
};

const reducers = {
  handleSearch: (state, { payload }) => {
    state.search = payload?.search;
  },
  handleHistoric: (state, { payload }) => {
    state.historicShow = payload?.show;
  },
  handleSelectProduct: (state, { payload }) => {
    state.prodSelected = payload.item;
  },
  handleRevalidadeDetail: (state, { payload }) => {
    state.isShowDialogRevalidate = payload?.isShowDialogRevalidate;
  },
  handleOpenModalAssociation: (state, { payload }) => {
    state.association.show = payload.show;
    state.association.prod_id = payload.id;
    state.association.chave_note = payload.chave_note;
    state.association.data = payload.row;
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },

  handleToogleDialogFields: (state, { payload }) => {
    Object.keys(payload).map(field => {
      const value = payload[field];

      return (state.isShowDialog[field] = value);
    });
  },
};

const escrituracaoAssociationSlice = createSlice({
  name: "escrituracaoAssociation",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = escrituracaoAssociationSlice.reducer;
export const actions = escrituracaoAssociationSlice.actions;
export default reducer;
