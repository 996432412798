import { useState, useCallback } from "react";
import { useToast } from "hooks";

import authenticationApi from "../../services/authenticationApi";

export const useRequestNewPassword = () => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const { addToast } = useToast();

  const token = localStorage.getItem("@mixfiscal:authenticatorToken");

  const fetch = useCallback(
    (field = {}) => {
      if (!field?.email) {
        return addToast({
          type: "error",
          title: "Campo email em branco.",
          description: `O campo email não pode ficar em branco`,
        });
      }
      setLoading(true);
      authenticationApi
        .post(
          `/forgetPassword`,
          {
            email: field?.email,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          addToast({
            type: "success",
            title: "Esqueceu a senha",
            description: `Foi enviado um email no ${field?.email} para redefinir a senha.`,
          });
          setLoading(false);

          // navigate("/login");
        })
        .catch(err => {
          addToast({
            type: "error",
            title: "Solicitação de senha",
            description: err?.response?.data?.message,
          });
          setData();
          setLoading(false);
        });
    },
    [token, addToast],
  );

  return { isLoading: loading, data, fetch };
};
