import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const initialState = {
  params: {
    page: 1,
    limit: 20,
  },
  issue_date_start: format(firstDay, "yyyy-MM-dd"),
  issue_date_end: format(currentDay, "yyyy-MM-dd"),
  total: 0,
  totalPages: 0,
  search: {},
};

const painelMachineSlice = createSlice({
  name: "painelMachine",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleParamsSearch: (state, { payload }) => {
      const field = payload?.field;
      const value = payload?.value;
      state.search[field] = value;
    },
    handleChangeDate: (state, { payload }) => {
      if (payload?.issue_date_start) {
        state.issue_date_start = payload.issue_date_start;
      }
    },
    handleChangeStatus: (state, { payload }) => {
      state.status = payload.status;
    },
  },
});

export const {
  handleParams,
  handleParamsSearch,
  handleChangeDate,
  handleChangeStatus,
} = painelMachineSlice.actions;

export default painelMachineSlice.reducer;
