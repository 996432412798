import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

import { columns } from "components/contexts/TaxAnalysis";

const today = new Date();
const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const dateStart = new Date(today.getFullYear(), today.getMonth(), 1);
const dateEnd = new Date(
  `${today.getFullYear}-${today.getMonth}-${lastDayMonth}`,
);

const initialtTaxAnalises = {
  list: [],
  listInvoice: [],
  title: "QUEIMA DE CAIXA",
  order: {
    column: "",
    value: "",
  },
  issue_date_start: format(dateStart, "yyyy-MM-dd"),
  issue_date_end: format(dateEnd, "yyyy-MM-dd"),
  changed: false,
  tipo: "",
  params: {
    page: 1,
    limit: 20,
  },
  paramsNote: {
    page: 1,
    limit: 10,
  },
  currentMonth: [],
  totalPages: 0,
  columns: [],
  columnsProducts: [],
  filters: [],
  taxType: "all",
};
const initialState = () => {
  return initialtTaxAnalises;
};

export const taxAnalisesSlice = createSlice({
  name: "taxAnalises",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
    },
    handleSetListInvoice: (state, { payload }) => {
      state.listInvoice = [...payload.list];
    },
    handleColumns: (state, { payload }) => {
      state.columns = [...payload];
    },
    handleParamsPage: (state, { payload }) => {
      state.params.page = payload.page;
    },
    handleParamsNote: (state, { payload }) => {
      const field = payload?.field;
      state.paramsNote[field] = payload.value;
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
    handleOrderUpdate: (state, { payload }) => {
      state.order.column = payload.column;
      state.order.value = payload.value;
    },
    handleParamsStatus: (state, { payload }) => {
      state.tipo = payload.status === "queima-caixa" ? "queima" : "risco";

      const setColumns = columns
        ?.filter(item => item?.isType === payload.status || !item?.isType)
        .map(item => item);

      state.columns = [...setColumns];
      if (payload.status === "queima-caixa") {
        state.title = "Queima de Caixa";
        state.order.column = "order_queima";
        state.order.value = "desc";
      }

      if (payload.status === "risco-assumido") {
        state.title = "Risco Assumido";
        state.order.column = "order_risco";
        state.order.value = "desc";
      }

      if (payload.status === "negociacao-fornecedor") {
        state.title = "Negociação Fornecedor";
      }
    },
    handleParamsStartEnd: (state, { payload }) => {
      if (payload?.issue_date_start) {
        state.issue_date_start = payload.issue_date_start;
      }
      if (payload?.issue_date_end) {
        state.issue_date_end = payload.issue_date_end;
      }
      state.changed = true;
    },

    handleParamsStart: (state, { payload }) => {
      state.issue_date_start = payload.issue_date_start;
      state.issue_date_end = payload.issue_date_end;
    },

    handleParamsEnd: (state, { payload }) => {
      state.issue_date_end = payload.issue_date_end;
    },
    handleTotalPages: (state, { payload }) => {
      state.totalPages = payload;
    },
    handleSetColumnsProducts: (state, { payload }) => {
      state.columnsProducts = payload;
    },

    handleChangeFilterTax: (state, { payload }) => {
      state.taxType = payload.taxType;
    },
  },
});

export const {
  handleSetList,
  handleColumns,
  handleTotalPages,
  handleSetListInvoice,
  handleParamsPage,
  handleParamsLimit,
  handleParamsStartEnd,
  handleParamsStatus,
  handleParamsEnd,
  handleParamsStart,
  handleOrderUpdate,
  handleSetColumnsProducts,
  handleChangeFilterTax,
  handleParamsNote,
} = taxAnalisesSlice.actions;

export default taxAnalisesSlice.reducer;
