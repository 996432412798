import moment from "moment";

export const operator = [
  {
    label: "Igual",
    value: "equals",
  },
  {
    label: "Contém",
    value: "contains",
  },
  {
    label: "Não Contém",
    value: "notContains",
  },
  {
    label: "termina com",
    value: "endsWith",
  },
  {
    label: "Vazio",
    value: "empty",
  },
  {
    label: "Não Vazio",
    value: "notEmpty",
  },
];

export const operatorOR = [
  {
    label: "E",
    value: "and",
  },
  {
    label: "OU",
    value: "or",
  },
];

export const getFilterInvoice = filters => {
  return filters?.map(item => {
    const {
      value: itemValue,
      start,
      end,
      columnField,
      operatorValue,
      columnOrField,
    } = item;

    const value = typeof itemValue === "string" ? itemValue : itemValue?.value;

    const date = Array.isArray(itemValue)
      ? {
          start: moment(start).format("YYYY-MM-DD"),
          end: moment(end).format("YYYY-MM-DD"),
        }
      : {};

    return {
      [columnField?.value]: {
        operatorValue: operatorValue?.value,
        columnOrField: columnOrField?.value,
        value,
        ...date,
      },
    };
  });
};
