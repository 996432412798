import { createSlice } from "@reduxjs/toolkit";

const InitialStateSpeds = {
  isOpenModal: {
    type: "",
    isOpen: false,
  },
  fieldsFilter: {
    page: 1,
    limit: 100,
  },
  isOpenModalProcess: false,
  fields: {},
  OpenModalReprocess: false,
  OpenModalDelete: false,
  Sped: {},
};

const initialState = () => {
  return InitialStateSpeds;
};

export const SpedsCreateSlice = createSlice({
  name: "speds",
  initialState,
  reducers: {
    handelOpenActionModal: (state, { payload }) => {
      state.isOpenModal.type = payload.type;
      state.isOpenModal.isOpen = payload.isOpen;
    },
    handleChangeModalProcess: (state, { payload }) => {
      state.isOpenModalProcess = payload?.isOpen;
    },
    handleChangePages: (state, { payload }) => {
      state.fieldsFilter.page = payload.page;
    },
    handleChangeFields: (state, { payload }) => {
      state.fieldsFilter = payload?.value;
    },
    handleOpenReprocess: (state, { payload }) => {
      state.OpenModalReprocess = payload;
    },
    handleOpenDelete: (state, { payload }) => {
      state.OpenModalDelete = payload;
    },
    handleChangeSped: (state, { payload }) => {
      state.Sped = payload.data;
    },
  },
});

export const {
  handleOpenReprocess,
  handleOpenDelete,
  handleChangeSped,
  handelOpenActionModal,
  handleChangePages,
  handleChangeFields,
  handleChangeModalProcess,
} = SpedsCreateSlice.actions;

export default SpedsCreateSlice.reducer;
