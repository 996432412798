import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
const today = new Date();
const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const dateStart = new Date(today.getFullYear(), today.getMonth(), 1);
const dateEnd = new Date(
  `${today.getFullYear}-${today.getMonth}-${lastDayMonth}`,
);

const INITIAL_STATE = {
  selecteds: [],
  search: "",
  invoiceSelected: {},
  openInvoice: {
    show: false,
    code: "",
    date: {},
  },

  selectedProduct: {
    show: false,
    xprod: "",
    data: {},
  },
  paramsNotes: {
    page: 1,
    limit: 20,
    numero_nota: "",
  },
  params: {
    page: 1,
    limit: 20,
    type: "",
    start: format(dateStart, "yyyy-MM-dd"),
    end: format(dateEnd, "yyyy-MM-dd"),
  },
};

const reducers = {
  handleSearch: (state, { payload }) => {
    state.search = payload?.search;
  },
  handleParamsNotesSearchPage: (state, { payload }) => {
    state.paramsNotes.page = payload?.page;
  },
  handleParamsNotesSearchLimit: (state, { payload }) => {
    state.paramsNotes.limit = payload?.limit;
  },
  handleInvoiceSelected: (state, { payload }) => {
    state.invoiceSelected = payload?.item;
  },
  handleInvoiceParams: (state, { payload }) => {
    const field = payload?.field;

    state.paramsNotes[field] = payload?.value;
  },
  handleInvoiceModal: (state, { payload }) => {
    state.openInvoice.show = payload.show;
    state.openInvoice.code = payload.xprod;
    state.openInvoice.data = payload.row;
    return state;
  },
  handleSelectProd: (state, { payload }) => {
    state.selectedProduct.show = payload.show;
    state.selectedProduct.id = payload.id;
    state.selectedProduct.data = payload.row;
    return state;
  },
};

const escrituracaoSlice = createSlice({
  name: "escrituracaoFiscalList",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = escrituracaoSlice.reducer;
export const actions = escrituracaoSlice.actions;
export default reducer;
