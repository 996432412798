import { createSlice } from "@reduxjs/toolkit";

export const initialStateDash = {
  yearAllSelected: false,
  monthAllSelected: false,
  yearsSelects: [],
  monthsSelects: [],
  modal: {
    isShowModal: false,
  },
};

const initialState = () => {
  return initialStateDash;
};

export const diagnosticoDashboardSlice = createSlice({
  name: "diagnosticoDashboard",
  initialState,
  reducers: {
    handleSelectedAllSet(state, { payload }) {
      const fieldAllStateSelect = payload.fieldAllStateSelect;
      state[fieldAllStateSelect] = payload.value;
    },
    handleModal(state, { payload }) {
      state.modal.isShowModal = payload?.value;
      state.modal.type = payload?.type;
    },
    handleSelectYears(state, { payload }) {
      const fieldState = payload.stateField;
      const fieldAllStateSelect = payload.fieldAllStateSelect;

      const copy = state[fieldState];
      const year = payload?.value;
      const checkSelect = copy.filter(item => item === year);
      if (checkSelect?.length) {
        const remove = copy?.filter(item => item !== year);
        state[fieldState] = remove;
        state[fieldAllStateSelect] = false;
      } else {
        const copyOthers = copy.filter(item => item !== year);
        state[fieldAllStateSelect] = false;

        state[fieldState] = [payload.value, ...copyOthers];
      }

      return state;
    },
    handleSelectAll(state, { payload }) {
      const fieldState = payload.stateField;
      const fieldAllStateSelect = payload.fieldAllStateSelect;

      const copy = state[fieldState];

      const dataSend = payload?.value;

      const itemCopyList = copy.map(item => item);

      const checkAllSelects = dataSend?.filter(item =>
        itemCopyList.includes(item),
      );

      if (checkAllSelects?.length > 0) {
        state[fieldState] = [];
        state[fieldAllStateSelect] = false;
      } else {
        state[fieldAllStateSelect] = true;
        state[fieldState] = payload.value;
      }
    },
  },
});

export const {
  handleSelectYears,
  handleSelectedAllSet,
  handleModal,
  handleSelectAll,
} = diagnosticoDashboardSlice.actions;

export default diagnosticoDashboardSlice.reducer;
