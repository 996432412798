export const getJsonInStringAndValid = (message, params) => {
  const isValidMessage =
    message?.codigo_produto === params?.prod_cod &&
    message?.mxf_cas_id === params?.mxf_cas_id;

  return {
    message,
    isValidMessage,
  };
};
