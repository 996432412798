import React from "react";

import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";

export const DialogConfirm = ({
  isOpen,
  title = "Alerta",
  handleConfirm,
  handleCancel,
  text = "",
  content,
  setIsOpen,
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    toggle={handleCancel}
    className="modal-dialog-centered"
  >
    <ModalHeader toggle={() => setIsOpen(!isOpen)}>
      {content && !text ? content : <div>{text}</div>}
    </ModalHeader>
    <ModalBody>
      <Row>
        <Col md="12" className="text-center">
          <Button onClick={handleConfirm} color="danger" type="submit">
            Confirmar
          </Button>
          <Button className="mx-1" onClick={handleCancel} color="success">
            Cancelar
          </Button>
        </Col>
      </Row>
    </ModalBody>
  </Modal>
);
