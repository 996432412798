import { useCallback } from "react";
import { useMixApi1, useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useCasosListagem = (params = {}) =>
  useFetchMixAPi1(getURLWithParams(`/legislacao/casos-especiais`, params));

export const useCasosEspeciaisDetail = id =>
  useFetchMixAPi1(
    id ? `/legislacao/casos-especiais/associacao/detalhe/${id}` : null,
  );

export const useCategoriasComCaso = (id, params) =>
  useFetchMixAPi1(
    id
      ? getURLWithParams(`/legislacao/casos-especiais/categorias/${id}`, params)
      : null,
  );
export const useCasosEspeciaisDetailCategorias = id =>
  useFetchMixAPi1(
    id ? `/legislacao/casos-especiais/associacao/detalhe/${id}` : null,
  );

export const useCasosAsync = () => {
  const api = useMixApi1();

  const fetchGrupo = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/casos-grupo/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  const fetchOperacao = useCallback(
    async (params = { op_nome: "*" }) => {
      try {
        const response = await api.get(
          getURLWithParams("legislacao/operacoes/search", params),
        );
        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching operacoes:", error);
        return [];
      }
    },
    [api],
  );

  const fetchRegra = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams(
            "legislacao/regras-tributarias/associacoes/search",
            params,
          ),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );
  return {
    fetchGrupo,
    fetchRegra,
    fetchOperacao,
  };
};

export const useGrupoCasosNovo = () => {
  const { onSend } = useMixApi1Toast();

  const onCreate = async data => {
    const endpoint = `legislacao/grupo-casos/novo`;
    const type = "POST";

    onSend({
      endpoint,
      messageSuccess: `Grupo Cadastrado com Sucesso`,
      messageFail: `Não foi possível cadastrar o Grupo`,
      data,
      type,
    });
  };
  return {
    onCreate,
  };
};

export const useCasosEspeciais = (id = null) => {
  const { onSend } = useMixApi1Toast();

  const onCreate = async data => {
    const endpoint = id
      ? `legislacao/casos-especiais/associacao/editar/${id}`
      : `legislacao/casos-especiais/novo`;
    const type = id ? "PUT" : "POST";

    onSend({
      endpoint,
      messageSuccess: id
        ? `Casos Editado com Sucesso`
        : `Caso Cadastrado com Sucesso`,
      messageFail: id
        ? `Não foi possível editar o Caso`
        : `Não foi possível cadastrar o Caso`,
      data,
      type,
      redirect: id ? false : true,
      fieldGetId: "mxf_cas_id",
      urlRedirect: "/legislacao/casosespeciais/detail",
    });
  };

  const onRemove = async () => {
    const endpoint = `legislacao/casos-especiais/associacao/destroy-vinculo/${id}`;

    const type = "GET";

    onSend({
      endpoint,
      messageSuccess: `Regra desassociada com sucesso`,
      messageFail: `Não foi possível desassociar Regra`,
      type,
    });
  };
  return {
    onCreate,
    onRemove,
  };
};
