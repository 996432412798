import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  params: {
    page: 1,
    limit: 20,
    tipo: "pendente",
  },

  isShowModalCorrectionProduct: {
    show: false,
    prod_id: "",
    chave_note: "",
    data: {},
  },
  isShowModalInvoiceCorrection: {
    show: false,
    prod_id: "",
    chave_note: "",
    data: {},
  },
  othersParams: {},
  historicShow: false,
};

const reducers = {
  handleSearch: (state, { payload }) => {
    state.search = payload?.search;
  },
  handleHistoric: (state, { payload }) => {
    state.historicShow = payload?.show;
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
  handleClearParams: (state, { payload }) => {
    const params = payload?.params;

    state.params = {};
    Object.keys(params)
      .filter(field => params[field] !== undefined && !params[field] !== "")
      .map(field => {
        state.params[field] = params[field];
      });
    return state;
  },
  handleOthersParams: (state, { payload }) => {
    const field = payload?.field;
    state.othersParams[field] = payload?.value;
    return state;
  },
  handleParamsMultiples: (state, { payload }) => {
    const params = payload?.params;
    Object.keys(params)
      .filter(field => params[field] !== undefined && !params[field] !== "")
      .map(field => {
        state.params[field] = params[field];
      });
    return state;
  },
  handleOpenModalAssociation: (state, { payload }) => {
    state.isShowModalCorrectionProduct.show = payload.show;
    state.isShowModalCorrectionProduct.prod_id = payload.id;
    state.isShowModalCorrectionProduct.chave_note = payload.chave_note;
    state.isShowModalCorrectionProduct.data = payload.row;
  },
  handleAssociationModalInvoice: (state, { payload }) => {
    Object.keys(payload).map(field => {
      const value = payload[field];
      return (state.isShowModalInvoiceCorrection[field] = value);
    });
  },
};

const escrituracaoAssociationNotesPendentesSlice = createSlice({
  name: "escrituracaoAssociationNotesPendentes",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = escrituracaoAssociationNotesPendentesSlice.reducer;
export const actions = escrituracaoAssociationNotesPendentesSlice.actions;
export default reducer;
