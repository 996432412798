export const STATUS_NFE = {
  ACTIVE: "active", // ativo
  BLANK_DATA: "blank_data", // tudo em branco
  WARNING_NOTE_ENTRY_SYNC: "note-warning-entry-sync", // notas de entrada atencao
  WARNING_NOTE_SALE_SYNC: "note-warning-sale-sync", // aviso de notas de saida

  DANGER_NOTE_ENTRY_SYNC: "note-danger-entry-sync",
  DANGER_NOTE_SALE_SYNC: "note-danger-sale-sync", // aviso de notas de saida
};

const colors = {
  danger: "#ea5455",
  warning: "#ff9f43",
  success: "#28c76f",
};

export const STATUS_NFE_COLORS = {
  [STATUS_NFE.ACTIVE]: colors.success,
  [STATUS_NFE.DANGER_NOTE_ENTRY_SYNC]: colors.danger,
  [STATUS_NFE.DANGER_NOTE_SALE_SYNC]: colors.danger,
  [STATUS_NFE.WARNING_NOTE_ENTRY_SYNC]: colors.warning,
  [STATUS_NFE.WARNING_NOTE_SALE_SYNC]: colors.warning,
};

export const STATUS_NFE_DANGER_LIST = [
  STATUS_NFE.DANGER_NOTE_ENTRY_SYNC,
  STATUS_NFE.DANGER_NOTE_SALE_SYNC,
];

export const STATUS_NFE_WARNING_LIST = [
  STATUS_NFE.WARNING_NOTE_ENTRY_SYNC,
  STATUS_NFE.WARNING_NOTE_SALE_SYNC,
];

/**
 * Labels dos Certificados
 */
export const TYPES_STATUS_CERTIFICATE = {
  VENCIDO: "vencido",
  AVENCER: "avencer",
  VENCENDO_HOJE: "vencendo-hoje",
  ATIVO: "ativo",
  SEM_CERTIFICADO: "sem-certificado",
};

export const TYPES_STATUS_CERTIFICATE_DAYS_COLORS = {
  [TYPES_STATUS_CERTIFICATE.AVENCER]: "#F6B000",
  [TYPES_STATUS_CERTIFICATE.ATIVO]: "green",
  [TYPES_STATUS_CERTIFICATE.VENCENDO_HOJE]: "red",
  [TYPES_STATUS_CERTIFICATE.VENCIDO]: "red",
};

export const TYPES_STATUS_CERTIFICATE_LABELS = {
  [TYPES_STATUS_CERTIFICATE.VENCIDO]: "Vencido",
  [TYPES_STATUS_CERTIFICATE.ATIVO]: "Ativo",
  [TYPES_STATUS_CERTIFICATE.VENCENDO_HOJE]: "Vencendo Hoje",
  [TYPES_STATUS_CERTIFICATE.AVENCER]: "A vencer",
  [TYPES_STATUS_CERTIFICATE.SEM_CERTIFICADO]: "Sem Certificado",
};
