import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useToast } from "hooks";
import { useSearchParams } from "react-router-dom";
import { usePermissionStoresToken, useStoresSearch } from "useCases";
import { handleCompaniesSearchSubmit } from "redux/app";
import { useDispatch } from "react-redux";

const AppContext = createContext({});

const defaultValues = {
  appBusinessIntelligence: false,
  disableAppBusinessIntelligence: false,
};

export const AppProvider = ({ children }) => {
  const { addToast } = useToast({});
  const [appState, setAppState] = useState(defaultValues);

  const [isAutSearchComplete, setAutSearchComplete] = useState(false);
  const [searchParams] = useSearchParams();
  const [cnpj] = useState(searchParams.get("cnpj"));

  const { onSend } = usePermissionStoresToken();

  const [autSearchCompanies, setAutSearch] = useState({});

  const { isLoading, data: resData } = useStoresSearch(autSearchCompanies);

  const dispatch = useDispatch();

  const autoSelectStore = useCallback(
    (values = {}, item) => {
      dispatch(
        handleCompaniesSearchSubmit({
          companiesSearchSelecteds: item,
          ...values,
        }),
      );
    },
    [dispatch],
  );

  const setBusinessIntelligence = useCallback(value => {
    setAppState(state => ({ ...state, appBusinessIntelligence: value }));
  }, []);

  const setDisableBusinessIntelligence = useCallback(value => {
    setAppState(state => ({ ...state, disableAppBusinessIntelligence: value }));
  }, []);

  const handleCopy = (value, msg) => {
    if (!value) {
      return addToast({
        type: "error",
        align: "top",
        title: "Error ao copiar",
        description: `Não foi possível copiar esse item`,
      });
    }
    navigator.clipboard.writeText(value);
    return addToast({
      type: "success",
      align: "top",
      title: "Copiado com sucesso",
      description: `${msg || "Copiado"} com sucesso`,
    });
  };

  useEffect(() => {
    if (cnpj?.length > 0) {
      setAutSearch({
        search: cnpj,
        limit: 50,
      });
    }
  }, [cnpj]);

  useEffect(() => {
    if (
      !isLoading &&
      resData?.stores?.docs?.length > 0 &&
      !isAutSearchComplete
    ) {
      onSend(resData?.stores?.docs);
      autoSelectStore({}, resData?.stores?.docs);
      setAutSearchComplete(true);
    }
  }, [
    isLoading,
    resData,
    autoSelectStore,
    setAutSearchComplete,
    isAutSearchComplete,
    onSend,
  ]);

  return (
    <AppContext.Provider
      value={{
        app: appState,
        setBusinessIntelligence,
        setDisableBusinessIntelligence,
        handleCopy,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context)
    throw new Error(
      "AppContext precisa ser utilizado dentro de um AppProvider",
    );
  return context;
};
