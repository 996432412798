import { createSlice } from "@reduxjs/toolkit";
import { getSessionTokenRobot } from "utils";

const initialState = () => {
  const isAuth = getSessionTokenRobot();

  const hasAuth = isAuth && isAuth?.length > 0 ? true : false;

  return {
    isAuth: hasAuth,
    actualMachineId: "",
    oldMachineId: "",
    dados: {},
    jobs: [],
    form: [],
    token: [],
    user: {},
    formNewRobot: {
      status: "INACTIVE",
    },
    newConfigRobot: [],
    FileNewModelJson: {
      status: "INACTIVE",
    },
  };
};
const suporteRobotSlice = createSlice({
  name: "robot",
  initialState: initialState(),
  reducers: {
    handleSetAuth: (state, { payload }) => {
      state.isAuth = payload?.value;
    },
    handleSetActualMachineId: (state, { payload }) => {
      state.actualMachineId = payload?.value;
    },
    handleSetOldMachineId: (state, { payload }) => {
      state.oldMachineId = payload?.value;
    },
    handleSetOldMachineId: (state, { payload }) => {
      state.oldMachineId = payload?.value;
    },
    handleSetAllLit: (state, { payload }) => {
      state.dados = payload?.dados;
      state.jobs = payload?.jobs;
    },
    handleSendForm: (state, { payload }) => {
      state.form = payload;
    },
    handleActiveNewRobot: state => {
      state.formNewRobot.status = "ACTIVE";
    },
    handleInactiveNewRobot: state => {
      state.formNewRobot.status = "INACTIVE";
    },
    handleSubmitNewRobot: (state, { payload }) => {
      state.formNewRobot = payload;
      state.formNewRobot.status = "SUBMIT";
    },
    handleTokenForm: (state, { payload }) => {
      state.token = payload;
    },
    handleSetAuthUser: (state, { payload }) => {
      state.user = payload;
    },
    handleSubmitNewConfig: (state, { payload }) => {
      state.newConfigRobot = payload;
    },

    handleFileNewModelJson: (state, { payload }) => {
      state.FileNewModelJson = payload;
      state.FileNewModelJson.status = "SUBMIT";
    },
    handleFileNewModelJsonInactive: state => {
      state.FileNewModelJson.status = "INACTIVE";
    },
    handleFileNewModelJsonActive: state => {
      state.FileNewModelJson.status = "ACTIVE";
    },
  },
});

export const actions = suporteRobotSlice.actions;

export default suporteRobotSlice.reducer;
