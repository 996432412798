import { TextField } from "components/form";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "reactstrap";

export const Header = ({ handleChangeFilter, handlePageChange, value }) => {
  const { control, handleSubmit } = useForm();

  const onSend = fields => {
    handleChangeFilter("id", fields?.id);
    handlePageChange(1);
  };
  const onClear = () => {
    handleChangeFilter("id", "");
    handlePageChange(1);
  };
  return (
    <>
      <h3>Notas Vinculadas</h3>
      <div className="px-1 pb-1">
        <Form onSubmit={handleSubmit(onSend)}>
          <Row>
            <Col md="6">
              <Row>
                <Col md="8">
                  <TextField
                    label="Chave da nota"
                    name="id"
                    defaultValue={value}
                    control={control}
                  />
                </Col>

                <Col md="4">
                  <Button
                    className="mt-2 mx-1"
                    type="button"
                    color="danger"
                    onClick={() => onClear()}
                  >
                    Cancelar
                  </Button>
                  <Button className="mt-2" type="submit">
                    Filtrar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
