export const TEXT_ACTIONS = {
  kill: {
    title: "Confirmar restart",
    text: "Confirme o restart da aplicação instalado no cliente",
  },
  "clear-localdb": {
    title: "Confirmar limpeza",
    text: "Confirme a limpeza da aplicação instalada no cliente.",
  },
};

export const ENUMS_STATUS_PROCESS = {
  FINALIZADO_COM_ERRORS: "FINALIZADO_COM_ERRORS",
  NAO_FINALIZADO: "NAO_FINALIZADO",
  SEM_ERRORS: "SEM_ERRORS",
};

export const LABELS_STATUS_PROCESS = {
  [ENUMS_STATUS_PROCESS.FINALIZADO_COM_ERRORS]: "FINALIZADO COM ERROS",
  [ENUMS_STATUS_PROCESS.NAO_FINALIZADO]: "NÃO FINALIZADO",
  [ENUMS_STATUS_PROCESS.SEM_ERRORS]: "SEM ERROS",
};

export const COLORS_ENUMS_STATUS_PROCESS = {
  [ENUMS_STATUS_PROCESS.FINALIZADO_COM_ERRORS]: "danger",
  [ENUMS_STATUS_PROCESS.NAO_FINALIZADO]: "warning",
  [ENUMS_STATUS_PROCESS.SEM_ERRORS]: "success",
};
