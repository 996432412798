import { createSlice } from "@reduxjs/toolkit";

const inicitalState = () => {
  const initialStore = JSON.parse(
    window.localStorage.getItem("@mixfiscal:stores"),
  );

  const getUnicUfStore =
    initialStore?.length > 0 ? initialStore[0]?.state : "SP";

  const initialProducts = {
    data: {},
    show: false,
    isFilterEan: true,
    filter: {
      ufEntrada: getUnicUfStore,
      origem: "EI",
      ufSaida: getUnicUfStore,
      saida: "SAC",
    },
    filterEanDesc: {},
  };

  return initialProducts;
};
export const productEanGtinSlice = createSlice({
  name: "produtoEanGtin",
  initialState: inicitalState(),
  reducers: {
    handleOpenModalProduct: (state, { payload }) => {
      state.show = true;
      state.data = payload;

      if (payload.isFilterEan !== undefined && payload.isFilterEan !== null) {
        state.isFilterEan = payload.isFilterEan;
      }
    },
    handleCloseModalProduct: state => {
      state.show = false;
      state.data = {};
      state.filter.ufEntrada = "SP";
      state.filter.origem = "ED";
      state.filter.ufSaida = "SP";
      state.filter.saida = "SVC";
      state.isFilterEan = true;
    },
    handleChangeFilter: (state, { payload }) => {
      state.filter[payload?.field] = payload?.value;
    },
    handleChangeFilterEanDesc: (state, { payload }) => {
      state.filterEanDesc[payload?.field] = payload?.value;
    },
    handleChangeisFilterEan: (state, { payload }) => {
      state.isFilterEan = payload;
    },
  },
});

export const {
  handleOpenModalProduct,
  handleCloseModalProduct,
  handleChangeFilter,
  handleChangeisFilterEan,
  handleChangeFilterEanDesc,
} = productEanGtinSlice.actions;

export default productEanGtinSlice.reducer;
