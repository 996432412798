import styled, { css } from "styled-components";

import { ListGroupItem } from "reactstrap";

export const ListGroupItemCustom = styled(ListGroupItem)`
  cursor: pointer;
  &:hover {
    & > div > h5 {
      color: #fff !important;
    }
    color: #fff !important;
    background: #7367f0 !important;
  }
`;

export const control = (styles, { selectProps }) => {
  const extraStyles = {};

  if (selectProps.variant === "simple") {
    extraStyles.border = "none";
    extraStyles.borderBottom = "1px solid #596778";
    extraStyles.borderRadius = 0;
    extraStyles["&:hover"] = {
      borderColor: "#596778",
      boxShadow: "none",
    };
  }

  // extraStyles.border = "1px solid #596778";

  // if (isDisabled) {
  //   extraStyles.border = "1px solid #cacccf";
  //   extraStyles["& svg"] = {
  //     fill: "#cacccf",
  //   };
  // }

  return {
    ...styles,
    backgroundColor: "white",
    // height: 40,
    ...extraStyles,
  };
};
export const InputFieldContainer = styled.div`
  ${({ width }) => css`
    width: ${width || "100%"};
    position: relative;
  `}
`;

export const InputFieldHelperText = styled.label`
  ${({ error }) => css`
    display: ${error ? "block" : "none"};
    color: ${error ? "#f48fb1" : ""};
    font-size: 12px;
    margin-left: 8px;
  `}
`;

export const indicatorSeparator = styles => ({
  ...styles,
  width: 0,
});

export const valueContainer = styles => ({
  ...styles,
  // height: 39,
});

export const minWidth = styles => ({
  ...styles,
  minWidth: "100px",
});

export const borderTopRightRadius = styles => ({
  ...styles,
  borderTopRightRadius: "0px",
});

export const borderBottomRightRadius = styles => ({
  ...styles,
  borderBottomRightRadius: "0px",
});

export const placeholder = styles => ({
  ...styles,
  fontSize: 14,
});

export const singleValue = (styles, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  return {
    ...styles,
    fontSize: 14,
    opacity,
  };
};

export const menu = styles => ({
  ...styles,
  zIndex: 4,
});

export const Label = styled.label`
  ${({ isDisabled }) => css`
    font-size: 14px;
    margin-left: 8px;

    color: ${isDisabled && "#cacccf"};
  `}
`;

export const SelectLoading = styled.div`
  width: 100%;
  padding: 4px 7px;
  border: 1px solid rgb(89, 103, 120);
  border-radius: 3px;
  color: rgb(89, 103, 120);
`;

export const Select = styled.div`
  ${({ type, minWidth, borderTopRightRadius, borderBottomRightRadius }) => css`
    display: ${type === "top" && "flex"};
    flex-direction: ${type === "top" ? "column" : "row"};
    ${minWidth &&
    css`
      & .react-select .select__control {
        width: ${minWidth};
        border-top-right-radius: ${borderTopRightRadius};
        border-bottom-right-radius: ${borderBottomRightRadius};
      }
    `}
    ${Label} {
      width: 100%;
    }

    &[type="top"] > div {
      width: 100%;
    }
  `}
`;
