export const pageInfo = [
  {
    label: "Mensalidade",
    value: "M",
  },
  {
    label: "Implantação",
    value: "I",
  },
];

export const pageEm = [
  {
    label: "Pagamento",
    value: "P",
  },
  {
    label: "Faturado",
    value: "F",
  },
  {
    label: "Ativação",
    value: "A",
  },
];

export const descTribut = [
  {
    label: "Sim",
    value: "S",
  },
  {
    label: "Não",
    value: "N",
  },
];

export const tipoPgto = [
  {
    label: "%",
    value: "%",
  },
  {
    label: "$",
    value: "$",
  },
];

export const tipoAgente = [
  {
    label: "Vendedor",
    value: "VD",
  },
  {
    label: "Gerente Vendas",
    value: "GV",
  },
  {
    label: "Unidade",
    value: "U",
  },
  {
    label: "Pré-Vendedor",
    value: "PV",
  },
  {
    label: "ERP",
    value: "E",
  },
  {
    label: "Indicador",
    value: "I",
  },
];
