import { createSlice } from "@reduxjs/toolkit";

export const initialAlertness = {
  isShowModalForm: false,
  _id: "",
  items: [
    {
      title: "",
      description: "",
      icon: "",
    },
  ],
  params: {
    page: 1,
    limit: 50,
  },
  list: [],
  totalPages: 0,
};

const initialState = () => {
  return initialAlertness;
};

export const alertnessSlice = createSlice({
  name: "alertness",
  initialState,
  reducers: {
    handleSetNewItem: (state, { payload }) => {
      state.items = [...payload];
    },
    handleSetOpenModalForm: (state, { payload }) => {
      state.isShowModalForm = payload;
    },
    handleEditModalForm: (state, { payload }) => {
      state._id = payload;
    },
    handleRemoveItemList: (state, { payload }) => {
      const items = state.list
        ?.filter(item => item._id !== payload?.id)
        .map(item => item);

      state.list = [...items];
    },
    handleRemoveItem: (state, { payload }) => {
      const { key } = payload;
      const item = state.items
        .filter((item, index) => index !== key)
        .map(item => item);
      state.items = [...item];
    },
    handleSetItems: (state, { payload }) => {
      state.items = [...payload];
    },
    handleSetColluns: (state, { payload }) => {
      const list = [...state.items];
      list[payload.key][payload.field] = payload.value;
      state.items = list;
    },
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetListShow: (state, { payload }) => {
      console.log({ payload });
      const list = state.list || [];
      const igual = list
        ?.filter(item => item._id === payload?.id)
        .map(item => {
          return {
            ...item,
            isShow: payload?.show,
          };
        });

      const items = list
        ?.filter(item => item._id !== payload?.id && item?.isShow === true)
        .map(item => {
          return {
            ...item,
            isShow: !payload.show,
          };
        });
      const itemsFalse = list
        ?.filter(item => item._id !== payload?.id && !item?.isShow)
        .map(item => {
          return {
            ...item,
            isShow: false,
          };
        });

      state.list = [...igual, ...items, ...itemsFalse];
    },
    handleSetPageAndLimit: (state, { payload }) => {
      state.params.page = payload.page;
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
  },
});

export const {
  handleSetListShow,
  handleSetNewItem,
  handleSetItems,
  handleRemoveItem,
  handleSetOpenModalForm,
  handleEditModalForm,
  handleSetList,
  handleSetPageAndLimit,
  handleParamsLimit,
  handleRemoveItemList,
  handleSetColluns,
} = alertnessSlice.actions;

export default alertnessSlice.reducer;
