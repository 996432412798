import { createSlice } from "@reduxjs/toolkit";

const initialUpload = {
  isShow: false,
  files: [],
  filesSuccess: [],
  progressInfos: [],
  list: [],
};
const initialState = () => {
  return initialUpload;
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    handleIsShow: (state, { payload }) => {
      state.isShow = payload;
    },
    handleUploadFiles: (state, { payload }) => {
      state.files = payload?.files.map(item => {
        return {
          file: item,
          percentage: 0,
          success: "padrao",
        };
      });
    },
    handleRemoveFileState: (state, { payload }) => {
      const uploadedFiles = state.files;
      const filtered = uploadedFiles.filter(
        i => i?.file?.name !== payload.name,
      );
      state.files = [...filtered];
    },
    handleRemoveFileAll: state => {
      state.files = [];
    },
    handleSaveListSuccess: (state, { payload }) => {
      const list = state.files || [];

      const cloneOthers = state?.filesSuccess;

      const selected = list
        .filter(item => item?.file?.path === payload?.path)
        .map(item => {
          return {
            file: item?.file,
            percentage: payload?.percentage,
            success: payload?.success,
          };
        });
      const removeSuccess = list?.filter(
        item => item?.file?.path !== payload?.path,
      );

      state.filesSuccess = [...selected, ...cloneOthers];
      state.files = [...removeSuccess];
    },
    handleSaveFiles: (state, { payload }) => {
      const list = state.files || [];

      const clone = list
        .filter(item => item?.file?.path !== payload?.path)
        .map(item => item);

      const selected = list
        .filter(item => item?.file?.path === payload?.path)
        .map(item => {
          return {
            file: item?.file,
            percentage: payload?.percentage,
            success: payload?.success,
          };
        });

      state.files = [...clone, ...selected];
    },
    handleList: (state, { payload }) => {
      state.list = payload?.list;
    },
  },
});

export const {
  handleIsShow,
  handleSaveFiles,
  handleSaveFileshandleList,
  handleSetFileUploadSuccess,
  handleRemoveFileAll,
  handleSaveListSuccess,
  handleUploadFiles,
  handleRemoveFileState,
} = uploadSlice.actions;

export default uploadSlice.reducer;
