import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  list: [],
  params: {
    page: 1,
    limit: 10,
  },
};

const reducers = {
  handleParamsMultiples: (state, { payload }) => {
    const params = payload?.params;
    state.params = {};
    Object.keys(params)
      .filter(field => params[field] !== undefined && !params[field] !== "")
      .map(field => {
        state.params[field] = params[field];
      });
    return state;
  },
  handleParamsClear: state => {
    state.params.page = 1;
    state.params.limit = 10;
  },
  handleSetList: (state, { payload }) => {
    state.list = payload?.list;
  },
  handleRemove: (state, { payload }) => {
    const list = state?.list;

    const remove = (list || []).filter(item => item?.id !== payload?.id);

    if (!remove?.length) {
      state.params = {
        page: 1,
        limit: 0,
      };
    }

    state.list = remove;
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
};

const actionsNoteSlice = createSlice({
  name: "actionsNotes",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = actionsNoteSlice.reducer;
export const actions = actionsNoteSlice.actions;
export default reducer;
