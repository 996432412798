import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  params: {
    page: 1,
    limit: 20,
  },
  isShowModal: {
    show: false,
    id: "",
  },
};

const reducers = {
  handleOpenModal: (state, { payload }) => {
    state.isShowModal.show = payload?.show;
    state.isShowModal.id = payload?.id;
  },
  handleOpenModalMultiples: (state, { payload }) => {
    Object.keys(payload).map(field => {
      const value = payload[field];
      return (state.isShowModal[field] = value);
    });
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
  handleParamsMultiples: (state, { payload }) => {
    const params = payload?.params;

    Object.keys(payload?.params).map(field => {
      state.params[field] = params[field];
    });
    return state;
  },
};

const parameterConfiguratorSlice = createSlice({
  name: "parameterConfigurator",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = parameterConfiguratorSlice.reducer;
export const actions = parameterConfiguratorSlice.actions;
export default reducer;
