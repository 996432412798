import { useAPIAuthenticateToast, useToast } from "hooks";
import { validatePassword } from "utils/utils";

export const useAccountEdit = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();
  const { addToast } = useToast();

  const onSave = async (id, field) => {
    const validation = validatePassword(field?.password);
    const validationConfirm = validatePassword(field?.confirmPassword);

    if (!validation || !validationConfirm) {
      return addToast({
        title: "Usuário error senha",
        type: "error",
        description: `Precisa conter 8 caracteres, letras minúsculas,
                letras maiúsculas, números e caracteres especiais.`,
      });
    }

    if (field?.password !== field?.confirmPassword) {
      return addToast({
        title: "Usuário",
        type: "error",
        description: `Os campos de senha não coincidem`,
      });
    }
    await onSend({
      endpoint: `/user/${id}`,
      messageSuccess: "Usuário editado com sucesso!",
      messageFail: "Não foi possivel editar usuário",
      data: {
        ...field,
        password: field?.password,
      },
      type: "PUT",
    });
  };

  return {
    onSave,
    isLoading,
  };
};
