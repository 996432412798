import { createSlice } from "@reduxjs/toolkit";

export const initialDialog = {
  isShowModal: {
    show: false,
    type: "",
  },
  list: [],
};

const initialState = () => {
  return initialDialog;
};

export const storeStatusSlice = createSlice({
  name: "storeStatus",
  initialState,
  reducers: {
    handleToogleDialog: (state, { payload }) => {
      state.isShowModal.show = payload.show;
      state.isShowModal.type = payload.type;
    },
    handleSetList: (state, { payload }) => {
      state.list = payload;
    },
  },
});

export const { handleToogleDialog, handleSetList } = storeStatusSlice.actions;

export default storeStatusSlice.reducer;
