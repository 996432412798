import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const LOCAL_NAME = "app-mix-product";

const setProductData = (values = {}) =>
  window.localStorage.setItem(LOCAL_NAME, JSON.stringify(values));

const initialProducts = {
  page: 1,
  limit: 100,
  list: [],
  isShowFilter: false,
  filters: [
    {
      id: uuidv4(),
      columnField: {
        columnOrField: "and",
        columnField: "prod_nome",
        operatorValue: "equals",
        value: "",
      },
    },
  ],
  modal: {
    _id: null,
    show: false,
  },
};
const initialState = () => {
  const item = window.localStorage.getItem(LOCAL_NAME);
  const exist = item ? JSON.parse(item) : {};

  return {
    ...initialProducts,
    ...exist,
  };
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.page = payload.page;
      }
      if (payload.limit) {
        state.limit = payload.limit;
      }
    },
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
    },
    handleOnChangeFilter: (state, { payload }) => {
      const list = [...state.filters];
      list[payload?.key].columnField[payload?.field] = payload?.value;
      state.filters = list;
    },
    handleToogleFilters: (state, { payload }) => {
      state.isShowFilter = payload.isShowFilter;
    },
    handleAddFilters: (state, { payload }) => {
      state.filters = payload;
    },
    handleRemoveFilterUnic: (state, action) => {
      const { id } = action.payload;
      state.filters = state.filters.filter(item => item.id !== id);
    },
    handleRemoveFilterAll: state => {
      state.filters = [
        {
          id: uuidv4(),
          columnField: {
            columnOrField: "and",
            columnField: "prod_nome",
            operatorValue: "equals",
            value: "",
          },
        },
      ];
    },

    handleOpenModalProduct: (state, action) => {
      const { _id: id } = action.payload;
      state.modal.show = true;
      state.modal._id = id;
      setProductData(state);
    },

    handleCloseModalProduct: state => {
      state.modal = initialProducts.modal;
    },
  },
});

export const {
  handleSetList,
  handleToogleFilters,
  handleOnChangeFilter,
  handleAddFilters,
  handleRemoveFilterUnic,
  handleRemoveFilterAll,
  handleSetPageAndLimit,
  handleCloseModalProduct,
  handleOpenModalProduct,
} = productSlice.actions;

export default productSlice.reducer;
