import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleChangeFilterTax } from "redux/taxAnalises";
import { useAnalyticsDiagnosticoTempoReal } from "useCases/dashboard";
import { DIAGNOSTICO_STATUS_TYPE } from "constants/Types/Diagnostico.types";

import { CardListing } from "./CardListing";

export const Risco = () => {
  const {
    tipo,
    issue_date_start: start,
    issue_date_end: end,
    taxType,
  } = useSelector(state => state.taxAnalises);

  const dispatch = useDispatch();

  const { data } = useAnalyticsDiagnosticoTempoReal({
    issue_date_start: start,
    issue_date_end: end,
    tipo: "XML",
  });

  const values = useMemo(() => {
    const [icms, piscofins] = [
      data?.data?.filter(value => value.tipo === "icms"),
      data?.data?.filter(value => value.tipo === "piscofins"),
    ];
    const riscoAssumidoIcmsTotal = Number(
      icms?.find(
        row => row.tipo_value === DIAGNOSTICO_STATUS_TYPE.RISCO_ASSUMIDO,
      )?.total ?? 0,
    );

    const riscoAssumidoPisCofinsTotal = Number(
      piscofins?.find(
        row => row.tipo_value === DIAGNOSTICO_STATUS_TYPE.RISCO_ASSUMIDO,
      )?.total ?? 0,
    );

    const riscoAssumidoIcmsMultaMoratoriaTotal = 0.2 * riscoAssumidoIcmsTotal;
    const riscoAssumidoIcmsMultaPunitivaTotal = 0.75 * riscoAssumidoIcmsTotal;

    const riscoAssumidoPisCofinsMoratoriaTotal =
      0.2 * riscoAssumidoPisCofinsTotal;
    const riscoAssumidoPisCofinsPunitivaTotal =
      0.75 * riscoAssumidoPisCofinsTotal;

    const riscoAssumidoIcmsMultaTotal =
      riscoAssumidoIcmsMultaPunitivaTotal +
      riscoAssumidoIcmsMultaMoratoriaTotal;

    const riscoAssumidoPisCofinsMultaTotal =
      riscoAssumidoPisCofinsMoratoriaTotal +
      riscoAssumidoPisCofinsPunitivaTotal;

    const riscoAssumidoMoratoriaMultaTotal =
      riscoAssumidoPisCofinsMoratoriaTotal +
      riscoAssumidoIcmsMultaMoratoriaTotal;

    const riscoAssumidoPunitivaMultaTotal =
      riscoAssumidoIcmsMultaPunitivaTotal + riscoAssumidoPisCofinsPunitivaTotal;

    const total = riscoAssumidoIcmsTotal + riscoAssumidoPisCofinsTotal / 2;

    const riscoAssumidoIcmsTotalPercent = parseInt(
      (riscoAssumidoIcmsTotal / total) * 100,
      10,
    );
    const riscoAssumidoIcmsMultaPercent = parseInt(
      (riscoAssumidoIcmsMultaTotal / total) * 100,
      10,
    );

    const riscoAssumidoPisCofinsTotalPercent = parseInt(
      (riscoAssumidoPisCofinsTotal / total) * 100,
      10,
    );

    const riscoAssumidoPisCofinsMultaTotalPercent = parseInt(
      (riscoAssumidoPisCofinsMultaTotal / total) * 100,
      10,
    );

    let riscoAssumidoTotal =
      riscoAssumidoIcmsTotal +
      riscoAssumidoPisCofinsTotal +
      riscoAssumidoPunitivaMultaTotal +
      riscoAssumidoMoratoriaMultaTotal;

    if (taxType === "icms") {
      riscoAssumidoTotal = riscoAssumidoIcmsTotal + riscoAssumidoIcmsMultaTotal;
    }

    if (taxType === "piscofins") {
      riscoAssumidoTotal =
        riscoAssumidoPisCofinsTotal + riscoAssumidoPisCofinsMultaTotal;
    }

    return {
      riscoAssumidoIcms: riscoAssumidoIcmsTotal,
      riscoAssumidoIcmsTotal:
        riscoAssumidoIcmsTotal + riscoAssumidoIcmsMultaTotal,
      riscoAssumidoPisCofins: riscoAssumidoPisCofinsTotal,
      riscoAssumidoPisCofinsTotal:
        riscoAssumidoPisCofinsTotal + riscoAssumidoPisCofinsMultaTotal,
      riscoAssumidoTotal,
      riscoAssumidoIcmsMultaTotal,
      riscoAssumidoPisCofinsMultaTotal,
      riscoAssumidoMoratoriaMultaTotal,
      riscoAssumidoPunitivaMultaTotal,
      riscoAssumidoIcmsTotalPercent,
      riscoAssumidoIcmsMultaPercent,
      riscoAssumidoPisCofinsTotalPercent,
      riscoAssumidoPisCofinsMultaTotalPercent,
    };
  }, [data?.data, taxType]);

  const risco = [
    {
      title: "ICMS",
      total: values?.riscoAssumidoIcms,
      color: "light-primary",
      type: "icms",
    },
    {
      title: "PIS/COFINS",
      total: values?.riscoAssumidoPisCofins,
      color: "light-info",
      type: "piscofins",
    },
    {
      title: "Multa ICMS",
      total: values?.riscoAssumidoIcmsMultaTotal,
      color: "light-warning",
      type: "icms",
    },
    {
      title: "Multa PIS/COFINS",
      total: values?.riscoAssumidoPisCofinsMultaTotal,
      color: "light-warning",
      type: "piscofins",
    },
    {
      title: "Valor total",
      total: values?.riscoAssumidoTotal,
      color: "light-success",
      type: "all",
    },
  ];

  const handleChangeTax = ({ type }) =>
    dispatch(handleChangeFilterTax({ taxType: type }));

  return (
    <div className="d-flex align-items-center">
      <div style={{ margin: "0 auto" }}>
        <CardListing
          type={tipo}
          items={risco}
          typeActive={taxType}
          handleClick={handleChangeTax}
        />
      </div>
    </div>
  );
};
