import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  setSessionSolicitationIntegrationBlueSoft,
  getSessionSolicitationIntegrationBlueSoft,
} from "utils";

const statusSolicitation = getSessionSolicitationIntegrationBlueSoft();

const INITIAL_STATE = {
  title: "Geral",
  solicitationModal: false,
  selectedCount: 0,
  selectedRows: [],
  typeUpdateErp: "",
  isSendModalOpen: false,
  integrationProgress: statusSolicitation,
  openDetalhe: {
    id: "",
    cnpj: "",
    show: false,
  },
  logs: {
    descricao: "",
    produtokey: "",
    show: false,
    items: [],
  },
  departamento: {
    isLoading: true,
    data: [],
  },
  secao: {
    isLoading: false,
    data: [],
  },
  grupo: {
    isLoading: false,
    data: [],
  },
  subgrupo: {
    isLoading: false,
    data: [],
  },
  params: {
    page: 1,
    limit: 50,
  },
  filter: {},
};

const reducers = {
  handleOpenLogs: (state, { payload }) => {
    state.logs.descricao = payload?.descricao;
    state.logs.produtokey = payload?.produtokey;
    state.logs.show = payload?.show;
    state.logs.items = payload?.logs;
  },
  handleOpenDetalhe: (state, { payload }) => {
    state.openDetalhe.id = payload?.id;
    state.openDetalhe.cnpj = payload?.cnpj;
    state.openDetalhe.show = payload?.show;
  },
  handleListGroup: (state, { payload }) => {
    const field = payload?.field;
    state[field].data = payload?.data;
    state[field].payload = payload?.payload;
    return state;
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
  handleChange: (state, action) => {
    state = { ...state, ...action.payload };
    return state;
  },
  handleChangeTitle: (state, action) => {
    state.title = action?.payload;
    return state;
  },
  handleChangeOpenSolicitationModal: (state, { payload }) => {
    state.solicitationModal = !payload?.open;
  },
  handleChangeSelecteds: (state, { payload }) => {
    const selected = payload?.row;
    state.selectedCount = selected?.length;
    state.selectedRows = selected;
    return state;
  },
  handleChangeSelectedRemoveProduct: (state, { payload }) => {
    const list = state.selectedRows;
    const itemSelected = payload?.row;
    const remove = (list || [])
      .filter(item => item?._id !== itemSelected?._id)
      .map(item => item);

    toast.success("O produto foi removido da lista de selecionados!!");

    state.selectedCount = remove?.length;

    state.selectedRows = [...remove];
    return state;
  },
  handleChangeSelected: (state, { payload }) => {
    const list = state.selectedRows;
    const itemSelected = payload?.selected;

    const copy = [...list];

    const check = (list || []).map(item => item?._id);
    const remove = (list || []).filter(item => item?._id !== itemSelected?._id);

    if (check?.includes(itemSelected?._id)) {
      toast.success("O produto foi removido da lista de selecionados!!");
      state.selectedCount = remove?.length;

      state.selectedRows = remove?.length > 0 ? [...remove] : [];
      return state;
    }

    const countAll = [...copy, itemSelected];

    state.selectedCount = countAll?.length;

    state.selectedRows = countAll;
    return state;
  },
  handleChangeParamsFilterClear: state => {
    state.filter = {};
  },
  handleChangeParamsFilter: (state, action) => {
    const fields = action?.payload?.fields;

    if (!Object.keys(fields)) {
      state.filter = {};
    }
    Object.keys(fields)
      .filter(key => fields[key] !== undefined && fields[key] !== "")
      .forEach(key => {
        if (key === "divers_fields") {
          state.filter[key] = fields[key]?.map(item => item?.value);

          return;
        }

        state.filter[key] = fields[key];
      });
    return state;
  },

  handleUpdateInErp: (state, action) => {
    const value = action?.payload?.type;

    state.typeUpdateErp = value;

    return state;
  },
  hanldeIsModalSend: (state, action) => {
    state.isSendModalOpen = action?.payload?.field;
    return state;
  },
  handleSendSolicitation: () => {
    toast.success("Solicitação de correção enviada com sucesso!");
  },

  handleChangeStatusSolicitation: (state, action) => {
    const payload = action?.payload;

    setSessionSolicitationIntegrationBlueSoft(payload);
    state.integrationProgress = payload;

    return state;
  },
};

const integrationsSlice = createSlice({
  name: "integrations",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = integrationsSlice.reducer;
export const actions = integrationsSlice.actions;
export default reducer;
