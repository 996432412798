import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { handleChangeFilterTax } from "redux/taxAnalises";

import { useAnalyticsDiagnosticoTempoReal } from "useCases/dashboard";
import { DIAGNOSTICO_STATUS_TYPE } from "constants/Types/Diagnostico.types";

import { CardListing } from "./CardListing";

export const Queima = () => {
  const {
    tipo,
    issue_date_start: start,
    issue_date_end: end,
    taxType,
  } = useSelector(state => state.taxAnalises);

  const dispatch = useDispatch();

  const { data } = useAnalyticsDiagnosticoTempoReal({
    issue_date_start: start,
    issue_date_end: end,
    tipo: "XML",
  });

  const values = useMemo(() => {
    const [icms, piscofins] = [
      data?.data?.filter(value => value.tipo === "icms"),
      data?.data?.filter(value => value.tipo === "piscofins"),
    ];

    const icmsQueimaCaixaTotal = Number(
      icms?.find(row => row.tipo_value === DIAGNOSTICO_STATUS_TYPE.QUEIMA_CAIXA)
        ?.total ?? 0,
    );

    const pisCofinsQueimaCaixaTotal = Number(
      piscofins?.find(
        row => row.tipo_value === DIAGNOSTICO_STATUS_TYPE.QUEIMA_CAIXA,
      )?.total ?? 0,
    );

    return {
      icmsQueimaCaixaTotal,
      pisCofinsQueimaCaixaTotal,
      queimaCaixaTotal: icmsQueimaCaixaTotal + pisCofinsQueimaCaixaTotal,
    };
  }, [data?.data]);

  const queima = [
    {
      title: "ICMS",
      total: values?.icmsQueimaCaixaTotal,
      color: "light-primary",
      type: "icms",
    },
    {
      title: "PIS/COFINS TOTAL",
      total: values?.pisCofinsQueimaCaixaTotal,
      color: "light-info",
      type: "piscofins",
    },
    {
      title: "VALOR TOTAL",
      total: values?.queimaCaixaTotal,
      color: "light-warning",
      type: "all",
    },
  ];

  const handleChangeTax = ({ type }) =>
    dispatch(handleChangeFilterTax({ taxType: type }));

  return (
    <div className="d-flex align-items-center">
      <div style={{ margin: "0 auto" }}>
        <CardListing
          type={tipo}
          typeActive={taxType}
          items={queima}
          handleClick={handleChangeTax}
        />
      </div>
    </div>
  );
};
