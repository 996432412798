export const DIAGNOSTICO_STATUS_TYPE = {
  QUEIMA_CAIXA: "QUEIMA_CAIXA",
  RISCO_ASSUMIDO: "RISCO_ASSUMIDO",
  CORRETO: "CORRETO",
  A_OTIMIZAR: "A_OTIMIZAR",
  CORRETO_CONVERSAO_REGIME: "CORRETO_CONVERSAO_REGIME",
  OPORTUNIDADE: "OPORTUNIDADE",
  CREDITO_INDEVIDO: "CREDITO_INDEVIDO",
  MUDAR_FORNECEDOR_SIMPLES: "MUDAR_FORNECEDOR_SIMPLES",
  CORRETO_S_TRIB_MIX: "CORRETO_S_TRIB_MIX",
  CORRETO_S_TAG: "CORRETO_S_TAG",
};

export const DIAGNOSTICO_GOOD_FOR_SALE_TYPE = {
  DEBITO_ESTORNAR: "DEBITO_ESTORNAR",
  NAO_RECONHECIDOS: "NAO_RECONHECIDOS",
  CREDITO_A_ESTORNAR: "CREDITO_A_ESTORNAR",
};

export const DIAGNOSTICO_SPED_CREDITS_TABS = {
  CREDITOS: "CREDITOS",
  DOWNLOADS: "DOWNLOADS",
  COMPLEMENTO_CREDITOS: "COMPLEMENTO_CREDITOS",
  CONFIGS: "CONFIGS",
  CLASSIFICACAO: "CLASSIFICACAO",
};

export const DIAGNISTICO_STATUS_TYPES_ANALYTICS = [
  DIAGNOSTICO_STATUS_TYPE.A_OTIMIZAR,
  DIAGNOSTICO_STATUS_TYPE.RISCO_ASSUMIDO,
  DIAGNOSTICO_STATUS_TYPE.QUEIMA_CAIXA,
  DIAGNOSTICO_STATUS_TYPE.OPORTUNIDADE,
  DIAGNOSTICO_STATUS_TYPE.CREDITO_INDEVIDO,
];

export const DIAGNISTICO_STATUS_TYPES_SEM_ANALYTICS = [
  DIAGNOSTICO_STATUS_TYPE.CORRETO,
  DIAGNOSTICO_STATUS_TYPE.CORRETO_CONVERSAO_REGIME,
  DIAGNOSTICO_STATUS_TYPE.MUDAR_FORNECEDOR_SIMPLES,
  DIAGNOSTICO_STATUS_TYPE.CORRETO_S_TRIB_MIX,
  DIAGNOSTICO_STATUS_TYPE.CORRETO_S_TAG,
];

export const DIAGNOSTICO_STATUS_TYPE_FORCA_CONVERSAO = [
  DIAGNOSTICO_STATUS_TYPE.CORRETO_CONVERSAO_REGIME,
];
