import React from "react";
import { useSelector } from "react-redux";
import { Card, Row, CardBody } from "reactstrap";

import { Queima } from "./Queima";
import { Risco } from "./Risco";

export const Totals = () => {
  const { title, tipo } = useSelector(state => state.taxAnalises);

  return (
    <Card className="p-1">
      <h3>{title}</h3>
      <CardBody>
        <Row>{tipo === "queima" ? <Queima /> : <Risco />}</Row>
      </CardBody>
    </Card>
  );
};
