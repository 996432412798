export const AUTH_SESSION_GROUP_ACESSS = "@mixfiscal:groupAccess";
export const AUTH_SESSION_GROUPS_ACESSS = "@mixfiscal:groupsAccess";

export const getGroupAll = () =>
  JSON.parse(localStorage.getItem(AUTH_SESSION_GROUP_ACESSS));

export const setSessionGroupAcess = body => {
  localStorage.setItem(AUTH_SESSION_GROUP_ACESSS, JSON.stringify(body));
};
export const getGroupsAccessAll = () =>
  JSON.parse(localStorage.getItem(AUTH_SESSION_GROUPS_ACESSS));

export const setSessionGroupsAcess = body => {
  localStorage.setItem(AUTH_SESSION_GROUPS_ACESSS, JSON.stringify(body));
};
