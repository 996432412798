export const comparatorFields = (obj1, obj2) => {
  const differences = {};

  const compareInternal = (obj1, obj2, path = "") => {
    for (const key in obj1) {
      const fullPath = path ? `${path}.${key}` : key;

      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        compareInternal(obj1[key], obj2[key], fullPath);
      } else {
        if (obj1[key] !== obj2[key]) {
          differences[fullPath] = {
            oldValue: obj1[key],
            newValue: obj2[key],
          };
        }
      }
    }
  };

  compareInternal(obj1, obj2);

  return differences;
};

export const transformString = cst => {
  const cstInString = cst.toString();
  return cstInString === "0" ? cstInString.padEnd(2, 0) : cst.toString();
};

export const treatPisCofins = (
  piscofinscumulativosaidaOld,
  pisCofinsSaidaCumulativoNew,
) => {
  return {
    div_cst:
      piscofinscumulativosaidaOld?.cst !== pisCofinsSaidaCumulativoNew?.cst,
    div_alq_pis:
      piscofinscumulativosaidaOld?.aliq !== pisCofinsSaidaCumulativoNew?.aliq,
    div_alq_cofins:
      piscofinscumulativosaidaOld?.aliq !== pisCofinsSaidaCumulativoNew?.aliq,
    cst_old: piscofinscumulativosaidaOld?.cst,
    cst_new: pisCofinsSaidaCumulativoNew?.cst,
    pisAliquotaOld: piscofinscumulativosaidaOld?.aliq,
    pisAliquotaNew: pisCofinsSaidaCumulativoNew?.aliq,
    cofinsAliquotaOld: piscofinscumulativosaidaOld?.aliq,
    cofinsAliquotaNew: pisCofinsSaidaCumulativoNew?.aliq,
  };
};
export const comparatorFieldTributationEstadual = (
  tributacaoestadualold,
  tributacaoestadualnew,
) => {
  return (tributacaoestadualold || []).map(item => {
    const uf = item?.uf;
    const tributationNew = (tributacaoestadualnew || []).find(
      itemNew => (itemNew.uf = uf),
    );

    let icmsDeEntrada = {};
    let icmsDeSaidaClienteFinal = {};
    let icmsDeEntradaEntreLojas = {};
    let icmsDeSaidaClienteRevenda = {};
    let icmsDeSaidaClienteFinalSimplesNacional = {};
    let icmsDeSaidaClienteRevendaSimplesNacional = {};
    let icmsDeSaidaClienteTransferencia = {};
    let icmsDeSaidaClienteTransferenciaSimplesNacional = {};

    if (item?.icmsDeEntrada?.cst >= 0) {
      icmsDeEntrada = {
        ...item?.icmsDeEntrada,
        aliquota_mix: tributationNew?.icmsDeEntrada?.aliquota,
        aliquota_div:
          item?.icmsDeEntrada?.aliquota !==
          tributationNew?.icmsDeEntrada?.aliquota,
        cst_div:
          transformString(item?.icmsDeEntrada?.cst) !==
          transformString(tributationNew?.icmsDeEntrada?.cst),
        cst_mix: tributationNew?.icmsDeEntrada?.cst,
        origem_div:
          item?.icmsDeEntrada?.origem !== tributationNew?.icmsDeEntrada?.origem,
        origem_mix: tributationNew?.icmsDeEntrada?.origem,
        reducao_div:
          item?.icmsDeEntrada?.reducao !==
          tributationNew?.icmsDeEntrada?.reducao,
        reducao_mix: tributationNew?.icmsDeEntrada?.reducao,
      };
    }

    if (item?.icmsDeSaidaClienteTransferencia?.cst >= 0) {
      icmsDeSaidaClienteTransferencia = {
        ...item?.icmsDeSaidaClienteTransferencia,
        aliquota_mix: tributationNew?.icmsDeSaidaClienteTransferencia?.aliquota,
        aliquota_div:
          item?.icmsDeSaidaClienteTransferencia?.aliquota !==
          tributationNew?.icmsDeSaidaClienteTransferencia?.aliquota,
        cst_div:
          transformString(item?.icmsDeSaidaClienteTransferencia?.cst) !==
          transformString(tributationNew?.icmsDeSaidaClienteTransferencia?.cst),
        cst_mix: tributationNew?.icmsDeSaidaClienteTransferencia?.cst,
        origem_div:
          item?.icmsDeSaidaClienteTransferencia?.origem !==
          tributationNew?.icmsDeSaidaClienteTransferencia?.origem,
        origem_mix: tributationNew?.icmsDeSaidaClienteTransferencia?.origem,
        reducao_div:
          item?.icmsDeSaidaClienteTransferencia?.reducao !==
          tributationNew?.icmsDeSaidaClienteTransferencia?.reducao,
        reducao_mix: tributationNew?.icmsDeSaidaClienteTransferencia?.reducao,
      };
    }

    if (item?.icmsDeSaidaClienteTransferenciaSimplesNacional?.cst >= 0) {
      icmsDeSaidaClienteTransferenciaSimplesNacional = {
        ...item?.icmsDeSaidaClienteTransferenciaSimplesNacional,
        aliquota_mix:
          tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional
            ?.aliquota,
        aliquota_div:
          item?.icmsDeSaidaClienteTransferenciaSimplesNacional?.aliquota !==
          tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional
            ?.aliquota,
        cst_div:
          transformString(
            item?.icmsDeSaidaClienteTransferenciaSimplesNacional?.cst,
          ) !==
          transformString(
            tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional?.cst,
          ),
        cst_mix:
          tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional?.cst,
        origem_div:
          item?.icmsDeSaidaClienteTransferenciaSimplesNacional?.origem !==
          tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional
            ?.origem,
        origem_mix:
          tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional
            ?.origem,
        reducao_div:
          item?.icmsDeSaidaClienteTransferenciaSimplesNacional?.reducao !==
          tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional
            ?.reducao,
        reducao_mix:
          tributationNew?.icmsDeSaidaClienteTransferenciaSimplesNacional
            ?.reducao,
      };
    }

    if (item?.icmsDeSaidaClienteRevendaSimplesNacional?.cst >= 0) {
      icmsDeSaidaClienteRevendaSimplesNacional = {
        ...item?.icmsDeSaidaClienteRevendaSimplesNacional,
        aliquota_mix:
          tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.aliquota,
        aliquota_div:
          item?.icmsDeSaidaClienteRevendaSimplesNacional?.aliquota !==
          tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.aliquota,
        cst_div:
          transformString(
            item?.icmsDeSaidaClienteRevendaSimplesNacional?.cst,
          ) !==
          transformString(
            tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.cst,
          ),
        cst_mix: tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.cst,
        origem_div:
          item?.icmsDeSaidaClienteRevendaSimplesNacional?.origem !==
          tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.origem,
        origem_mix:
          tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.origem,
        reducao_div:
          item?.icmsDeSaidaClienteRevendaSimplesNacional?.reducao !==
          tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.reducao,
        reducao_mix:
          tributationNew?.icmsDeSaidaClienteRevendaSimplesNacional?.reducao,
      };
    }

    if (item?.icmsDeSaidaClienteFinalSimplesNacional?.cst >= 0) {
      icmsDeSaidaClienteFinalSimplesNacional = {
        ...item?.icmsDeSaidaClienteFinalSimplesNacional,
        aliquota_mix:
          tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.aliquota,
        aliquota_div:
          item?.icmsDeSaidaClienteFinalSimplesNacional?.aliquota !==
          tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.aliquota,
        cst_div:
          transformString(item?.icmsDeSaidaClienteFinalSimplesNacional?.cst) !==
          transformString(
            tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.cst,
          ),
        cst_mix: tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.cst,
        origem_div:
          item?.icmsDeSaidaClienteFinalSimplesNacional?.origem !==
          tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.origem,
        origem_mix:
          tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.origem,
        reducao_div:
          item?.icmsDeSaidaClienteFinalSimplesNacional?.reducao !==
          tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.reducao,
        reducao_mix:
          tributationNew?.icmsDeSaidaClienteFinalSimplesNacional?.reducao,
      };
    }

    if (item?.icmsDeSaidaClienteRevenda?.cst >= 0) {
      icmsDeSaidaClienteRevenda = {
        ...item?.icmsDeSaidaClienteRevenda,
        aliquota_mix: tributationNew?.icmsDeSaidaClienteRevenda?.aliquota,
        aliquota_div:
          item?.icmsDeSaidaClienteRevenda?.aliquota !==
          tributationNew?.icmsDeSaidaClienteRevenda?.aliquota,
        cst_div:
          transformString(item?.icmsDeSaidaClienteRevenda?.cst) !==
          transformString(tributationNew?.icmsDeSaidaClienteRevenda?.cst),
        cst_mix: tributationNew?.icmsDeSaidaClienteRevenda?.cst,
        origem_div:
          item?.icmsDeSaidaClienteRevenda?.origem !==
          tributationNew?.icmsDeSaidaClienteRevenda?.origem,
        origem_mix: tributationNew?.icmsDeSaidaClienteRevenda?.origem,
        reducao_div:
          item?.icmsDeSaidaClienteRevenda?.reducao !==
          tributationNew?.icmsDeSaidaClienteRevenda?.reducao,
        reducao_mix: tributationNew?.icmsDeSaidaClienteRevenda?.reducao,
      };
    }

    if (item?.icmsDeEntradaEntreLojas?.cst >= 0) {
      icmsDeEntradaEntreLojas = {
        ...item?.icmsDeEntradaEntreLojas,
        aliquota_mix: tributationNew?.icmsDeEntradaEntreLojas?.aliquota,
        aliquota_div:
          item?.icmsDeEntradaEntreLojas?.aliquota !==
          tributationNew?.icmsDeEntradaEntreLojas?.aliquota,
        cst_div:
          transformString(item?.icmsDeEntradaEntreLojas?.cst) !==
          transformString(tributationNew?.icmsDeEntradaEntreLojas?.cst),
        cst_mix: tributationNew?.icmsDeEntradaEntreLojas?.cst,
        origem_div:
          item?.icmsDeEntradaEntreLojas?.origem !==
          tributationNew?.icmsDeEntradaEntreLojas?.origem,
        origem_mix: tributationNew?.icmsDeEntradaEntreLojas?.origem,
        reducao_div:
          item?.icmsDeEntradaEntreLojas?.reducao !==
          tributationNew?.icmsDeEntradaEntreLojas?.reducao,
        reducao_mix: tributationNew?.icmsDeEntradaEntreLojas?.reducao,
      };
    }

    if (item?.icmsDeSaidaClienteFinal?.cst >= 0) {
      icmsDeSaidaClienteFinal = {
        ...item?.icmsDeSaidaClienteFinal,
        aliquota_mix: tributationNew?.icmsDeSaidaClienteFinal?.aliquota,
        aliquota_div:
          item?.icmsDeSaidaClienteFinal?.aliquotaInterna !==
          tributationNew?.icmsDeSaidaClienteFinal?.aliquotaInterna,
        cst_div:
          transformString(item?.icmsDeSaidaClienteFinal?.cst) !==
          transformString(tributationNew?.icmsDeSaidaClienteFinal?.cst),
        cst_mix: tributationNew?.icmsDeSaidaClienteFinal?.cst,
        origem_div:
          item?.icmsDeSaidaClienteFinal?.origem !==
          tributationNew?.icmsDeSaidaClienteFinal?.origem,
        origem_mix: tributationNew?.icmsDeSaidaClienteFinal?.origem,
        reducao_div:
          item?.icmsDeSaidaClienteFinal?.reducao !==
          tributationNew?.icmsDeSaidaClienteFinal?.reducao,
        reducao_mix: tributationNew?.icmsDeSaidaClienteFinal?.reducao,
      };
    }

    return {
      ...item,
      icmsDeEntrada,
      icmsDeSaidaClienteFinal,
      icmsDeEntradaEntreLojas,
      icmsDeSaidaClienteRevenda,
      icmsDeSaidaClienteFinalSimplesNacional,
      icmsDeSaidaClienteRevendaSimplesNacional,
      icmsDeSaidaClienteTransferencia,
      icmsDeSaidaClienteTransferenciaSimplesNacional,
      aliquotaInterna_div:
        item?.aliquotaInterna !== tributationNew?.aliquotaInterna,
      aliquotaInterna_mix: tributationNew?.aliquotaInterna,
      baseIcmr_div: item?.baseIcmr_mix !== tributationNew?.baseIcmr_mix,
      baseIcmr_mix: tributationNew?.baseIcmr,
      cargaMediaFcp_div: item?.cargaMediaFcp !== tributationNew?.cargaMediaFcp,
      cargaMediaFcp_mix: tributationNew?.cargaMediaFcp,
      fcpSt_div: item?.fcpSt !== tributationNew?.fcpSt,
      fcpSt_mix: tributationNew?.fcpSt,
      itemBeneficiado_mix: tributationNew?.itemBeneficiado,
      itemComReducaoNaBaseSt_mix: tributationNew?.itemComReducaoNaBaseSt,
      ivaSt_mix: tributationNew?.ivaSt,
      ivaSt_div: item?.ivaSt !== tributationNew?.ivaSt,
      itemBeneficiado_mix: tributationNew?.itemBeneficiado,
      ivaStAjustado_div: item?.ivaStAjustado !== tributationNew?.ivaStAjustado,
      ivaStAjustado_mix: tributationNew?.ivaStAjustado,
      ivaStAjustado4_div:
        item?.ivaStAjustado4 !== tributationNew?.ivaStAjustado4,
      ivaStAjustado4_mix: tributationNew?.ivaStAjustado4,
      pautaFiscal_mix: tributationNew?.pautaFiscal,
      pautaFiscal_div: item?.pautaFiscal !== tributationNew?.pautaFiscal,
    };
  });
};

export const treatTransformInPorcentage = (aliquota, fixed = 2) => {
  return aliquota >= 0 ? `${parseFloat(aliquota).toFixed(fixed)}%` : "--";
};
export const toFixed = (item, fixed = 2) => {
  return item >= 0 ? parseInt(item).toFixed(fixed) : "---";
};
