import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const INITIAL_STATE = {
  tipos_entrada: [],
  tipos_saida: [],
};

const reducers = {
  handleChangeAllTypes: (state, { payload }) => {
    const field = payload?.field;

    state[field] = payload?.item;
    return state;
  },
  handleChange: (state, { payload }) => {
    const field = payload?.field;
    const copy = state[field];

    const check = (copy || []).filter(item => item === payload?.item);
    const remove = (copy || []).filter(item => item !== payload?.item);

    if (!check?.length) {
      state[field] = [...remove, payload?.item];
      toast.success(`O Cenario de ${payload?.label} ${payload?.item}`);
    } else {
      state[field] = [...remove];
      toast.error(
        `O Cenario de ${payload?.label} ${payload?.item} foi removido`,
      );
    }
    return state;
  },
};

const integrationsConfigsSlice = createSlice({
  name: "integrationsConfigs",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = integrationsConfigsSlice.reducer;
export const actions = integrationsConfigsSlice.actions;
export default reducer;
