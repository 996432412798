import { v4 as uuidv4 } from "uuid";

export const addOrEditSubsRecursively = (
  arrayOfObjects,
  idFather,
  targetId,
) => {
  arrayOfObjects.forEach(obj => {
    obj.sub = obj.sub || [];

    if (obj.id === idFather) {
      const existingSub = obj.sub.find(sub => sub.id === targetId);

      if (existingSub) {
        addOrEditSubsRecursively(existingSub.sub, idFather, targetId);
      } else {
        const newSub = {
          id: uuidv4(),
          nome: "",
          ordem: "",
          sub: [],
        };
        obj.sub.push(newSub);
      }
    } else {
      addOrEditSubsRecursively(obj.sub, idFather, targetId);
    }
  });
};

export const handleChangeUpdateNameRecursively = (
  arrayOfObjects,
  targetId,
  field,
  value,
) => {
  arrayOfObjects.forEach(obj => {
    if (obj.id === targetId) {
      obj[field] = value;
    }

    if (obj.sub && obj.sub.length > 0) {
      handleChangeUpdateNameRecursively(obj.sub, targetId, field, value);
    }
  });
};

export const handleDeleteStageRecursively = (arrayOfObjects, targetId) => {
  for (let i = 0; i < arrayOfObjects.length; i++) {
    const obj = arrayOfObjects[i];

    if (obj.id === targetId) {
      // Remover o estágio com o ID correspondente
      arrayOfObjects.splice(i, 1);
      return;
    }

    if (obj.sub && obj.sub.length > 0) {
      handleDeleteStageRecursively(obj.sub, targetId);
    }
  }
};
