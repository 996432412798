import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  name: "",
  cnpjs: [],
  bookmark: true,
  responsibleId: "",
  sellerId: "",
  initialCalculationPeriod: "",
  finalCalculationPeriod: "",
  initialExpectedTerm: "",
  finalExpectedTerm: "",
  successRate: "",
  createdAt: new Date(),
};

const reducers = {
  handleChange: (state, action) => {
    state = { ...state, ...action.payload };
    console.log("state: ", state);
    return state;
  },
  clearState: () => {
    return { ...INITIAL_STATE };
  },
};

const projectSlice = createSlice({
  name: "fiscalDiagnosis",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = projectSlice.reducer;
export const actions = projectSlice.actions;
export default reducer;
