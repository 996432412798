import { useSelector } from "react-redux";

export const useGetCliIds = () => {
  const { header } = useSelector(state => state.app);

  const stores = header?.storesSelecteds
    ? header?.storesSelecteds
    : header?.stores;

  return stores?.filter(item => item?.mxf)?.map(item => item?.mxf?.cli_id);
};
