import styled, { css } from "styled-components";
import { Button, Input } from "reactstrap";

const device = {
  xs: "400px",
  sm: "600px",
  md: "900px",
  lg: "1280px",
  xl: "1440px",
  xxl: "1920px",
};

const media = {
  xs: (...args) => css`
    @media (max-width: ${device.xs}) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media (max-width: ${device.sm}) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (max-width: ${device.md}) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (max-width: ${device.lg}) {
      ${css(...args)};
    }
  `,
  xl: (...args) => css`
    @media (max-width: ${device.xl}) {
      ${css(...args)};
    }
  `,
  xxl: (...args) => css`
    @media (max-width: ${device.xxl}) {
      ${css(...args)};
    }
  `,
};

export const NavSearch = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;

  ${media.xs`
  flex-direction: column !important;
`}
  ${media.sm`
  flex-direction: column !important;
  `}
`;

export const NavSearchItem = styled.div`
  ${({ show, width }) => css`
    visibility: ${show ? "visible" : "hidden"};
    opacity: ${show ? "1" : "0"};
    transition: visibility 0s, opacity 0.5s linear;
    ${width &&
    css`
      width: ${width};
    `}

    ${media.xs`
      margin-top: 5px;
      width: 100%;
    `}
     ${media.sm`
      margin-top: 5px;
      width: 100%;
    `}
  `}
`;

export const InputCustom = styled(Input)`
  ${({ inputBorderRadius = "50px" }) => css`
    width: 100% !important;
    height: 50px !important;
    border-radius: ${inputBorderRadius} !important;
  `}
`;
export const ButtonCustom = styled(Button)`
  margin-top: 2px !important;
  padding: 0.8rem 0.7rem !important;
  margin-left: 10px !important;
  ${({ btnBorderRadiues = "20px" }) => css`
    border-radius: ${btnBorderRadiues} !important;
  `}
`;

export const HeaderCustom = styled.div`
  padding: 5px 10px;
`;

export const Camera = styled.div`

 & #camera,
 & #camera--view, 
 & #camera--sensor, 
 & #camera--output{
    position: fixed;
    height: 100%;
    width: 100%:
    object-fit: cover;
  }
`;
