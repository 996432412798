import React, { useState, useRef } from "react";

import { PopoverHeader, PopoverBody } from "reactstrap";

import * as S from "./styles";

export const PopoverInfo = ({
  title,
  children,
  popoverBody,
  isWidthAdpt = false,
  placement = "top",
  color,
}) => {
  const ref = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = value => setPopoverOpen(value);

  return (
    <>
      {ref.current && (
        <S.PopoverCustom
          isWidthAdpt={isWidthAdpt}
          placement={placement}
          target={ref.current}
          isOpen={popoverOpen}
          toggle={() => setPopoverOpen(!popoverOpen)}
        >
          <PopoverHeader
            className={color ? `bg-${color} border-${color}` : null}
          >
            {title}
          </PopoverHeader>
          <PopoverBody>{popoverBody ?? children}</PopoverBody>
        </S.PopoverCustom>
      )}
      <div
        ref={ref}
        onMouseEnter={() => togglePopover(true)}
        onMouseLeave={() => togglePopover(false)}
      >
        {children}
      </div>
    </>
  );
};
