import { useFetchNfe } from "hooks";
import { getURLWithParams } from "utils/context";
// TODO: Retirar os usos de hooks com cancelamento

export const useRepricingItens = params =>
  useFetchNfe(
    params
      ? getURLWithParams("/reprecificacao/relatorios-cargas-itens", params)
      : null,
    { method: "POST" },
  );
