import { transformListSelect } from "utils";

const NOT_SELECT = {
  undefined: "Todos",
};

export const CLASSIFICAR_ARR_STATUS_PROD = {
  Novos: "Novos",
  Incompletos: "Incompletos",
  // classificacao: "Classificado",
  // nao_classificado: "Não classificados",
  Revisar: "Revisar",
  Revisar2x: "Revisar2x",
  diagnostico: "Diagnóstico",
  revisarDiag: "Diagnóstico  Revisar",
  corrigirDiag: "Diagnóstico Corrigir Todos",
  corrigirDiag3: "Diagnóstico Corrigir Errados",
  corrigirDiag2: "Diagnóstico Corrigir Verificar",
  classificarDiag: "Diagnóstico Classificar",
  classificado_automaticamente: "Classificado Automaticamente (Mix)",
  classificado_automaticamente_cliente:
    "Classificado Automaticamente (Cliente)",
  classificado_automaticamente_cliente_0_similaridade:
    "Cliente com similaridade 0",
};

export const CLASSIFICAR_ARR_STATUS = {
  classificado: "Classificado",
  nao_classificado: "Não classificados",
  reclassificacao: "Reclassificado",
  classificacao_e_reclassificacao: "Classificados e Reclassificado",
  especificacao: "Especificado",
  revisao: "Revisado",
  insercao: "Inserido",
  alteracao: "Alterado",
  insercao_pc: "Inserido - Prod. Cliente",
  alteracao_pc: "Alterado - Prod. Cliente",
};
export const CLASSIFICAR_ARR_STATUS_PROD_LIST = transformListSelect({
  ...NOT_SELECT,
  ...CLASSIFICAR_ARR_STATUS_PROD,
});

export const CLASSIFICAR_ARR_STATUS_LIST = transformListSelect({
  ...NOT_SELECT,
  ...CLASSIFICAR_ARR_STATUS,
});

export const ScoreColorsTypes = score => {
  if (score < 20) {
    return "danger";
  }

  if (score >= 65) {
    return "success";
  }

  return "warning";
};

export const VINCULO_TYPES = {
  EXISTE: "Existe",
  CRIADO: "Criado",
  CRIADO_EAN_VALIDO: "Criado EAN válido",
  VINCULADO_EAN: "Vinculado EAN",
  VINCULADO_DESCRICAO: "Vinculado descrição",
  VINCULADO_SIMILARIDADE: "Vinculado similaridade",
  VINCULADO_MANUALMENTE: "Vinculado manualmente",
};

export const STATUS_CLASSIFICACAO = {
  CLASSIFICADO: "Classificado",
  NAO_CLASSIFICADO: "Não classificado",
  CLASSIFICADO_SIMILARIDADE: "Classificado similaridade",
  CLASSIFICADO_IGNORADO: "Classificado ignorado",
  NAO_USAR: "Não usar",
  NAO_USAR_IGNORADO: "Não usar ignorado",
};

export const StatusTagsIgnoradosEnum = {
  ATIVO: "ATIVO",
  MANUAL: "MANUAL",
  IGNORADO: "IGNORADO",
  DEFAULT: "Não informado",
};

export const StatusTagsSelect = {
  ATIVO: "ATIVO",
  MANUAL: "MANUAL",
  IGNORADO: "IGNORADO",
};

export const StatusTagsIgnoradosEnumColors = {
  [StatusTagsIgnoradosEnum.ATIVO]: "success",
  [StatusTagsIgnoradosEnum.MANUAL]: "primary",
  [StatusTagsIgnoradosEnum.IGNORADO]: "danger",
  DEFAULT: "warning",
};

export const STATUS_TAGS_LIST = transformListSelect(StatusTagsSelect);

export const MERCADOLOGICO_TYPE = {
  SETOR: "SETOR",
  DEPARTAMENTO: "DEPARTAMENTO",
  GRUPO: "GRUPO",
};

export const MERCADOLOGICO_TYPE_LIST = transformListSelect(MERCADOLOGICO_TYPE);
