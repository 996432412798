import { useMixApi1Toast, useFetchMixAPi1 } from "hooks";
import { getURLWithParams } from "utils/context";

export const useOmieList = (params = {}) =>
  useFetchMixAPi1(getURLWithParams(`/omie/categorias`, params));

export const useOmieShow = (params = {}) =>
  useFetchMixAPi1(getURLWithParams(`/omie/categoria/detalhes`, params));

export const useCommentSend = (id = null) => {
  const { isLoading, onSend } = useMixApi1Toast();

  const onSave = async data => {
    const endpoint =
      id !== null ? `/omie/categoria/update/${id}` : `/omie/categoria/novo`;

    const type = id !== null ? "PUT" : "POST";

    onSend({
      endpoint,
      messageSuccess: `Requisição completada!`,
      messageFail: "Falha ao tentar completar requisição!",
      data,
      type,
    });
  };

  return {
    isLoading,
    onSave,
  };
};
