import { createSlice } from "@reduxjs/toolkit";

export const initialUpdates = {
  sendType: Boolean,
};

const initialState = () => {
  return initialUpdates;
};

export const updatesSlice = createSlice({
  name: "atualizacoes",
  initialState,
  reducers: {
    handleSendType: (state, { payload }) => {
      state.sendType = payload;
    },
  },
});

export const { handleSendType } = updatesSlice.actions;

export default updatesSlice.reducer;
