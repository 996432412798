import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardBody, Row, Col, Collapse, Button } from "reactstrap";
import { BlockUiBlankData } from "components/structure";

import * as S from "./CardFields.styles";
import toast from "react-hot-toast";
import { ChevronDown, ChevronUp } from "react-feather";

export const CardFields = props => {
  const {
    title,
    className,
    data,
    type = "primary",
    icon,
    isLoading,
    isBlankData,
    descriptionError = "Sem Dados",
    childrenTitle,
    isCollapse = false,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleCopy = (field, value) => {
    navigator.clipboard.writeText(value);
    return toast.success(`Copiou ${field}: ${value}`);
  };

  return (
    <BlockUiBlankData
      title={title}
      description={descriptionError}
      show={isLoading || isBlankData}
      isLoading={isLoading}
    >
      <S.CardCustom type={type} className={className} {...props}>
        <S.CardTitleCustom type={type} tag="div">
          <Row>
            <Col md="9">
              {!childrenTitle ? (
                <h4 className="title">
                  {icon || ""}&nbsp;
                  {title}
                </h4>
              ) : (
                childrenTitle
              )}
            </Col>
            <Col
              md="3"
              style={{
                curso: isCollapse ? "pointer" : "auto",
              }}
              className="text-end"
            >
              {isCollapse ? (
                <Button.Ripple
                  className="btn-icon rounded-circle"
                  color="secondary"
                  size="sm"
                >
                  {isOpen ? (
                    <ChevronUp
                      onClick={() => setIsOpen(!isOpen)}
                      color="white"
                      size={15}
                    />
                  ) : (
                    <ChevronDown
                      onClick={() => setIsOpen(!isOpen)}
                      size={15}
                      color="white"
                    />
                  )}
                </Button.Ripple>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </S.CardTitleCustom>
        <Collapse isOpen={isCollapse === false ? true : isOpen}>
          <CardBody>
            <S.CardBodyList>
              {(data || []).map(item => {
                return (
                  <>
                    <S.CardBodyListItem
                      onClick={() => handleCopy(item?.title, item?.value)}
                    >
                      <h5>{item?.title}</h5>
                      <p>{item?.value}</p>
                    </S.CardBodyListItem>
                  </>
                );
              })}
            </S.CardBodyList>
          </CardBody>
        </Collapse>
      </S.CardCustom>
    </BlockUiBlankData>
  );
};

// ** PropTypes
CardFields.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string,
  icon: PropTypes.element,
  actions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  descriptionError: PropTypes.string,
  endReload(props) {
    // ** User passes reload action and doesn't pass endReload then return Error
    if (
      (props["actions"] === "reload" && props["endReload"] === undefined) ||
      (props["actions"].includes("reload") && props["endReload"] === undefined)
    ) {
      return new Error("Please provide a function to end reload!");
    }
  },
};
