import React from "react";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";

import { DownloadCloud } from "react-feather";
import {
  FILE_EXPORT_LIST,
  FILE_EXPORT_TYPE,
} from "constants/Types/Files.types";

import * as S from "./BtnExport.styles";

export const BtnExport = ({
  onHandleExport,
  isLoading = false,
  disabled,
  actives = [FILE_EXPORT_TYPE.CSV, FILE_EXPORT_TYPE.PDF, FILE_EXPORT_TYPE.XLSX],
  size,
}) => {
  const handleExport = path => onHandleExport({ type: path });

  return (
    <UncontrolledButtonDropdown size={size}>
      <S.DropdownToggleCustom
        disabled={isLoading || disabled}
        color="primary"
        size="sm"
        caret
      >
        {isLoading ? (
          <Spinner style={S.CustomIcone} color="light" size="sm" />
        ) : (
          <DownloadCloud size="lg" style={S.CustomIconeDownload} />
        )}
      </S.DropdownToggleCustom>
      <DropdownMenu>
        {FILE_EXPORT_LIST.filter(item => actives.includes(item.type)).map(
          item => (
            <DropdownItem
              key={`item-${item.type}`}
              onClick={() => handleExport(item.type)}
              tag="button"
            >
              {`EXPORTAR ${item.label}`}
            </DropdownItem>
          ),
        )}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};
