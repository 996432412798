import { createSlice } from "@reduxjs/toolkit";

export const initialCountFiles = {
  list: [],
};

const initialState = () => {
  return initialCountFiles;
};

export const countFilesSlice = createSlice({
  name: "countFilesCnpjs",
  initialState,
  reducers: {
    handleList: (state, { payload }) => {
      state.list = [...payload.list];
      //   state.totalPages = payload.totalPages;
    },
  },
});

export const { handleList } = countFilesSlice.actions;

export default countFilesSlice.reducer;
