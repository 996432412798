import { useFetchMixAPi1, useMixApi1, useMixApi1Toast } from "hooks";
import { useCallback } from "react";
import { getURLWithParams } from "utils/context";

export const useRegrasTributarias = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/regras-tributacao", params));

export const useRegrasTributariasDetail = id =>
  useFetchMixAPi1(
    id ? `/legislacao/regras-tributarias/associacoes/detalhe/${id}` : null,
  );

export const useRegrasExtrasDetail = id =>
  useFetchMixAPi1(id ? `/regras-tributarias/campos-extras/${id}` : null);

export const useListStates = () => useFetchMixAPi1(`/utils/estados`);

export const useClassIcmsSelect = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/mxf_class_icms", params));

export const useSearchCbnfe = (params = {}) =>
  useFetchMixAPi1(
    Object.keys(params)?.length > 0
      ? getURLWithParams("/legislacao/cbenef/search", params)
      : null,
  );

export const useCestSelect = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/cest", params));

export const useModificadoresList = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/modificadores/listagem", params));

export const useAtividadesList = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/atividades", params));

export const useCasosEspeciasSelect = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/casos-especiais", params));

export const useFetchRegrasOperacoes = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/operacoes", params));

export const useRegrasTributariasToast = (id = null) => {
  const { onSend } = useMixApi1Toast();

  const onCreate = async data => {
    const endpoint = id
      ? `legislacao/regras-tributacao/detalhe/${id}`
      : `legislacao/regras-tributacao/novo`;
    const type = id ? "PUT" : "POST";

    onSend({
      endpoint,
      messageSuccess: id ? "Atualizado" : `Regra Cadastrada com Sucesso`,
      messageFail: id
        ? "Falha ao atualizar"
        : `Não foi possível cadastrar a Regra`,
      data,
      type,
      redirect: id ? false : true,
      fieldGetId: "mxf_reg_id",
      urlRedirect: "/legislacao/regrastributarias/detail",
    });
  };

  const onRemove = id =>
    onSend({
      endpoint: `legislacao/regras-tributacao/detalhe/${id}`,
      messageSuccess: `Regra Tributária deletada!`,
      messageFail: "Erro ao deletar!",
      type: "DELETE",
    });

  const onDeleteOp = body => {
    onSend({
      endpoint: `/legislacao/regras-tributacao/operacoes/delete`,
      messageSuccess: `Operação removida da lista`,
      messageFail: "Erro ao deletar operação!",
      type: "POST",
      data: body,
    });
  };

  const onDeleteMOd = body => {
    onSend({
      endpoint: `/legislacao/regras-tributacao/modificador/delete`,
      messageSuccess: `Modificador removida da lista`,
      messageFail: "Erro ao deletar Modificador!",
      type: "POST",
      data: body,
    });
  };

  const onDeleteAtiv = body => {
    onSend({
      endpoint: `/legislacao/regras-tributacao/atividade/delete`,
      messageSuccess: `Modificador removida da lista`,
      messageFail: "Erro ao deletar Modificador!",
      type: "POST",
      data: body,
    });
  };

  return {
    onCreate,
    onRemove,
    onDeleteOp,
    onDeleteMOd,
    onDeleteAtiv,
  };
};

export const useLegislacaoCasosEspeciaisFetch = params =>
  useFetchMixAPi1(
    getURLWithParams("/legislacao/casos-especiais/search", params),
  );

export const useLegislacaoClassificacaoFetch = params =>
  useFetchMixAPi1(
    getURLWithParams("/legislacao/mxf_class_icms/search", params),
  );

export const useLegislacaoCbenefFetch = params =>
  useFetchMixAPi1(getURLWithParams("/legislacao/cbenef/search", params));

export const useLegislacaoNotaFiscalFetch = params =>
  useFetchMixAPi1(getURLWithParams("/legislacao/nota-fiscal/search", params));

export const useLegislacaoRegraTributariasSpedFetch = params =>
  useFetchMixAPi1(
    getURLWithParams("/legislacao/regras-tributarias/sped/search", params),
  );

export const useRegraSelectAsync = () => {
  const api = useMixApi1();

  const fetch = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/casos-especiais/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  const fetchClassificacao = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/mxf_class_icms/search", {
            ...params,
            page: 1,
          }),
        );

        const data = response.data?.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  const fetchCbnef = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/cbenef/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  const fetchModificador = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/modificadores/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  const fetchNotasFiscais = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/nota-fiscal/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  const fetchSped = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams(
            "/legislacao/regras-tributarias/sped/search",
            params,
          ),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  const fetchPis = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams(
            "/legislacao/regras-tributarias/pis-cofins/search",
            params,
          ),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  return {
    fetch,
    fetchClassificacao,
    fetchCbnef,
    fetchModificador,
    fetchNotasFiscais,
    fetchPis,
    fetchSped,
  };
};
