import { STATES } from "constants/types";
import { useCallback } from "react";

export const treatSelect = ({ field, arrExist, fieldLabel, arrItem }) => {
  const exists = arrExist?.map(item => item[field]);

  const select = arrItem
    ?.filter(item => exists?.includes(item[field]))
    .map(item => ({
      label: item[fieldLabel],
      value: item[field],
    }));

  return select;
};

export const amountSelect = (res, field, fieldValue) => {
  return {
    label: res?.length > 0 ? res[0][field] : "",
    value: res?.length > 0 ? res[0][fieldValue] : "",
    ...res[0],
  };
};

export const useUpdateDetail = (
  data,
  dataOp,
  dataAt,
  reset,
  searchingAsyncClass,
) => {
  const updateDetail = useCallback(async () => {
    const regra = data?.data[0];

    const sigla = regra?.casos?.length ? regra?.casos[0]?.uf_sigla : "";

    const estado = sigla ? STATES[sigla] : null;

    const select = treatSelect({
      field: "op_sigla",
      arrExist: regra?.operacoes,
      fieldLabel: "op_nome",
      arrItem: dataOp?.data ?? [],
    });

    const selectAt = treatSelect({
      field: "atv_sigla",
      arrExist: regra?.atividades,
      fieldLabel: "atv_nome",
      arrItem: dataAt?.data,
    });

    const ModList = (regra?.modificadores || []).map(item => ({
      label: item.modificadores.mod_nome,
      value: item.modificadores.mod_sigla,
    }));

    const res2 = await searchingAsyncClass({
      class_icms_id:
        regra?.classificacao_icms[0]?.classificacao_icms.class_icms_id,
    });

    const classi = amountSelect(res2, "class_icms_nome", "class_icms_id");
    const cbenef = regra?.rti_cbenef
      ? {
          label: `${regra.rti_cbenef}`,
          value: `${regra.rti_cbenef}`,
          cbenef_codigo: `${regra.rti_cbenef}`,
        }
      : {};

    reset({
      atividades: selectAt,
      operacoes: select,
      rti_nome: regra?.rti_nome,
      rti_observacao: regra?.rti_observacao || "",
      vigencia_init: regra?.rti_vigencia_ini,
      vigencia_end: regra?.rti_vigencia_fim,
      cbnef_aliq: regra?.rti_cbenef_alq,
      mxf_cas_id: {
        label: regra?.casos[0]?.caso.nome_caso,
        value: regra?.casos[0]?.caso.mxf_cas_id,
      },
      class_icms_id: classi,
      rti_cbenef: cbenef,
      modificadores: ModList,
      uf: {
        label: estado,
        value: sigla,
      },
    });
  }, [data, dataAt, dataOp, reset, searchingAsyncClass]);

  return updateDetail;
};
