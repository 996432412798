import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

export const initialBoxAndRisk = {};

const initialState = () => {
  return {
    ...initialBoxAndRisk,
    issue_date_start: format(firstDay, "yyyy-MM-dd"),
    issue_date_end: format(currentDay, "yyyy-MM-dd"),
    uf: null,
  };
};

export const boxAndRiskSlice = createSlice({
  name: "boxAndRisk",
  initialState,
  reducers: {
    handleChangeDate: (state, { payload }) => {
      if (payload?.issue_date_start) {
        state.issue_date_start = payload.issue_date_start;
      }
      if (payload?.issue_date_end) {
        state.issue_date_end = payload.issue_date_end;
      }
    },

    handleChangeState: (state, { payload }) => {
      if (payload?.uf) {
        state.uf = payload.uf;
      } else {
        state.uf = null;
      }
    },
  },
});

export const { handleChangeDate, handleChangeState } = boxAndRiskSlice.actions;

export default boxAndRiskSlice.reducer;
