import React from "react";

import * as S from "../mobileTable.styles";

export const Badges = ({ columns, dataItem }) => {
  return (
    <>
      <S.TagsDiv className="d-flex  flex-wrap">
        {(columns || [])
          .filter(
            item => item?.isShowTag && !item?.isTitle && !item?.isSubTitle,
          )
          .map(item => {
            const value =
              item?.isSelectTypeLabel &&
              Object.keys(item?.typeLabels)?.length > 0
                ? item?.typeLabels[dataItem[item?.sortField]]
                : dataItem[item?.sortField];

            return dataItem[item?.sortField]?.length > 1 ? (
              <S.BadgeCustom color={item?.type || "primary"}>
                {`${item?.name || item?.sortField}: ${value}`}
              </S.BadgeCustom>
            ) : null;
          })}
      </S.TagsDiv>
    </>
  );
};
