import styled, { css } from "styled-components";
import { Popover } from "reactstrap";

export const PopoverCustom = styled(Popover)`
  ${props =>
    props.isWidthAdpt
      ? css`
          & .popover {
            max-width: 100% !important;
          }
        `
      : ""};
`;
