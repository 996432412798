import React from "react";
import DataTableDefault, { createTheme } from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { AlertList } from "components/structure/AlertList";
import { Spinner, Table } from "reactstrap";
import { ChevronDown } from "react-feather";

import { useSkin } from "@hooks/useSkin";

const paginationComponentOptions = {
  rowsPerPageText: "Items por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

createTheme("dark", {
  background: {
    default: "transparent",
  },
});

const CustomPagination = ({
  totalPages,
  handlePageChange,
  page: pageItem,
  paginationPosition,
}) => (
  <ReactPaginate
    nextLabel=""
    breakLabel="..."
    previousLabel=""
    pageCount={totalPages}
    activeClassName="active"
    breakClassName="page-item"
    pageClassName={"page-item"}
    breakLinkClassName="page-link"
    nextLinkClassName={"page-link"}
    pageLinkClassName={"page-link"}
    nextClassName={"page-item next"}
    previousLinkClassName={"page-link"}
    previousClassName={"page-item prev"}
    onPageChange={({ selected }) =>
      handlePageChange && handlePageChange(selected === 0 ? 1 : selected + 1)
    }
    forcePage={pageItem !== 0 ? pageItem - 1 : 0}
    containerClassName={`pagination react-paginate ${paginationPosition} p-1`}
  />
);

const NoData = ({ titleError, isSuccess = false }) => (
  <AlertList
    isSuccess={isSuccess}
    title="Informativo"
    description={titleError || "Ainda não há dados cadastrados nessa seção."}
  />
);

const ProgressPage = () => (
  <div className="text-center pt-4 pb-4">
    <Spinner />
    <h4>Carregando dados....</h4>
  </div>
);

const CellRow = ({ column, item }) => {
  if (column.cell) {
    return column.cell(item);
  }

  return item[column.name];
};

export const DataTable = ({
  isLoading,
  totalDocs,
  totalPages,
  page = 1,
  data = [],
  pagination = true,
  isError = false,
  paginationServer = true,
  handlePageChange = () => { },
  handlePerRowsChange = () => { },
  onSort = null,
  titleError,
  isSuccessNotData = false,
  paginationPosition = `justify-content-end`,
  type = "full",
  ...props
}) => {
  const { skin } = useSkin();

  if (type === "simple") {
    const columns = props.columns ?? [];
    return (
      <Table responsive bordered>
        <thead>
          <tr>
            {columns?.map((item, key) => (
              <th
                className={item.className}
                key={`column-simple-${key}-${item.name}`}
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length}>
                <ProgressPage />
              </td>
            </tr>
          ) : null}
          {!isLoading && data?.length
            ? data?.map(dataItem => (
              <tr key={dataItem.id}>
                {columns.map((column, key) => (
                  <td key={`column-td-simple-${key}-${column.name}`}>
                    <div className={column.className}>
                      <CellRow column={column} item={dataItem} />
                    </div>
                  </td>
                ))}
              </tr>
            ))
            : null}
        </tbody>
      </Table>
    );
  }

  return (
    <DataTableDefault
      pagination={pagination}
      paginationServer={paginationServer}
      data={data}
      onSort={onSort}
      theme={skin === "dark" ? "dark" : "default"}
      sortIcon={onSort && <ChevronDown />}
      paginationDefaultPage={page}
      progressPending={isLoading}
      paginationComponentOptions={paginationComponentOptions}
      paginationTotalRows={totalDocs}
      onChangeRowsPerPage={handlePerRowsChange}
      paginationComponent={extraProps =>
        pagination ? (
          <CustomPagination
            paginationPosition={paginationPosition}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            page={page}
            {...extraProps}
          />
        ) : null
      }
      noDataComponent={
        isError ? (
          <div>{titleError ? titleError : "Nenhum dados para listar"}</div>
        ) : (
          !isLoading &&
          !data.length && (
            <NoData isSuccess={isSuccessNotData} titleError={titleError} />
          )
        )
      }
      progressComponent={<ProgressPage />}
      {...props}
    />
  );
};
