import React from "react";
import { Controller } from "react-hook-form";

import { Input, Label } from "reactstrap";
import * as S from "./Switch.styles";

export const Switch = ({
  name,
  control,
  label,
  helperText,
  error,
  color = "primary",
  ...props
}) => {
  const colorItem = `form-check-${color} form-switch`;
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <S.InputFieldContainer>
            <div className={colorItem}>
              <Input
                type="switch"
                name={name}
                id={`switch-${name}`}
                onChange={onChange}
                value={value}
                checked={value}
              />
              <Label for={`switch-${name}`} className="form-check-label mx-1">
                {label}
              </Label>
            </div>
          </S.InputFieldContainer>
        )}
      />
    );
  }

  const { value, onChange } = props;

  return (
    <S.InputFieldContainer>
      <Input
        value={value}
        name={name}
        onChange={v =>
          onChange ? onChange({ target: { name, value: v } }) : null
        }
        label={label}
      />
    </S.InputFieldContainer>
  );
};
