import React, { Fragment } from "react";
import Flatpickr from "react-flatpickr";

import moment from "moment";

import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { Portuguese } from "flatpickr/dist/l10n/pt";

// * Flatpicker
import "@styles/react/libs/flatpickr/flatpickr.scss";
import "flatpickr/dist/plugins/monthSelect/style.css";

const dateCurrent = new Date();

export const DatePickerMonth = ({
  date,
  value,
  height = "auto",
  style,
  onChange = () => {},
  isMonthLabel = true,
}) => {
  const current = moment(dateCurrent).format("MM/YY");
  const dateCurrentSelected = moment(date).format("MM/YY");

  return (
    <Fragment>
      <input type="hidden" className="date" name="mother" />
      <Flatpickr
        options={{
          disableMobile: true,
          locale: Portuguese,
          plugins: [
            new MonthSelectPlugin({
              shorthand: true,
              dateFormat: "YYYY-MM-DD",
              altFormat: "DD-MM-YYYY",
              altFormat: "F Y",
              theme: "default",
            }),
          ],
          formatDate: dateValue => {
            const dateSelected = moment(dateValue).format("MM/YY");

            return current === dateSelected && isMonthLabel
              ? "Mês Atual"
              : moment(dateValue).format("MM/YY");
          },
        }}
        style={{
          width:
            current === dateCurrentSelected && isMonthLabel ? "105px" : "70px",
          border: "1px solid #7367f0",
          height,
          background: "transparent",
          color: "#7367f0",
          ...style,
        }}
        className="form-control"
        value={value ?? date}
        onChange={onChange}
        id="default-picker"
      />
    </Fragment>
  );
};
