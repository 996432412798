import { createSlice } from "@reduxjs/toolkit";

export const inicialCasos = {
  params: {
    limit: 10,
    page: 1,
    descricao: "",
  },
  modal: {
    show: false,
  },
};

const initialState = () => {
  return inicialCasos;
};

export const CasosSlice = createSlice({
  name: "casosEspeciais",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleSetSearch: (state, { payload }) => {
      if (payload?.isDelete) {
        delete state.params[payload?.field];
      } else {
        state.params[payload?.field] = payload?.value;
      }
    },
    handleModal: (state, { payload }) => {
      state.modal.show = payload;
      return state;
    },
  },
});

export const { handleParams, handleModal, handleSetSearch } =
  CasosSlice.actions;

export default CasosSlice.reducer;
