import { createSlice } from "@reduxjs/toolkit";

export const initialCest = {
  params: {
    page: 1,
    limit: 10,
  },
  list: {
    page: 1,
    limit: 10,
  },
  relate: false,
  selectField: [{ label: "Selecione", value: "Selecione" }],
  chosenField: null,
  inputNcm: "",
};

const initialState = () => initialCest;

export const CestSlice = createSlice({
  name: "legisCest",
  initialState,
  reducers: {
    handleRelate: (state, { payload }) => {
      state.relate = payload;
      return state;
    },
    handleParams: (state, { payload }) => {
      const field = payload.field;
      state.params[field] = payload.value;
      return state;
    },
    handleClearParams: (state, { payload }) => {
      const field = payload?.field;
      delete state.params[field];
      return state;
    },
    handleSelect: (state, { payload }) => {
      state.selectField = payload;
      return state;
    },
    handleList: (state, { payload }) => {
      const field = payload.field;
      state.list[field] = payload.value;
      return state;
    },
    handleChosenField: (state, { payload }) => {
      state.chosenField = payload;
      return state;
    },
    handleNcmInputField: (state, { payload }) => {
      state.inputNcm = payload;
    },
    reset: () => initialState(),
  },
});

export const {
  handleRelate,
  handleParams,
  handleClearParams,
  handleSelect,
  handleList,
  reset,
  handleChosenField,
  handleNcmInputField,
} = CestSlice.actions;

export default CestSlice.reducer;
