import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  isShowModalClassification: {
    show: false,
    data: {},
  },
  isShowModalSolicitar: {
    show: false,
    data: {},
  },
  params: {
    page: 1,
    limit: 5,
  },
  params_class: {
    page: 1,
    limit: 10,
  },
  prodSelected: {},
  prodSelectedFilter: {
    origem: "EI",
    saida: "SNC",
  },
  cardIcmsSale: {
    tipo: "SNC",
  },
  cardPisSale: {
    tipo: "SNC",
  },
  cardIcmsEntrie: {
    tipo: "EI",
  },
  cardPisEntrie: {
    tipo: "EI",
  },
  productTributacion: {
    entrie: {},
    sale: {},
    row_entrada: {},
    row_saida: {},
  },
  countSearch: {
    count: 0,
  },
};

const reducers = {
  handleOpenClassificationModal: (state, { payload }) => {
    Object.keys(payload).map(field => {
      const value = payload[field];
      return (state.isShowModalClassification[field] = value);
    });
  },
  handleToggleModalSolicitar: (state, { payload }) => {
    Object.keys(payload).map(field => {
      const value = payload[field];
      return (state.isShowModalSolicitar[field] = value);
    });
  },
  handleChangeProductTributacaoEntrie: (state, { payload }) => {
    state.productTributacion.entrie = payload?.fields;
    state.productTributacion.row_entrada = payload?.row;
  },
  handleChangeProductTributacaoSale: (state, { payload }) => {
    state.productTributacion.sale = payload?.fields;
    state.productTributacion.row_saida = payload?.row;
  },
  handleProdSelected: (state, { payload }) => {
    state.prodSelected = payload?.selected;
  },
  handleCardIcmsSale: (state, { payload }) => {
    state.cardIcmsSale.tipo = payload;
  },
  handleCardPisSale: (state, { payload }) => {
    state.cardPisSale.tipo = payload;
  },

  handleCardIcmsEntries: (state, { payload }) => {
    state.cardIcmsEntrie.tipo = payload;
  },
  handleCardPisEntries: (state, { payload }) => {
    state.cardPisEntrie.tipo = payload;
  },

  handleProdSelectedFilter: (state, { payload }) => {
    state.prodSelectedFilter[payload?.field] = payload?.value;
  },
  handleClearParams: (state, { payload }) => {
    const params = payload?.params;
    state.params = {};
    Object.keys(params)
      .filter(field => params[field] !== undefined && !params[field] !== "")
      .map(field => {
        state.params[field] = params[field];
      });
    return state;
  },
  handleParamsMultiples: (state, { payload }) => {
    const params = payload?.params;
    Object.keys(params)
      .filter(field => params[field] !== undefined && !params[field] !== "")
      .map(field => {
        state.params[field] = params[field];
      });
    return state;
  },

  handleParamsClass: (state, { payload }) => {
    const field = payload?.field;
    state.params_class[field] = payload?.value;
    return state;
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
};

const escriturationClassificationSlice = createSlice({
  name: "escriturationClassification",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = escriturationClassificationSlice.reducer;
export const actions = escriturationClassificationSlice.actions;
export default reducer;
