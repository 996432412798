import { createSlice } from "@reduxjs/toolkit";
import { companiesByStores, storesUniqs } from "utils/context/store";
import {
  handleSelectCompaniesReducer,
  handleSelectStoreReducer,
  handleSelectStoreClearReducer,
  handleSelectCompaniesClearReducer,
  handleSelectStoreMultiplesReducer as handleSelectStoreMultiplesReducerSelect,
  handleSelectCompaniesReducerMultiples as handleSelectCompaniesReducerMultiplesSelect,
} from "./app/reducers";
import {
  setSessionGroupAcess,
  setSessionModules,
  setSessionGroupsAcess,
  getSessionModules,
} from "utils";

const LOCAL_NAME = "app-headerData";

const initialHeader = () => {
  const headerData = window.localStorage.getItem(LOCAL_NAME)
    ? JSON.parse(window.localStorage.getItem(LOCAL_NAME))
    : {};

  const initialGroup = JSON.parse(
    window.localStorage.getItem("@mixfiscal:groupAccess"),
  );

  const initialModules = getSessionModules();

  const initialStore = JSON.parse(
    window.localStorage.getItem("@mixfiscal:stores"),
  );

  const initialCompanies = JSON.parse(
    window.localStorage.getItem("@mixfiscal:companies"),
  );

  const groupsAccess = JSON.parse(
    window.localStorage.getItem("@mixfiscal:groupsAccess"),
  );

  const cliIds = headerData?.stores?.map(item => item?.mxf?.cli_id);

  return {
    accessGroupOpen: false,
    companiesOpen: false,
    storesOpen: false,
    search: false,
    accessGroup: initialGroup?._id,
    accessGroupAll: initialGroup,
    modulesPerms: initialModules,
    companies: headerData?.companies?.length < 1 ? initialCompanies : [],
    groupsAccess,
    companiesSelecteds:
      !headerData?.companies || headerData?.companies?.length < 1
        ? initialCompanies
        : [],
    stores:
      !headerData?.stores || headerData?.stores?.length < 1 ? initialStore : [],
    storesSelecteds:
      !headerData?.stores || headerData?.stores?.length < 1 ? initialStore : [],
    companiesSearchSelecteds: [],
    openMobileViewSearch: false,
    cliIds,
    ...headerData,
  };
};

export const setHeaderData = (values = {}) =>
  window.localStorage.setItem(LOCAL_NAME, JSON.stringify(values));

export const appSlice = createSlice({
  name: "app",
  initialState: {
    header: initialHeader(),
  },
  reducers: {
    handleHeaderGroup: (state, action) => {
      state.header.accessGroup = action.payload._id;
      setSessionGroupAcess(action?.payload);
      setSessionModules(action?.payload?.modules);
      setHeaderData(state.header);
    },
    handleHeaderGroupsAcess: (state, action) => {
      state.header.groupsAccess = action?.payload;
      setSessionGroupsAcess(action?.payload);
      setHeaderData(state.header);
    },
    handleHeaderGroupsAcessModules: (state, action) => {
      state.header.modulesPerms = action?.payload;
    },
    handleSelectCompaniesReducerMultiples:
      handleSelectCompaniesReducerMultiplesSelect,
    handleSelectStoreMultiplesReducer: handleSelectStoreMultiplesReducerSelect,
    handleSelectCompanies: handleSelectCompaniesReducer,

    handleSelectStore: handleSelectStoreReducer,
    handleHeaderGroupClose: () => {},
    handleHeaderAccessGroup: (state, action) => {
      state.header.accessGroupOpen = action.payload;
      setHeaderData(state.header);
    },
    handleHeaderCompanies: (state, action) => {
      state.header.companiesOpen = action.payload;
      setHeaderData(state.header);
    },
    handleHeaderStores: (state, action) => {
      state.header.storesOpen = action.payload;
      setHeaderData(state.header);
    },

    handleHeaderSearch: (state, action) => {
      state.header.search = action.payload;
    },

    handleCompaniesSearchSelecteds: (state, action) => {
      const { stores } = action.payload;

      state.header.companiesSearchSelecteds = stores;
    },

    handleCompaniesSearchSubmit: (state, action) => {
      const { companiesSearchSelecteds } = action.payload;

      const companies = companiesByStores(companiesSearchSelecteds);
      const stores = storesUniqs(companiesSearchSelecteds);

      handleSelectCompaniesClearReducer(state);
      for (const company of companies) {
        handleSelectCompaniesReducer(state, {
          payload: company,
        });
      }

      handleSelectStoreClearReducer(state);
      for (const store of stores) {
        handleSelectStoreReducer(state, {
          payload: store,
        });
      }

      state.header.search = false;
      state.header.companiesSearchSelecteds = [];

      setHeaderData(state.header);
    },

    handleSyncAfterLogin: state => {
      state.header = initialHeader();
    },
    handleSetViewMobileConfig: (state, { payload }) => {
      state.header.openMobileViewSearch = payload;
    },
  },
});

export const {
  handleHeaderGroup,
  handleHeaderGroupClose,
  handleHeaderAccessGroup,
  handleSelectCompaniesReducerMultiples,
  handleSelectStoreMultiplesReducer,
  handleHeaderSearch,
  handleSelectCompanies,
  handleSelectStore,
  handleHeaderCompanies,
  handleHeaderStores,
  handleCompaniesSearchSelecteds,
  handleCompaniesSearchSubmit,
  handleSyncAfterLogin,
  handleSetViewMobileConfig,
  handleHeaderGroupsAcess,
  handleHeaderGroupsAcessModules,
} = appSlice.actions;

export default appSlice.reducer;
