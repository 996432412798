import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  modal: {
    show: false,
  },
};

const reducers = {
  handleOpenModal: (state, { payload }) => {
    state.modal = payload;
  },
};

const formContratoSlice = createSlice({
  name: "formContrato",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = formContratoSlice.reducer;
export const actions = formContratoSlice.actions;
export default reducer;
