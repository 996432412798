function validate(id) {
  return id.length === 64 || id.length === 65;
}

export const treatMachineId = (row, cmfCliObservacoes) => {
  const lines = cmfCliObservacoes?.split(/(\r\n|\n|\r)/gm) ?? [];

  const listMachinesIds = {};

  if (validate(cmfCliObservacoes?.trim() ?? "")) {
    return [{ machinesIds: [cmfCliObservacoes] }];
  }

  for (const line of lines) {
    const texts = line.split(" ");
    for (const text of texts) {
      const parsedText = text.replace(/(\r\n|\n|\r)/gm, "");
      const valid = validate(parsedText);
      if (valid) {
        if (listMachinesIds[row.cmf_cli_nome] === undefined) {
          listMachinesIds[row.cmf_cli_nome] = {
            machinesIds: [parsedText],
          };
        } else {
          listMachinesIds[row.cmf_cli_nome].machinesIds.push(parsedText);
        }
      }
    }
  }

  const listData = Object.values(listMachinesIds);

  return listData;
};
