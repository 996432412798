import { useFetchMixAPi1, useMixApi1, useMixApi1Toast } from "hooks";
import { useCallback } from "react";
import { getURLWithParams } from "utils/context";

export const useCestlist = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/list/cest", params));

export const useCestDetail = id =>
  useFetchMixAPi1(id ? `/legislacao/cest/detail/${id}` : null);

export const useListCestSegmentos = () =>
  useFetchMixAPi1(`/legislacao/cest-segmentos`);

export const useCestListRelates = (params = {}) =>
  useFetchMixAPi1(getURLWithParams(`/legislacao/cest-relacao`, params));

export const useDownloadNcmRelates = (clicked, params = {}) =>
  useFetchMixAPi1(
    clicked
      ? getURLWithParams(`/legislacao/cest-relacao/download`, params)
      : null,
  );

export const useCestNew = id => {
  const { isLoading, data, onSend } = useMixApi1Toast();

  const cestCreate = async data => {
    const endpoint = id
      ? `/legislacao/cest/update/${id}`
      : `/legislacao/cest/create`;

    const type = id ? "PUT" : "POST";

    const res = await onSend({
      endpoint,
      messageSuccess: "Sucesso!",
      messageFail: "Requisição falhou!",
      type,
      data,
    });
    return res;
  };

  const onRemove = id =>
    onSend({
      endpoint: `/legislacao/cest/delete/${id}`,
      messageSuccess: `CEST deletado!`,
      messageFail: `Erro ao deletar`,
      type: "DELETE",
    });

  const onRemoveNcmLinked = (params = {}) =>
    onSend({
      endpoint: getURLWithParams(
        `/legislacao/cest-relalacao-ncm/delete`,
        params,
      ),
      messageSuccess: `NCM Relacionado deletado!`,
      messageFail: `Erro ao deletar`,
      type: "DELETE",
    });

  return {
    cestCreate,
    onRemove,
    onRemoveNcmLinked,
    isLoading,
    data,
  };
};

export const useNcmAndCestRelation = () => {
  const { isLoading, data, onSend } = useMixApi1Toast();

  const addOnUpdate = async data => {
    await onSend({
      endpoint: `/legislacao/cest-relacionar-ncm`,
      messageSuccess: "Sucesso!",
      messageFail: "Requisição falhou!",
      type: "POST",
      data,
    });
  };

  return {
    addOnUpdate,
    isLoading,
    data,
  };
};

export const useCestSelectAsync = () => {
  const api = useMixApi1();

  const fetch = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/cest/busca", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );
  return {
    fetch,
  };
};
