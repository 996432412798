import { useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useListaShow = params =>
  useFetchMixAPi1(getURLWithParams(`/omie/financas/omie_titulos`, params));

export const useListaShowTitulos = params =>
  useFetchMixAPi1(
    getURLWithParams(`/omie/financas/omie_titulos/titulos`, params),
  );

export const useDownloadBoleto = () => {
  const { isLoading, data, onSend } = useMixApi1Toast();

  const onDownload = async id => {
    const res = await onSend({
      endpoint: `omie/financas/baixar-boleto/${id}`,
      messageSuccess: `Download concluido com success.`,
      messageFail: "Download ocorreu um error",
      type: "POST",
    });

    if (res?.cLinkBoleto) {
      window.open(res.cLinkBoleto, "_blank");
    }
  };

  return {
    onDownload,
    isLoading,
    data,
  };
};

export const useEnviarEmailBoleto = () => {
  const { isLoading, data, onSend } = useMixApi1Toast();

  const onEnviarEmail = async (cnpj, nf) => {
    try {
      const res = await onSend({
        endpoint: `/envio/email/solicita-boleto`,
        data: {
          data: [{ cnpj, nf }],
        },
        messageSuccess: `E-mail enviado com sucesso.`,
        messageFail: "Erro ao enviar o e-mail.",
        type: "POST",
      });

      return res;
    } catch (error) {
      console.error("Erro ao enviar o e-mail:", error);
      return null;
    }
  };

  return {
    onEnviarEmail,
    isLoading,
    data,
  };
};
