import React from "react";
import ReactPaginate from "react-paginate";

export const CustomPagination = ({
  totalPages,
  handlePageChange,
  page: pageItem,
}) => (
  <ReactPaginate
    nextLabel=""
    breakLabel="..."
    previousLabel=""
    pageCount={totalPages}
    activeClassName="active"
    breakClassName="page-item"
    pageClassName={"page-item"}
    breakLinkClassName="page-link"
    nextLinkClassName={"page-link"}
    pageLinkClassName={"page-link"}
    nextClassName={"page-item next"}
    previousLinkClassName={"page-link"}
    previousClassName={"page-item prev"}
    onPageChange={({ selected }) =>
      handlePageChange && handlePageChange(selected === 0 ? 1 : selected + 1)
    }
    forcePage={pageItem !== 0 ? pageItem - 1 : 0}
    containerClassName={"pagination react-paginate justify-content-end p-1"}
  />
);
