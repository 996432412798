import { useFetchNfe, useAPINFE2Toast } from "hooks";

export const useFetchTags = () => useFetchNfe(`/invoice/tags-xmls`);

export const useFetchTagShow = id => useFetchNfe(`/invoice/tags-xmls/${id}`);

export const useActionsTags = id => {
  const { isLoading, data, onSend } = useAPINFE2Toast();

  const onSave = async data => {
    const res = await onSend({
      endpoint: id ? `invoice/tags-xmls/${id}` : `invoice/tags-xmls`,
      messageSuccess: id ? "Tag Atualizada" : `Nova tag Cadastrada`,
      messageFail: id
        ? "Falha ao atualizar dados do parametro"
        : "Falaha ao tentar cadastrar novo parametro",
      type: "POST",
      data,
    });
    return res;
  };

  const onDelete = async idDelete => {
    const res = await onSend({
      endpoint: `invoice/tags-xmls/delete/${idDelete}`,
      messageSuccess: "Tag deletado com sucesso",
      messageFail: "Não foi possivel deletar a tag",
      type: "DELETE",
    });
    return res;
  };

  return {
    onSave,
    onDelete,
    isLoading,
    data,
  };
};
