import styled from "styled-components";
import { CardTitle, Card } from "reactstrap";

export const colorStyles = {
  white: {
    bg: "#fff",
    itemsColor: "#fff",
    border: "none",
  },
  secondary: {
    bg: "#82868b",
    itemsColor: "#fff",
    border: "none",
  },
  success: {
    bg: "#28c76f",
    itemsColor: "#fff",
    border: "none",
  },
  error: {
    bg: "#e72b2c",
    itemsColor: "#fff",
    border: "none",
  },
  warning: {
    bg: "#F6B000",
    itemsColor: "#5e5873",
    border: "none",
  },
  info: {
    bg: "#00cfe8",
    itemsColor: "#596778",
    border: "none",
  },
  primary: {
    bg: "#7367f0",
    bgHover: "#9E95FF",
    itemsColor: "#fff",
    border: "1px solid #5A8DEE",
  },

  notify: {
    bg: "#5A8DEE",
    itemsColor: "#fff",
    border: "1px solid #5A8DEE",
  },
};

export const CardBodyList = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;

export const CardBodyListItem = styled.div`
  flex: 50%;
  padding: 5px 10px 5px 10px;
  &:hover {
    background-color: #f3f3f3;
  }
`;

export const CardCustom = styled(Card)`
  padding: 10px 10px 20px;
  border-top: 3px solid
    ${props =>
      props.type
        ? colorStyles[props.type].bg
        : colorStyles["primary"].bg} !important;
  border-radius: 5px;
`;

export const CardTitleCustom = styled(CardTitle)`
  margin: 0 !important;

  & div > div > h4 {
    padding-rigth: 10px;
    margin: 0 !important;
    font-size: 19px;
    color: #5e5873;
  }
`;
