import React, { useState } from "react";

import { formatCnpj } from "utils";

import { CheckCircle, AlertCircle } from "react-feather";

import { Row, Col, Badge } from "reactstrap";

import Avatar from "@components/avatar";

import { PopoverInfo } from "components/structure/PopoverInfo";

export const CertificatePopover = ({ dias }) => {
  const active = value => {
    if (value && value.toString().substr(0, 1) !== `-`) {
      return {
        title: `Seu certificado venceu tem:`,
        active: false,
      };
    }
    return {
      title: `Seu certificado vence em:`,
      active: true,
    };
  };

  return (
    <>
      <h4>{active(dias)?.title}</h4>
      <div className="d-flex justify-content-center">
        <div className="d-flex align-items-center">
          <Avatar
            color="light-danger"
            icon={<AlertCircle size={24} />}
            style={{ padding: 3 }}
            className="me-1"
          />
          <div className="my-auto text-center">
            <h5 className="mb-0">
              {dias?.toString().substr(0, 1) === `-`
                ? dias?.toString().substr(1)
                : dias}
            </h5>
            <small>Dias</small>
          </div>
        </div>
      </div>
    </>
  );
};

export const OptionsComponentStore = ({ data: { data } }) => {
  const idPopover = `Popover-Id-${data._id}`;
  const [popoverOpen, setPopoverOpen] = useState("");
  const active = value => {
    if (value && value.toString().substr(0, 1) !== `-`) {
      return {
        active: `inativo`,
      };
    }
    return {
      active: `ativo`,
    };
  };

  const OpenPopover = () => {
    popoverOpen === idPopover ? setPopoverOpen("") : setPopoverOpen(idPopover);
  };

  const popover = <CertificatePopover dias={data?.fadel?.dias_vencidos} />;

  return (
    <>
      <Row active={data?.isActive} style={{ width: "100%" }}>
        <Col md="12">
          {data.companyName || data.legalNature || data.tradeName}
        </Col>
        <Col md="12">
          <b>REGIME FISCAL:</b>&nbsp;
          {data.taxRegime}
        </Col>
        <Col md="4" className="d-flex  mt-1">
          <Badge color="info" className="me-1">
            MATRIZ
          </Badge>

          <Badge color={data.isActive ? `success` : `danger`} className="me-1">
            {data.isActive === true ? `ATIVO` : `INATIVA`}
          </Badge>

          <PopoverInfo
            target={idPopover}
            popoverOpen={popoverOpen}
            setPopoverOpen={setPopoverOpen}
            isWidthAdpt={true}
            placement="top"
            title="Certificados A1"
            popoverBody={popover}
          >
            <Badge
              color={
                active(data?.fadel?.dias_vencidos).active === "inativo"
                  ? "danger"
                  : "success"
              }
              onMouseEnter={() => OpenPopover()}
              onMouseLeave={() => setPopoverOpen("")}
              id={idPopover}
              pill
            >
              {active(data?.fadel?.dias_vencidos).active === "inativo" ? (
                <AlertCircle />
              ) : (
                <CheckCircle />
              )}
            </Badge>
          </PopoverInfo>
        </Col>
        <Col md="8" className="mt-1 d-flex justify-content-end">
          <Badge color="secondary" pill>
            CNPJ:&nbsp;{formatCnpj(data.cnpj)}
          </Badge>
        </Col>
      </Row>
    </>
  );
};
