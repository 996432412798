import { useRef, useState } from "react";
import axios from "axios";
import { getSessionUser } from "utils/session";
import { useToast } from "./useToast";

const BASE_URL =
  process.env.REACT_APP_INTEGRACAO_BLUE ||
  "https://integracao.mixfiscal.com.br/bluesoft/api";

export const useApiIntegrationBlueSoft = () => {
  const user = getSessionUser();

  const tokenAcessIntegrations = user?.token_integration;

  // TODO: Adicionar toast automatico quando as requisições falharem!
  const onRejected = error => {
    // toast.error("Ocorre um error!");
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: BASE_URL,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${tokenAcessIntegrations}`;
    if (tokenAcessIntegrations !== undefined) {
      config.headers["x-access-token"] = tokenAcessIntegrations;
    }
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};
export const useApiIntegrationBlueSoftToast = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useApiIntegrationBlueSoft();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: messageSuccess || data?.message,
        });
      }
      setIsLoading(false);
      return data;
    } catch (err) {
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onSend,
  };
};
