import { createSlice } from "@reduxjs/toolkit";

const currentYear = new Date().getFullYear();

export const initialCnpjTotals = {
  active: currentYear.toString(),
};

const initialState = () => {
  return initialCnpjTotals;
};

export const cnpjTotalsSlice = createSlice({
  name: "cnpjTotals",
  initialState,
  reducers: {
    handleActive: (state, { payload }) => {
      state.active = payload.active;
    },
  },
});

export const { handleActive } = cnpjTotalsSlice.actions;

export default cnpjTotalsSlice.reducer;
