import { createSlice } from "@reduxjs/toolkit";

const initialRelatorioSkus = {
  page: 50,
};

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const initialState = () => {
  return {
    ...initialRelatorioSkus,
    issueDateStart: firstDay,
    issueDateEnd: currentDay,
  };
};

export const relatorioSkusSlice = createSlice({
  name: "relatorioSkus",
  initialState,
  reducers: {
    handleChangeDate: (state, { payload }) => {
      state.issueDateStart = payload.issueDateStart;
      state.issueDateEnd = payload.issueDateEnd;
    },
    handleRowLimit: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const { handleChangeDate, handleRowLimit } = relatorioSkusSlice.actions;

export default relatorioSkusSlice.reducer;
