import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const today = new Date();
const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const dateStart = new Date(today.getFullYear(), today.getMonth(), 1);
const dateEnd = new Date(
  `${today.getFullYear}-${today.getMonth}-${lastDayMonth}`,
);

const initialOportunidade = {
  list: [],
  listInvoice: [],
  params: {
    page: 1,
    limit: 20,
    type: "",
    start: format(dateStart, "yyyy-MM-dd"),
    end: format(dateEnd, "yyyy-MM-dd"),
  },
  filter: false,
  currentMonth: [],
  totalPages: 0,
  columns: [],
  filters: [],
};
const initialState = () => {
  return initialOportunidade;
};

const handleParamsReducer = (state, { payload }) => {
  state.params[payload?.field] = payload.value;
};

export const oportunidadeSlice = createSlice({
  name: "oportunidade",
  initialState,
  reducers: {
    handleChangeParams: handleParamsReducer,
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
    },
    handleOpenFilter: (state, action) => {
      state.filter = action.payload;
    },
    handleSetListInvoice: (state, { payload }) => {
      state.listInvoice = [...payload.list];
    },
    handleColumns: (state, { payload }) => {
      state.columns = payload;
    },
    handleParamsPage: (state, { payload }) => {
      state.params.page = payload.page;
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
    handleParamsStartEnd: (state, { payload }) => {
      // if (payload?.start) {
      state.params.start = payload.start;
      // }
      // if (payload?.end) {
      state.params.end = payload.end;
      // }
    },
    handleTotalPages: (state, { payload }) => {
      state.totalPages = payload;
    },
  },
});

export const {
  handleSetList,
  handleColumns,
  handleTotalPages,
  handleSetListInvoice,
  handleParamsPage,
  handleParamsLimit,
  handleParamsStartEnd,
  handleChangeParams,
  handleOpenFilter,
} = oportunidadeSlice.actions;

export default oportunidadeSlice.reducer;
