import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  params: {
    page: 1,
    limit: 20,
  },
};

const reducers = {
  handleParams: (state, { payload }) => {
    state.params = payload?.fields;
    return state;
  },
  handleParamsFields: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
};

const parameterConfiguratorSlice = createSlice({
  name: "reportsEscrituration",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = parameterConfiguratorSlice.reducer;
export const actions = parameterConfiguratorSlice.actions;
export default reducer;
