import React from "react";
import { useDispatch } from "react-redux";
import {
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { Eye } from "react-feather";

import { handleToogleDialog, handleToogleTab } from "redux/invoice";

import { dateFormatInvoice, hourFormat } from "utils";

const TYPES = {
  COMPRAS: "compras",
  VENDAS: "vendas",
  COMPRA_VENDAS: "vendas-or-compra",
};

export const limitCaracter = (
  limit = 35,
  param,
  id,
  title = "Natureza da Operação",
) => {
  const newData = param ? (
    <>
      <p id={id}>{`${param.substr(0, limit)}...`}</p>
      <UncontrolledPopover placement="top" target={id}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{param}</PopoverBody>
      </UncontrolledPopover>
    </>
  ) : (
    "-"
  );
  return newData;
};

export const checkModel = modelo => {
  if (modelo === "55") {
    return <>{modelo} - NFe</>;
  }
  if (modelo === "57") {
    return <>{modelo} - CTe</>;
  }
  if (modelo === "59") {
    return <>{modelo} - SAT</>;
  }
  if (modelo === "65") {
    return <>{modelo} - NFCe</>;
  }

  return modelo;
};

const Action = row => {
  const dispatch = useDispatch();

  const toogleDialog = () => {
    dispatch(
      handleToogleDialog({
        show: true,
        id: row?.id,
        data: {},
        modelo: "",
      }),
    );
    dispatch(
      handleToogleTab({
        value: "divergente",
      }),
    );
  };
  return (
    <div className="column-action d-flex align-items-center">
      <Eye
        onClick={toogleDialog}
        id={`pw-tooltip-${row?.id}`}
        size={17}
        className="mx-1"
      />
      <UncontrolledTooltip placement="top" target={`pw-tooltip-${row?.id}`}>
        Detalhe da Nota
      </UncontrolledTooltip>
    </div>
  );
};

export const columns = [
  {
    name: "",
    width: "70px",
    sortField: "---",
    sortable: false,
    typesDefaults: TYPES.COMPRA_VENDAS,
    omit: false,
    disabledDrop: true,
    position: 0,
    cell: row => {
      return Action(row);
    },
  },

  {
    name: "CHAVE",
    sortable: false,
    typesDefaults: [],
    typesDefaults: TYPES.COMPRA_VENDAS,
    sortField: "chave",
    width: "400px",
    position: 1,
    omit: false,
    cell: row => {
      return row?.id;
    },
  },

  {
    name: "Dt. Emissão",
    sortable: false,
    typesDefaults: TYPES.COMPRA_VENDAS,
    sortField: "dhemi",
    omit: false,
    position: 2,
    width: "220px",
    cell: row => {
      return (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <h6 className="user-name  mb-0">
              Data: {dateFormatInvoice(row?.dhemi)}
            </h6>
            <h6 className="user-name  mb-0">Hora: {hourFormat(row?.dhemi)}</h6>
          </div>
        </div>
      );
    },
  },
  {
    name: "N° Produto na Nota",
    sortable: false,
    typesDefaults: [],
    typesDefaults: TYPES.COMPRA_VENDAS,
    sortField: "nitem",
    width: "200px",
    position: 3,
    omit: false,
    cell: row => {
      return row?.nitem;
    },
  },
];
