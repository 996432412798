import React, { useState, createContext, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "react-google-login";

import {
  AUTH_TOKEN_SESSION,
  AUTH_TOKEN_SESSION_TEMPLATE,
  AUTH_TOKEN_SESSION_COMPANIES,
  AUTH_TOKEN_SESSION_TOKEN_BASE,
  AUTH_USER_SESSION,
  AUTH_SESSION_COMPANIES,
  AUTH_SESSION_GROUP_ACESSS,
  AUTH_SESSION_STORES,
  removeClaims,
  getSessionTokenBase,
  isAdvancedUser,
  setSession,
} from "utils";
import { useToast } from "./ToastContext";
import authenticationApi from "../services/authenticationApi";
import templatesApi from "../services/templatesApi";

const AuthContext = createContext({});
const googleClientId =
  "749521299246-gc4o8jqqte07f3oglm3be4u6on1s7n8j.apps.googleusercontent.com";

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error("useAuth precisa ser utilizado dentro de um AuthProvider");
  return context;
};

const useGoogleLoginCustom = (
  handleGoogleLoginSuccess,
  handleGoogleLoginFailure,
) => {
  const { signIn: signInGoogle, signOut: signOutGoogle } = useGoogleLogin({
    clientId: googleClientId,
    onSuccess: handleGoogleLoginSuccess,
    onFailure: handleGoogleLoginFailure,
  });

  return { signInGoogle, signOutGoogle };
};

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(() => {
    const token = localStorage.getItem(AUTH_TOKEN_SESSION_TEMPLATE);
    const user = localStorage.getItem(AUTH_USER_SESSION);
    const tokenCompanies = localStorage.getItem(AUTH_TOKEN_SESSION_COMPANIES);
    const tokenBase = getSessionTokenBase();

    if (token && user) {
      return {
        token,
        user: JSON.parse(user),
        tokenCompanies,
        tokenBase,
        revalidate: [],
      };
    }

    return {};
  });

  const [currentPermission, setCurrentPermission] = useState(null);

  const history = useNavigate();
  const { addToast } = useToast();

  const signIn = useCallback(
    async ({ email, password }) => {
      setIsLoading(true);
      try {
        const { data: authenticationServiceData } =
          await authenticationApi.post("/login", {
            email,
            password,
            browser_id: "anything",
          });

        if (!authenticationServiceData) throw new Error();

        const { data: templateServiceData } = await templatesApi.post(
          "/login",
          {
            email: authenticationServiceData.user.email,
            hash: authenticationServiceData.user.hash,
          },
        );

        if (!templateServiceData) throw new Error();

        setSession(
          AUTH_SESSION_COMPANIES,
          authenticationServiceData?.companies,
        );
        setSession(
          AUTH_SESSION_GROUP_ACESSS,
          authenticationServiceData.accessGroup,
        );
        setSession(AUTH_SESSION_STORES, authenticationServiceData.stores);
        setData({
          token: templateServiceData.token,
          user: authenticationServiceData.user,
        });

        window.localStorage.setItem(
          AUTH_TOKEN_SESSION_TEMPLATE,
          templateServiceData.token,
        );

        window.localStorage.setItem(
          AUTH_TOKEN_SESSION,
          authenticationServiceData.token,
        );

        window.localStorage.setItem(
          AUTH_TOKEN_SESSION_COMPANIES,
          authenticationServiceData.tokenCompanies,
        );
        window.localStorage.setItem(
          AUTH_TOKEN_SESSION_TOKEN_BASE,
          authenticationServiceData.tokenBase,
        );
        const userItem = {
          ...authenticationServiceData.user,
          layout: "vertical",
        };
        window.localStorage.setItem(
          AUTH_USER_SESSION,
          JSON.stringify(userItem),
        );

        setIsLoading(false);
        return authenticationServiceData;
      } catch (err) {
        throw new Error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading],
  );

  const signOut = useCallback(() => {
    removeClaims();
    setData({});
    window.location.href = "/login";
  }, []);

  const handleGoogleLoginSuccess = async googleResponse => {
    const { data: authenticationServiceData } = await authenticationApi.post(
      "/login",
      {
        googleAuth: {
          email: googleResponse.profileObj.email,
          name: `${googleResponse.profileObj.givenName} ${googleResponse.profileObj.familyName}`,
          key: googleResponse.profileObj.googleId,
        },
        browser_id: "anything",
      },
    );

    if (!authenticationServiceData) throw new Error();

    const { data: templateServiceData } = await templatesApi.post("/login", {
      email: authenticationServiceData.user.email,
      hash: authenticationServiceData.user.hash,
    });

    if (!templateServiceData) throw new Error();

    setData({
      token: templateServiceData.token,
      user: authenticationServiceData.user,
    });

    window.localStorage.setItem(
      AUTH_TOKEN_SESSION_TEMPLATE,
      templateServiceData.token,
    );

    window.localStorage.setItem(
      AUTH_TOKEN_SESSION,
      authenticationServiceData.token,
    );

    window.localStorage.setItem(
      AUTH_TOKEN_SESSION_COMPANIES,
      authenticationServiceData.tokenCompanies,
    );

    window.localStorage.setItem(
      AUTH_USER_SESSION,
      JSON.stringify(authenticationServiceData.user),
    );

    window.localStorage.setItem(
      AUTH_TOKEN_SESSION_TOKEN_BASE,
      authenticationServiceData.tokenBase,
    );

    history.go(0);
  };
  const handleGoogleLoginFailure = () => {
    // TODO: adicionar um toast de erro aqui
  };

  const { signInGoogle, signOutGoogle } = useGoogleLoginCustom(
    handleGoogleLoginSuccess,
    handleGoogleLoginFailure,
  );

  const handleUpdatePermission = async accessGroupId => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    const {
      data: { accessGroups: groupResponse },
    } = await authenticationApi.get(`/accessGroup/${accessGroupId}`, {
      headers: {
        Authorization: token,
      },
    });
    setCurrentPermission(groupResponse);
  };

  const handleForgetPassword = async email => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    if (!email) return;
    authenticationApi
      .post(
        `/forgetPassword`,
        {
          email,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        addToast({
          type: "success",
          title: "Esqueceu a senha",
          description: `Foi enviado um email no ${email} para redefinir a senha.`,
        });
      });
  };

  const setCompaniesSessionToken = (tokenCompanies, tokenBase) => {
    window.localStorage.setItem(AUTH_TOKEN_SESSION_COMPANIES, tokenCompanies);
    window.localStorage.setItem(AUTH_TOKEN_SESSION_TOKEN_BASE, tokenBase);
    // setTimeout(() => {
    setData({
      ...data,
      tokenCompanies,
      tokenBase,
      revalidate: [1],
    });
    // }, 2000);
  };

  const setRevalidateAll = useCallback(
    (value = []) => setData({ ...data, revalidate: value }),
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signInGoogle,
        signOutGoogle,
        token: data.user,
        tokenCompanies: data.tokenCompanies,
        tokenBase: data.tokenBase,
        user: data.user,
        revalidateAll: data?.revalidate,
        currentPermission,
        handleUpdatePermission,
        handleForgetPassword,
        setCompaniesSessionToken,
        isAdvancedUser,
        setRevalidateAll,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth, AuthProvider };
