import { Badge } from "reactstrap";
import styled from "styled-components";

const colorStyles = {
  success: {
    bg: "#28c76f",
    itemsColor: "#28c76f",
  },
  danger: {
    bg: "red",
    itemsColor: "red",
  },
  warning: {
    bg: "#ff9f43e8",
    itemsColor: "#ff9f43e8",
  },
  default: {
    bg: "#4a23e8",
    itemsColor: "#000",
  },
};

export const DateHourTitle = styled.h6`
  font-size: 10px;
  padding: 15px 5px 0px 0px;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
export const StatusDiv = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
  display: flex;
  background-color: ${props => colorStyles[props?.type || "default"].bg};
  color: ${props =>
    colorStyles[props?.type || "default"].itemsColor} !important;
`;

export const TopTitles = styled.h6`
  font-size: 8px;
  color: #fff;
  margin: 0 !important;
  font-weight: 700;
  ${props => (props?.copy ? `cursor: pointer` : "")};
`;

export const TitleMonay = styled.h1`
  font-size: 30px;
  font-weight: 700;
`;

export const CodIdDiv = styled.div`
  background: #54bf01;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  display: flex;
  margin-left: auto;
`;

export const TagsDiv = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
`;

export const BadgeCustom = styled(Badge)`
  margin: 1px;
`;

export const CardHeaderAddingTitle = styled.div`
  margin-top: 5px;
  padding: 5px 10px 5px 10px;
  background-color: #e8e8e8;
  border-radius: 5px;
  h4 {
    font-weight: bold;
  }
`;
