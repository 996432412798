import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import { SOLICITATION_ENDPOINTS } from "../../constants/Types/Solicitation.types";

const initialSelecteds = SOLICITATION_ENDPOINTS?.map(item => item?.value);
const INITIAL_STATE = {
  selecteds: [...initialSelecteds],
  figura_fiscal: true,
  regra_fiscal: true,
};

const reducers = {
  handleChange: (state, { payload }) => {
    const field = payload?.field;
    const copy = state[field];

    const check = (copy || []).filter(item => item === payload?.item);
    const remove = (copy || []).filter(item => item !== payload?.item);

    if (!check?.length) {
      state[field] = [...remove, payload?.item];
      toast.success(`O Cenario de ${payload?.label} ${payload?.item}`);
    } else {
      state[field] = [...remove];
      toast.error(
        `O Cenario de ${payload?.label} ${payload?.item} foi removido`,
      );
    }
    return state;
  },

  handleSelected: (state, { payload }) => {
    const selected = payload?.selected;

    const copy = state.selecteds;

    const check = (copy || []).filter(item => item === selected);
    const remove = (copy || []).filter(item => item !== selected);

    if (!check?.length) {
      state.selecteds = [...remove, selected];
      toast.success(`A Solicitação de ${selected} foi adicionada`);
    } else {
      state.selecteds = [...remove];
      toast.error(`A Solicitação de ${selected} foi removida`);
    }
  },

  handleChangeFiscal: (state, { payload }) => {
    const field = payload?.field;
    const label = payload?.label;
    const actualValue = state[field];

    if (!actualValue === false)
      toast.error(`A solicitação para ${label} foi desativada`);
    else toast.success(`A solicitação para ${label} foi ativada`);

    state[field] = !actualValue;
  },
};

const solicitationSlice = createSlice({
  name: "integrationSolicitation",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = solicitationSlice.reducer;
export const actions = solicitationSlice.actions;
export default reducer;
