import { createSlice } from "@reduxjs/toolkit";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const dashboardDefault = {
  cardAll: {
    dateInit: firstDay,
    dateEnd: currentDay,
  },
  cardStatus: {
    start: firstDay,
    end: currentDay,
  },
  cardTotalEscrituradas: {
    start: firstDay,
    end: currentDay,
  },
  cardTotalEscrituradasLastMonths: {
    start: firstDay,
  },
  cardPendentes: {
    start: firstDay,
    end: currentDay,
  },
};

const handleCardStatusReducer = (state, { payload }) => {
  state.cardStatus.start = payload.dateInit;
  state.cardStatus.end = payload.dateEnd;
};

const handleCardTotalEscrituracaoReducer = (state, { payload }) => {
  state.cardTotalEscrituradas.start = payload.dateInit;
  state.cardTotalEscrituradas.end = payload.dateEnd;
};

const handleCardTotalEscrituracaoMonthsReducer = (state, { payload }) => {
  state.cardTotalEscrituradasLastMonths.start = payload.dateInit;
};

const handleCardPendentesReducer = (state, { payload }) => {
  state.cardPendentes.start = payload.dateInit;
  state.cardPendentes.end = payload.dateEnd;
};

const initialDashboard = () => {
  return dashboardDefault;
};

export const escrituracaoDashboardSlice = createSlice({
  name: "escrituracaoDash",
  initialState: initialDashboard(),
  reducers: {
    handleCardStatus: handleCardStatusReducer,
    handleCardPendentes: handleCardPendentesReducer,
    handleCardTotalEscriturado: handleCardTotalEscrituracaoReducer,
    handleCardTotalEscrituracaoMonths: handleCardTotalEscrituracaoMonthsReducer,
  },
});

export const {
  handleCardStatus,
  handleCardPendentes,
  handleCardTotalEscriturado,
  handleCardTotalEscrituracaoMonths,
} = escrituracaoDashboardSlice.actions;

export default escrituracaoDashboardSlice.reducer;
