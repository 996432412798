import { createSlice } from "@reduxjs/toolkit";

const initialClassificar = {
  openSideBar: false,
  data: [],
  page: 1,
  limit: 100,
  totalPages: 0,
};

const initialState = () => {
  return { ...initialClassificar };
};

export const classificarSlice = createSlice({
  name: "classificar",
  initialState,
  reducers: {
    handleOpenSidBar: (state, { payload }) => {
      state.openSideBar = payload?.value;
    },
    handleUpdateList: (state, { payload }) => {
      state.data = payload;
    },
    handleSelectRow: (state, { payload }) => {
      const List = [...state.data];
      const updatedData = List.map(item => {
        if (payload?.id !== item.id) {
          return item;
        }

        return {
          ...item,
          productSelected: !item.productSelected,
        };
      });
      state.data = [...updatedData];
      state.totalPages = payload.totalPages;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.page = payload.page;
      }
      if (payload.totalPages) {
        state.totalPages = payload.totalPages;
      }
      if (payload.limit) {
        state.limit = payload.limit;
      }
    },
  },
});

export const {
  handleOpenSidBar,
  handleUpdateList,
  handleSelectRow,
  handleSetPageAndLimit,
} = classificarSlice.actions;

export default classificarSlice.reducer;

//state = atual
// payload = depois,
