import styled, { css } from "styled-components";

export const FlexAction = styled.div`
  ${({ isButtonAdd }) => css`
    display: ${isButtonAdd ? "flex " : "auto"};

    ${isButtonAdd &&
    css`
      float: right;
    `}
  `}
`;
