import { createSlice } from "@reduxjs/toolkit";

export const initialRever = {
  params: {
    filter: false,
    search_setor: "",
    search_category: "",
  },
  isShowModal: false,
  modal: {
    show: false,
  },

  productsSelects: [],
  showFindItemAlert: true,
  showInfo: false,
};

const initialState = () => {
  return initialRever;
};

export const reverDescricaoSlice = createSlice({
  name: "reverdescricao",
  initialState,
  reducers: {
    handleParams(state, { payload }) {
      state.params.search_setor = payload?.search_setor;
      state.params.search_category = payload?.search_category;
    },
    handleSelectProducts(state, { payload }) {
      const copy = [...state.productsSelects];

      const selectId = payload?.prod_uniq_id;
      const checkExist = state.productsSelects?.filter(
        item => item?.prod_uniq_id === selectId,
      );

      console.log({ selectId, checkExist });
      if (checkExist?.length) {
        const remove = state.productsSelects?.filter(
          item => item?.prod_uniq_id !== selectId,
        );
        state.productsSelects = [...remove];
      } else {
        state.productsSelects = [payload, ...copy];
      }
      return state;
    },
    handleFilter(state, { payload }) {
      state.params.filter = payload;
    },
    handleClearFilter(state) {
      delete state.params.search_setor;
      delete state.params.search_category;
      return state;
    },
    handleOpenModal(state, { payload }) {
      state.modal = payload;
    },
    handleOpenModalType(state, { payload }) {
      state.modal = payload;
    },
    handleAlert(state, { payload }) {
      state.showFindItemAlert = payload;
    },
    handleDownInfo(state, { payload }) {
      state.showInfo = payload;
    },
  },
});

export const {
  handleParams,
  handleFilter,
  handleClearFilter,
  handleOpenModal,
  handleDownInfo,
  handleAlert,
  handleSelectProducts,
  handleOpenModalType,
} = reverDescricaoSlice.actions;

export default reverDescricaoSlice.reducer;
