import { createSlice } from "@reduxjs/toolkit";

const state = {
  id: null,
  isNew: false,
};

const contractCategorySlice = createSlice({
  name: "contractCategory",
  initialState: state,
  reducers: {
    handleCategorySelected: (state, { payload }) => {
      state.id = payload;
      state.isNew = false;
      return state;
    },
    handleNewCategory: (state, { payload }) => {
      state.isNew = payload;
      state.id = null;
      return state;
    },
  },
});

export const actions = contractCategorySlice.actions;

export default contractCategorySlice.reducer;
