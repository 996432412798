import { createSlice } from "@reduxjs/toolkit";

const initalRepórts = {
  params: {
    page: 1,
    limit: 15,
  },
  search: {},
  columnsTributation: [],
  isUpdateColumns: false,
};

const initialState = () => {
  return {
    ...initalRepórts,
  };
};

export const relatorioCfopSlice = createSlice({
  name: "productReportsInvoices",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      const value = payload?.value;
      state.params[field] = value;
    },

    handleParamsClear: (state, { payload }) => {
      const field = payload?.field;
      delete state.search[field];
      return state;
    },
    handleParamsClearIn: state => {
      state.search = {};
      return state;
    },
    handleParamsSearch: (state, { payload }) => {
      const field = payload?.field;
      const value = payload?.value;
      state.search[field] = value;
    },
    handleSetColumnsTributation: (state, { payload }) => {
      const columns = payload;
      state.columnsTributation = columns;
    },
    handleOnChangeColumns: (state, { payload }) => {
      const { name, omit } = payload;
      const actions = state.columnsTributation.filter(
        item => !item.name && item.disabledDrop,
      );
      const selected = state.columnsTributation
        .filter(item => item.name && !item.disabledDrop && item?.name === name)
        .map(item => {
          return {
            ...item,
            omit: !omit ? true : false,
          };
        });

      const others = state.columnsTributation.filter(
        item => item.name && !item.disabledDrop && item?.name !== name,
      );

      const newList = [...actions, ...selected, ...others];

      const listPositions = newList.sort(
        (itemA, itemB) => itemA.position - itemB.position,
      );
      state.columnsTributation = listPositions;
    },
  },
});

export const {
  handleParams,
  handleParamsClear,
  handleParamsClearIn,
  handleParamsSearch,
  handleSetColumnsTributation,
  handleOnChangeColumns,
} = relatorioCfopSlice.actions;

export default relatorioCfopSlice.reducer;
