import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactPaginate from "react-paginate";

import { Card } from "reactstrap";

import { useQueimaAndRisco } from "useCases/reports/QueimaCaixa";

import { handleParamsPage } from "redux/taxAnalises";

import { Totals } from "./Header/CardsTotals";

import { ListProducts } from "./Tables";
import PagesHeaders from "components/structure/DataTable/PagesHeader";

import { FilterAdvanced } from "./Header/Filter/FilterAdvanced";

import { ActionsHeader } from "./Header/ActionsHeader";
import { Filter } from "react-feather";

export const CustomPagination = ({ totalPages }) => {
  const { params } = useSelector(state => state.taxAnalises);

  const dispatch = useDispatch();

  const handlePagination = page =>
    dispatch(handleParamsPage({ page: page.selected + 1 }));

  return (
    <ReactPaginate
      nextLabel=""
      breakLabel="..."
      previousLabel=""
      pageCount={totalPages}
      activeClassName="active"
      breakClassName="page-item"
      pageClassName={"page-item"}
      breakLinkClassName="page-link"
      nextLinkClassName={"page-link"}
      pageLinkClassName={"page-link"}
      nextClassName={"page-item next"}
      previousLinkClassName={"page-link"}
      previousClassName={"page-item prev"}
      onPageChange={page => handlePagination(page)}
      forcePage={params?.page !== 0 ? params?.page - 1 : 0}
      containerClassName={"pagination react-paginate justify-content-end p-1"}
    />
  );
};

export const TaxAnalysisListing = () => {
  const {
    params,
    paramsNote,
    tipo,
    order,
    issue_date_end: end,
    issue_date_start: start,
    taxType,
  } = useSelector(state => state.taxAnalises);

  const { data, isLoading } = useQueimaAndRisco({
    ...params,
    tipo,
    issue_date_end: end,
    issue_date_start: start,
    [order?.column]: order?.value,
    taxType,
  });

  const dataProducts = useMemo(
    () =>
      (data?.data ?? []).reduce((previous, current) => {
        if (current?.totais?.produtos?.length > 0) {
          return [...previous, ...current.totais.produtos];
        }

        return previous;
      }, []),
    [data],
  );

  return (
    <>
      <div className="react-dataTable mt-1">
        <Totals />
        <PagesHeaders
          title={``}
          isToggle
          titleFilter={<Filter size={15} />}
          childrenAction={<ActionsHeader disabledBtn={!dataProducts?.length} />}
          childrenToogle={<FilterAdvanced />}
        />
        <Card>
          <ListProducts
            data={dataProducts}
            dataRes={data}
            isLoading={isLoading}
            paramsSearch={{
              ...paramsNote,
              tipo,
              issue_date_end: end,
              issue_date_start: start,
              [order?.column]: order?.value,
              taxType,
            }}
          />
        </Card>
      </div>
    </>
  );
};
