import { formatCurrency } from "utils";

export const columns = [
  {
    name: "ICMS Queima de Caixa",
    sortable: false,
    typesDefaults: [],
    isType: "queima-caixa",
    sortField: "total_queima_caixa_icms",
    minWidth: "70px",
    position: 1,
    cell: row => {
      return formatCurrency(row?.totais?.total_queima_caixa_icms);
    },
  },
  {
    name: "PIS/COFINS Queima de Caixa",
    sortable: false,
    typesDefaults: [],
    isType: "queima-caixa",
    sortField: "total_queima_caixa_pis_cofins",
    minWidth: "70px",
    position: 1,
    cell: row => {
      return formatCurrency(row?.totais?.total_queima_caixa_pis_cofins);
    },
  },
  {
    name: "ICMS Risco assumido",
    sortable: false,
    typesDefaults: [],
    isType: "risco-assumido",
    sortField: "total_risco_assumido_icms",
    minWidth: "70px",
    position: 1,
    cell: row => {
      return formatCurrency(row?.totais?.total_risco_assumido_icms);
    },
  },
  {
    name: "PIS/COFINS Risco Assumido",
    sortable: false,
    typesDefaults: [],
    isType: "risco-assumido",
    sortField: "total_risco_assumido_icms",
    minWidth: "70px",
    position: 1,
    cell: row => {
      return formatCurrency(row?.totais?.total_risco_assumido_icms);
    },
  },
  {
    name: "Valor PIS",
    sortable: false,
    typesDefaults: [],
    sortField: "total_vpis",
    minWidth: "70px",
    position: 1,
    cell: row => {
      return formatCurrency(row?.totais?.total_vpis);
    },
  },
  {
    name: "Valor COFINS",
    sortable: false,
    typesDefaults: [],
    sortField: "total_vcofins",
    minWidth: "70px",
    position: 1,
    cell: row => {
      return formatCurrency(row?.totais?.total_vcofins);
    },
  },
  {
    name: "Valor ICMS",
    sortable: false,
    typesDefaults: [],
    sortField: "valor_icms",
    minWidth: "70px",
    position: 1,
    cell: row => {
      console.log({ row });
      return formatCurrency(row?.totais?.total_vicms);
    },
  },

  // {
  //   name: "Multa PIS/COFINS",
  //   sortable: true,
  //   typesDefaults: [],
  //   isType: "risco-assumido",
  //   sortField: "valor_multa_pis_cofins",
  //   minWidth: "70px",
  //   position: 1,
  //   omit: false,
  //   cell: row => {
  //     return formatCurrency(row?.valor_multa_pis_cofins);
  //   },
  // },
  // {
  //   name: "Multa ICMS",
  //   sortable: true,
  //   typesDefaults: [],
  //   isType: "risco-assumido",
  //   sortField: "valor_multa_icms",
  //   minWidth: "70px",
  //   position: 1,
  //   omit: false,
  //   cell: row => {
  //     return formatCurrency(row?.valor_multa_icms);
  //   },
  // },
];
