import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialProductClients = {
  page: 1,
  limit: 100,
  list: [],
  isShowFilter: false,
  filters: [
    {
      id: uuidv4(),
      columnField: {
        columnOrField: "and",
        columnField: "prod_nome",
        operatorValue: "equals",
        value: "",
      },
    },
  ],
};
const initialState = () => {
  const item = window.localStorage.getItem("filter-invoice");
  return item ? JSON.parse(item) : initialProductClients;
};

export const productClientsSlice = createSlice({
  name: "productsClient",
  initialState,
  reducers: {
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.page = payload.page;
      }
      if (payload.limit) {
        state.limit = payload.limit;
      }
    },
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
    },
    handleOnChangeFilter: (state, { payload }) => {
      const list = [...state.filters];
      list[payload?.key].columnField[payload?.field] = payload?.value;
      state.filters = list;
    },
    handleToogleFilters: (state, { payload }) => {
      state.isShowFilter = payload.isShowFilter;
    },
    handleAddFilters: (state, { payload }) => {
      state.filters = payload;
    },
    handleRemoveFilterUnic: (state, action) => {
      const { id } = action.payload;
      state.filters = state.filters.filter(item => item.id !== id);
    },
    handleRemoveFilterAll: state => {
      state.filters = [
        {
          id: uuidv4(),
          columnField: {
            columnOrField: "and",
            columnField: "prod_nome",
            operatorValue: "equals",
            value: "",
          },
        },
      ];
    },
  },
});

export const {
  handleSetList,
  handleToogleFilters,
  handleOnChangeFilter,
  handleAddFilters,
  handleRemoveFilterUnic,
  handleRemoveFilterAll,
  handleSetPageAndLimit,
} = productClientsSlice.actions;

export default productClientsSlice.reducer;
