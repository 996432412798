import { useState, useCallback } from "react";
import { useAPILinkMix } from "hooks";

export const useBuscarProductsIntegracaoApi = source => {
  const api = useAPILinkMix();
  const [dataItems, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      api
        .post("/busca-produtos", params, {
          cancelToken: source?.token,
        })
        .then(({ data }) => {
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [source?.token, api],
  );

  return {
    isLoading: loading,
    data: dataItems,
    fetch,
  };
};
