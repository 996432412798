import React from "react";

export class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div style={{ height: "100%", paddingTop: "10rem" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "2rem",
              fontFamily: "Cairo, sans-serif",
              color: "#0c2340",
            }}
          >
            Indisponível no momento
          </h1>
        </div>
      );
    }
    return children;
  }
}
