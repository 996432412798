import styled from "styled-components";

import { Button } from "reactstrap";

export const BarcodeScannerCustom = styled.div`
  position: fixed;
  top: 0;
  background-color: rgb(0 0 0 / 82%);
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
`;

export const ButtonCloseCustom = styled(Button)`
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 20px !important;
  margin-top: 20px !important;
`;
