import styled from "styled-components";
import { ListGroup, ListGroupItem } from "reactstrap";

export const ListGroupCustom = styled(ListGroup)`
  @media (max-width: 768px) {
    flex-direction: column !important;
  }
`;
export const ListGroupItemCustom = styled(ListGroupItem)`
  @media (max-width: 768px) {
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid rgba(34, 41, 47, 0.125) !important;
  }
`;
