import styled from "styled-components";

import { Slider } from "@material-ui/core";

export const Container = styled.div`
  width: 100%;
`;

export const SliderCustom = styled(Slider)`
  color: red;
  width: 100%;
  cursor: pointer;
  height: 2px;
  display: inline-block;
  padding: 13px 0;
  position: relative;
  box-sizing: content-box;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
`;
