export const INITIAL_VALUE_SELECT = {
  label: "Selecione",
  value: "",
};

export const SELECT_CATEGORY_INITIAL_VALUE = {
  label: "Categoria",
  value: "",
};

export const CARD_HEADERS_COLORS_TYPES = {
  green: "green",
  default: "default",
  greenDarken: "greenDarken",
  blueDarken: "blueDarken",
};

export const USER_TYPE = {
  ROOT: "root",
  ADMIN: "admin",
  DEV: "dev",
  FINANCIAL: "financial",
  MANAGER: "manager",
  ATTENDANT: "attendant",
  SALE: "sale",
};

export const PRIVILEGES = {
  ADMIN: USER_TYPE.ADMIN,
  DEV: USER_TYPE.DEV,
  FINANCIAL: USER_TYPE.FINANCIAL,
  MANAGER: USER_TYPE.MANAGER,
  ATTENDANT: USER_TYPE.ATTENDANT,
  ROOT: USER_TYPE.ROOT,
  SALE: USER_TYPE.SALE,
};
