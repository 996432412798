export const PIS_FIELDS_ENTRIES = {
  fieldMxCstPis: "cst_pis_mix_entrada",
  fieldMxCstCofins: "cst_pis_mix_entrada",
  fieldMxAlqPis: "aql_pis_mix_entrada",
  fieldMxAlqCofins: "aql_cofins_mix_entrada",
  fieldCstPisDiver: "divergencia_cst_pis_entrada",
  fieldCstCofinsDiver: "divergencia_cofins_entrada",
  fieldAlqPisDiver: "divergencia_alq_pis_entrada",
  fieldAlqCofinsDiver: "divergencia_alq_cofins_entrada",
};

export const PIS_FIELDS_SALE = {
  fieldMxCstPis: "cst_pis_mix_saida",
  fieldMxCstCofins: "cst_cofins_mix_saida",
  fieldMxAlqPis: "aql_pis_mix_saida",
  fieldMxAlqCofins: "aql_cofins_mix_saida",
  fieldCstPisDiver: "divergencia_cst_pis_saida",
  fieldCstCofinsDiver: "divergencia_cofins_saida",
  fieldAlqPisDiver: "divergencia_alq_pis_saida",
  fieldAlqCofinsDiver: "divergencia_alq_cofins_saida",
};
