import { useSelector } from "react-redux";
import { dateFormatInvoice } from "utils";

export const useGlobalDateDashboard = () => {
  const { cardOlhoMargem } = useSelector(state => state.dashboard);

  const dataGlobalInicio = dateFormatInvoice(cardOlhoMargem.dateInit, "eua");
  const dataGlobalFim = dateFormatInvoice(cardOlhoMargem?.dateEnd, "eua");

  const dataGlobalInicioNoFormat = cardOlhoMargem.dateInit;
  const dataGlobalFimNoFormat = cardOlhoMargem.dateEnd;

  return {
    dataGlobalInicio,
    dataGlobalFim,
    dataGlobalInicioNoFormat,
    dataGlobalFimNoFormat,
  };
};
