import { useCallback, useState } from "react";

import {
  useFetchNfe,
  useNfeAPIAuthenticate,
  useToast,
  useAPINFE2,
  useAPIAuthenticateToast,
} from "hooks";
import { INVOICES_MANIFEST_CODES_LABELS } from "constants/Types/Invoices.types";
import { getURLWithParams } from "utils/context";

// TODO: Retirar os usos de hooks com cancelamento

export const useTaxNotes = params =>
  useFetchNfe(params ? getURLWithParams("/tax-note", params) : null);

export const useTaxNotesTotais = params =>
  useFetchNfe(params ? getURLWithParams("/tax-note/totals", params) : null);

export const useTaxNotesDetail = params =>
  useFetchNfe(params ? getURLWithParams("/tax-note/details/", params) : null);

export const useFetchGetBookkeepingFactory = params =>
  useFetchNfe(params ? getURLWithParams("/nfe/bookkeeping/", params) : null);

export const useInvoiceGetProduto = params =>
  useFetchNfe(
    params ? getURLWithParams("/tax-note/details/produto", params) : null,
  );

export const useInvoiceXmls = params =>
  useFetchNfe(params ? getURLWithParams("/tax-note", params) : null);

export const useInvoiceGetXml = params =>
  useFetchNfe(params ? getURLWithParams("/tax-note", params) : null);

export const useInvoiceGetHistory = params =>
  useFetchNfe(
    params ? getURLWithParams("/tax-note/details/history", params) : null,
  );

export const useNfeDetailActions = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const sendProcessItens = (data = {}) =>
    onSend({
      endpoint: getURLWithParams("/cronjobs/processa-cenarios", data),
      messageSuccess: "Produtos processados com sucesso!",
      messageFail: "Falha ao tentar processar os produtos da nota",
      type: "GET",
    });

  return {
    isLoading,
    sendProcessItens,
  };
};

export const useInvoiceGetXml3 = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      apiNfe
        .get(getURLWithParams("/tax-note/xml", params))
        .then(values => {
          setData(values?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [apiNfe],
  );

  return { isLoading: loading, data, fetch };
};

export const useInvoiceDetail = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const {
    data: dataXml,
    isLoading: isLoadingXml,
    fetch: fectchGetxml,
  } = useInvoiceGetXml3();

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      apiNfe
        .get(getURLWithParams("/tax-note/details/", params))
        .then(values => {
          fectchGetxml({
            id: params?.id,
          });
          setData(values?.data);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [apiNfe, fectchGetxml],
  );

  return {
    isLoading: loading,
    data,
    dataXml,
    isLoadingXml,
    fetch,
  };
};

export const useInvoiceDetailAsync = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const {
    data: dataXml,
    isLoading: isLoadingXml,
    fetch: fectchGetxml,
  } = useInvoiceGetXml();

  const fetch = useCallback(
    async (params = {}) => {
      setLoading(true);
      try {
        const values = await apiNfe.get(
          getURLWithParams("/tax-note/details/", params),
        );
        fectchGetxml({ id: params?.id });
        setData(values?.data);
        setLoading(false);
        return values?.data;
      } catch (error) {
        setData(undefined);
        setLoading(false);
      }
    },
    [apiNfe, fectchGetxml],
  );

  return { isLoading: loading, data, dataXml, isLoadingXml, fetch };
};

export const useInvoiceSearchingTotals = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [totalPages, setTotalPages] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      apiNfe
        .post("/tax-note/totals", {
          ...params,
        })
        .then(values => {
          setTotalPages(values?.data?.total_pages);
          setData(values?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setTotalPages(undefined);
          setLoading(false);
        });
    },
    [apiNfe],
  );

  return { isLoading: loading, data, totalPages, fetch };
};

export const useInvoiceSearching = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const {
    data: dataTotals,
    totalPages,
    fetch: fetchTotals,
  } = useInvoiceSearchingTotals();

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      apiNfe
        .post("/tax-note", {
          ...params,
        })
        .then(values => {
          fetchTotals({ ...params });
          setData(values?.data);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [apiNfe, fetchTotals],
  );

  return {
    isLoading: loading,
    data,
    dataTotals: dataTotals?.[0],
    totalPages,
    fetch,
  };
};

export const useNoteDetailBokkeeping = params =>
  useFetchNfe(getURLWithParams("/nfe/bookkeeping", params));

export const useTaxNoteDownload = params =>
  useFetchNfe(params ? getURLWithParams("/tax-note/download", params) : null);

export const useTaxNoteManifest = ({ id }) => {
  const { addToast } = useToast();
  const api = useNfeAPIAuthenticate();
  const [isLoading, setIsLoading] = useState(false);

  const onSend = useCallback(
    (fields = {}) => {
      setIsLoading(true);

      api
        .post("/nfe/manifest", {
          cnpj: fields?.cnpj,
          documentType: "NFE",
          type: fields?.type,
          events: [
            {
              key: id,
              reason: "manifest",
            },
          ],
        })
        .then(() => {
          return addToast({
            type: "success",
            title: "Sucesso",
            description: `Atualizado com sucesso ${
              INVOICES_MANIFEST_CODES_LABELS[fields?.type]?.label ??
              "Desconhecido"
            }`,
          });
        })
        .catch(() => {
          return addToast({
            type: "error",
            title: "FALHA",
            description: `Falha ao tentar ${
              INVOICES_MANIFEST_CODES_LABELS[fields?.type]?.label ??
              "Desconhecido"
            }`,
          });
        })
        .finally(() => setIsLoading(false));
    },
    [api, setIsLoading, addToast, id],
  );

  return {
    isLoading,
    onSend,
  };
  // /nfe/manifest
};
