import { useState, useCallback } from "react";
import { useFetchNfe, useAPINFE2, useToast } from "hooks";
import { getURLWithParams } from "utils/context";

// TODO: Retirar os usos de hooks com cancelamento

export const useListSuppliers = params =>
  useFetchNfe(
    params ? getURLWithParams("/business-oportunity/supplier", params) : null,
  );

export const useListSuppliersNotes = params =>
  useFetchNfe(
    params ? getURLWithParams("/business-oportunity/tax-note", params) : null,
  );

export const useListSuppliersNotesProducts = params =>
  useFetchNfe(
    params
      ? getURLWithParams("/business-oportunity/tax-note-items", params)
      : null,
  );

export const useSuppliersAll = params =>
  useFetchNfe(
    params
      ? getURLWithParams("/business-oportunity/all-supplier", params)
      : null,
  );

export const useExportCsv = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const fetch = useCallback(
    (url, params = {}) => {
      setLoading(true);
      apiNfe
        .get(getURLWithParams(`/reports-exports/${url}`, params))
        .then(res => {
          if (res?.data?.response === false) {
            setLoading(false);
            return addToast({
              type: "error",
              title: "Sem dados",
              description: `Não foi encontrado dados para exportar ${url}`,
            });
          }
          window.open(res?.data?.Location, "_blank");
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [apiNfe, addToast],
  );

  return { isLoading: loading, data, fetch };
};
