import { createSlice } from "@reduxjs/toolkit";

export const inicialRegrasTributarias = {
  params: {
    page: 1,
  },
  stateSelect: "",
  formExtras: {
    Excecao: "",
    NCM: "",
    mxf_nf_id: "",
    modificar_options: "",
    modificar_options2: "",
    modificar_options3: "",
  },
  form: {
    Excecao: "",
    NCM: "",
    modificar_options: null,
    modificar_options2: null,
    mxf_cest_id: null,
    mxf_nf_id: null,
  },
};

const initialState = () => {
  return inicialRegrasTributarias;
};

export const RegrasTributariasSlice = createSlice({
  name: "RegrasTributarias",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },

    handleSelectState: (state, { payload }) => {
      state.stateSelect = payload?.value;
      return state;
    },
    handleSetForm: (state, { payload }) => {
      state.form = payload;
      return state;
    },
    handleSetSearch: (state, { payload }) => {
      if (payload?.isDelete) {
        delete state.params[payload?.field];
      } else {
        state.params[payload?.field] = payload?.value;
      }
    },
    handleSetNCM: (state, { payload }) => {
      state.formExtras.NCM = payload?.value;
    },
  },
});

export const {
  handleParams,
  handleSetSearch,
  handleSetItemForm,
  handleSetForm,
  handleSetNCM,
  handleSelectState,
} = RegrasTributariasSlice.actions;

export default RegrasTributariasSlice.reducer;
